const namespaced = true

const state = {
  selectedPackage: null,
  packageQty: 1,
  packageId: null,
  validGpePackage: false,
  packageWithoutHotelId: null,
  hotelMaxInventory: 10
}

const mutations = {
  refreshPackages(state, packages) {
    state.selectedPackage = packages[0]
  },
  updateSelectedPackage(state, chosenPackage){
    state.selectedPackage = chosenPackage
  },
  updatePackageQty(state, packageQty){
    state.packageQty = packageQty
  },
  setPackageId(state, packageId) {
    state.packageId = packageId
  },
  setPackageWithoutHotelId(state, packageId) {
    state.packageWithoutHotelId = packageId
  },
  updateValidGpePackage(state, status) {
    state.validGpePackage = status
  }
}

const getters = {
  getPackageQty: (state) => {
    return state.packageQty
  },
}


export default {
  namespaced,
  state,
  mutations,
  getters
}
