/* global dataLayer Turbo */

export { trackPageView, trackPurchaseEvent }
import cartApi from '../shared/cart/cart_api'

function load() {

  (function(w,d,s,l,i) {
    w[l] = w[l] || []
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
    var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),
      dl=l!='dataLayer'?'&l='+l:''
    j.async=true
    j.src='//www.googletagmanager.com/gtm.js?id='+i+dl
    f.parentNode.insertBefore(j,f)
  })(window, document, 'script', 'dataLayer', window.googleTagManagerId)

  if ((typeof Turbo !== 'undefined')) {
    document.addEventListener('turbo:load', function(){
      let googleUrl = window.location.href
      let pageTitle = document.title

      const gaPathOverride = document.getElementById('gaPathOverride')
      if(gaPathOverride){
        googleUrl = `${window.location.host}${gaPathOverride.dataset.url}`
        pageTitle = gaPathOverride.dataset.url.split('/').filter(n => n).map(n=> n.charAt(0).toUpperCase()+ n.slice(1)).join(' ')
      }

      trackPageView(googleUrl, pageTitle)
    }, true)
  } else {
    trackPageView(window.location.href, document.title)
  }
}

function trackPurchaseEvent() {
  if (!window.googleTagManagerId) { return }
  dataLayer.push({ ecommerce: null })  // Clear the previous ecommerce object.
  cartApi.getFinishedOrder().then(order => {
    let ecommerce = {
      transaction_id: order.id,
      value: order.dollarized_usd_total.toFixed(2),
      tax: parseFloat(order.object_tax).toFixed(2),
      processing_fee: parseFloat(order.object_processing_fee).toFixed(2),
      currency: 'USD',
      coupon: order.discount_code || '',
      event_name: order.event_name,
      items: buildItems(order)
    }

    dataLayer.push({
      event: 'purchase',
      ecommerce: ecommerce
    })
  })
}

function buildItems(order) {
  let unmappedPackages = order.items.filter(item => item.object_package_price > 0) // figure this out
  let packages = unmappedPackages.map((item, index) => {
    return {
      item_id: item.id,
      index: index,
      item_name: item.display_name,
      price: item.object_package_price.toFixed(2),
      quantity: item.quantity
    }
  })


  let unmappedHotels = order.items.filter(item => item.room_quantity > 0)
  let hotels = unmappedHotels.map((item, index) => {
    return {
      item_id: 111111,
      index: index + packages.length,
      item_name: item.hotel_name,
      price: parseFloat(item.room_price).toFixed(2),
      quantity: item.room_quantity
    }
  })

  let unmappedDeposits = order.items.filter(item => item.object_package_price < 0)
  let deposits = unmappedDeposits.map((item, index) => {
    return {
      item_id: item.id,
      index: packages.length + hotels.length + index,
      item_name: item.display_name,
      price: item.object_package_price.toFixed(2),
      quantity: item.quantity
    }
  })

  let transportationFee = []

  if(order.transportation_fee > 0) {
    transportationFee = [{
      item_id: 333333,
      index: packages.length + hotels.length + deposits.length,
      item_name: 'Transportation Fee',
      price:  parseFloat(order.transportation_fee).toFixed(2),
      quantity: 1
    }]
  }

  let discount = []
  if(order.discount_amount > 0) {
    discount = [{
      item_id: 444444,
      index: packages.length + hotels.length + deposits.length + transportationFee.length,
      item_name: 'Web Order Discount',
      price:  parseFloat(order.discount_amount).toFixed(2),
      quantity: 1
    }]
  }

  let protectGroup = [{
    item_id: 222222,
    index: packages.length + hotels.length + deposits.length + transportationFee.length + discount.length,
    item_name: 'Refundable Booking',
    price:  order.dollarized_usd_protect_group_amount.toFixed(2),
    quantity: 1
  }]

  if(order.dollarized_usd_protect_group_amount > 0) {
    return packages.concat(hotels).concat(transportationFee).concat(deposits).concat(protectGroup).concat(discount)
  } else {
    return packages.concat(hotels).concat(transportationFee).concat(deposits).concat(discount)
  }
}

function trackPageView(url, title = '', pagePath = window.location.pathname) {
  if (!window.googleTagManagerId) { return }
  dataLayer.push({
    'event': 'virtualPageView',
    'pageUrl': url,
    'pageTitle': title,
    'pagePath': pagePath
  })
}

if (window.googleTagManagerId) {
  load()
}
