/* global locale */
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { messages } from 'default/translated_text'
import { store } from 'default/store/index.js'

export default {
  loadVueComponent(component, element) {
    if (component == null) { return }
    const app = createApp(component)
    const i18n = createI18n({ locale, messages, fallbackLocale: 'en', warnHtmlInMessage: 'off' })
    app.use(i18n)
    app.use(store)
    if (element == null) { return }
    app.mount(element)
  }
}
