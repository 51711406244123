const namespaced = true

const state = {
  adults: 2,
  checkInDate: null,
  checkOutDate: null,
  contractedHotels:[],
  contractHotel:false,
  contractedOnlyMap: false,
  destination: '',
  eventLatitude: null,
  eventLongitude: null,
  excludedHotels: [],
  filteredHotels: [],
  h4h_destination: null,
  h4h_add_on: false,
  h4h_latitude: null,
  h4h_longitude: null,
  hotel: null,
  hotels: [],
  hotelRooms: [],
  isTBD: false,
  isRetailHotel: false,
  latitude: null,
  loading: false,
  longitude: null,
  hasFilterResults: null,
  packageId: null,
  pageCount: 10,
  propertyAmenities: [],
  propertyClasses: [],
  propertyTypes: [],
  retailHotels: [],
  retailLoading: false,
  roomQty: 1,
  scrollPosition: null,
  searchLatitude: null,
  searchLongitude: null,
  showFilter: false,
  showRetailIndex: false,
  showMap: false,
  totalHotels:[],
  venue: null,
  wasOnMap: false,
  temp: []
}

const mutations = {
  increaseRoomQty(state, n) {
    state.roomQty += n
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setContractedHotels(state, hotel) {
    state.contractedHotels.push(hotel)
  },
  setRetailHotelStatus(state, status) {
    state.isRetailHotel = status
  },
  setRetailLoading(state, retailLoading) {
    state.retailLoading = retailLoading
  },
  updateVenue(state, venue) {
    state.venue = venue
  },
  updateDestination(state, destination) {
    state.destination = destination
  },
  updateH4hDestination(state, h4h_destination) {
    state.h4h_destination = h4h_destination
  },
  updateH4hPackage(state, h4h_add_on){
    state.h4h_add_on = h4h_add_on
  },
  updateH4hLatitude(state, h4h_latitude) {
    state.h4h_latitude = h4h_latitude
  },
  updateH4hLongitude(state, h4h_longitude) {
    state.h4h_longitude = h4h_longitude
  },
  updateLatitude(state, eventLatitude) {
    state.eventLatitude = eventLatitude
  },
  updateLongitude(state, eventLongitude) {
    state.eventLongitude = eventLongitude
  },
  updateSearchLat(state, searchLatitude) {
    state.searchLatitude = searchLatitude
  },
  updateSearchLng(state, searchLongitude) {
    state.searchLongitude = searchLongitude
  },
  updateCheckInDate(state, checkInDate) {
    state.checkInDate = checkInDate
  },
  updateCheckOutDate(state, checkOutDate) {
    state.checkOutDate = checkOutDate
  },
  updateRoomQty(state, n) {
    state.roomQty = n
  },
  settotalRetailHotels(state, hotels){
    state.totalHotels = hotels
  },
  updateHotelsArray(state, hotels) {
    state.filteredHotels = []
    state.hotels.push(...hotels)
  },
  updateTBDStatus(state, tbd_status) {
    state.isTBD = tbd_status
  },
  increasePageCount(state, count) {
    state.pageCount += count
  },
  reloadHotels(state) {
    state.retailHotels = state.hotels.filter(hotel => hotel.contracted == false)
    state.totalHotels = state.hotels.map(hotel => hotel)
  },
  populateExcludedHotels(state, hotel) {
    state.excludedHotels.push(hotel)
  },
  clearExcludedHotels(state) {
    state.excludedHotels = []
  },
  populateFilteredHotels(state) {
    state.filteredHotels = state.totalHotels
  },
  setFilteredHotels(state) {
    state.excludedHotels.forEach((hotel) => {
      for(let i= 0; i < state.filteredHotels.length; i++) {
        if(hotel.id === state.filteredHotels[i].id) { state.filteredHotels.splice(i, 1) }
      }
    })
  },
  sortHotelsByDistance(state) {
    if(state.filteredHotels.length > 0) {
      return state.filteredHotels = state.filteredHotels.sort((a, b) => a.directions > b.directions ? 1 : -1)
    } else if(state.filteredHotels.length == 0) {
      return state.filteredHotels = state.hotels.filter(hotel => hotel.contracted == false).sort((a, b) => a.directions > b.directions ? 1 : -1)
    } else {
      return
    }
  },
  sortHotelsByPrice(state) {
    if(state.filteredHotels.length > 0) {
      return state.filteredHotels = state.filteredHotels.sort((a, b) => (Number.parseFloat(a.rooms[0].total) > Number.parseFloat(b.rooms[0].total)) ? 1: -1)
    } else if(state.filteredHotels.length == 0) {
      return state.filteredHotels = state.hotels.sort((a, b) => {
        return (Number.parseFloat(a.rooms[0].total.toString()) > Number.parseFloat(b.rooms[0].total.toString())) ? 1: -1
      }
      )
    } else {
      return
    }
  },
  hasHotelResults(state){
    state.excludedHotels = Array.from(new Set(state.excludedHotels))
    if(state.excludedHotels.length !== state.hotels.length) {
      return state.hasFilterResults = true
    } else {
      return state.hasFilterResults = false
    }
  },
  clearNonContractedHotels(state) {
    state.hotels = state.hotels.filter(hotel => hotel.contracted == true)
  },
  addHotelToArray(state, hotel) {
    state.hotels.push(hotel)
  },
  selectHotel(state, hotel) {
    if (state.showMap) {
      state.wasOnMap = true
      state.showMap = false
    }
    state.scrollPosition = window.scrollY
    window.scrollTo({top: 0, behavior: 'smooth'})

    state.hotel = hotel
  },
  selectContractHotel(state, hotel) {
    if (state.showMap) {
      state.wasOnMap = true
      state.showMap = false
    }
    state.scrollPosition = window.scrollY
    window.scrollTo({top: 0, behavior: 'smooth'})

    state.hotel = hotel
    state.contractHotel = true
  },
  deselectHotel(state) {
    if (state.wasOnMap) {
      state.showMap = true
      state.wasOnMap = false
    }
    window.scrollTo({top: state.scrollPosition, behavior: 'smooth'})
    state.hotel = null
    state.contractHotel = false
  },
  showRetailIndex(state, showRetailIndex) {
    state.showRetailIndex = showRetailIndex
  },
  showMap(state, showMap) {
    if (showMap) {
      state.scrollPosition = window.scrollY
    }
    if (!showMap) {
      state.contractedOnlyMap = false
      setTimeout(() => {
        window.scrollTo({top: state.scrollPosition, behavior: 'smooth'})
      }, 300)
    }
    state.showMap = showMap
  },
  setContractedOnlyMap(state, contractedOnly) {
    state.contractedOnlyMap = contractedOnly
  },
  toggleFilter(state) {
    state.showFilter == true ? state.showFilter = false : state.showFilter = true
  }
}


const getters = {
  getAllHotels: state => {
    return state.contractedHotels.concat(state.retailHotels)
  },
  getContractedHotels: state => {
    return state.contractedHotels
  },
  getUniqueDurations: (state, getters) => {
    let hotels = getters.getContractedHotels
    let uniqueDurations =
      [...new Set(hotels.map((x, index) => {
        if (!x.stayDuration) {
          state.hotels.splice(index, 1)
          return
        }
        return `${x.stayDuration}-`
      }))]
    uniqueDurations.sort()

    let size = uniqueDurations.length
    if (size <= 1) {
      return uniqueDurations[0]
    }

    uniqueDurations[size - 1] = uniqueDurations[size - 1] ?
      ` or ${uniqueDurations[size - 1]}` : ' or more '
    if(size == 2) {
      let returnString = ''
      uniqueDurations.forEach(duration => {
        returnString = returnString.concat(duration)
      })
      return returnString
    }

    for (let i = 0; i < size - 2; i++) {
      uniqueDurations[i] = `${uniqueDurations[i]}, `
    }

    let nightString = ''
    uniqueDurations.forEach(duration => {
      nightString = nightString.concat(duration)
    })
    return nightString
  },
  getHotels: state => {
    return state.hotels
  },
  getRetailHotels: state => {
    return state.hotels.filter(hotel => hotel.contracted == false)
  },
  getFilteredHotels: state => {
    return state.filteredHotels
  },
  sendCheckInToArn: state => {
    if (!state.checkInDate) return
    return state.checkInDate.toISOString().slice(0,10)
  },
  sendCheckOutToArn: state => {
    if (!state.checkOutDate) return
    return state.checkOutDate.toISOString().slice(0,10)
  },
  getLowestValue: state => hotelId => {
    const hotel = state.hotels.find(x => x.id === hotelId)
    if (!hotel || hotel == []) {
      return
    }

    return hotel.contracted ?
      hotel.rooms.find(room => room.inventory > 0 && room.active).total :
      hotel.rooms[0].total
  },
  getLowestPriceDiscountedRoom: state => hotelId => {
    const hotel = state.hotels.find(x => x.id === hotelId)

    return hotel.discountedRooms[0]
  },

  getRetailHotelStatus: state => {
    return state.isRetailHotel
  },
  getTBDStatus: state => {
    return state.isTBD
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations
}
