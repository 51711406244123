export default {
  en() {
    return {
      refundAmount: 'Refund Amount:',
      creditAmount: 'Transfer Amount:',
      selected: 'Your Selection:',
      success: 'Success! Your selected option has been submitted.',
      docusignFinePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      gracias: 'Thank you!',
      step1: 'Step 1:',
      step1Description: 'Make Your Selection',
      optionSelect: 'Select Your Option',
      step2: 'Step 2:',
      step2Description: 'Sign Terms and Conditions',
      clickHere: 'Click here',
      clickHereDescription: 'to read and agree to your terms and conditions.',
      submit: 'Save & Submit',
    }
  },
  ar() {
    return {
      refundAmount: ':مبلغ التحويل',
      creditAmount: ':اختيارك',
      selected: ':اختيارك',
      success: '.النجاح! تمّ تقديم خيارك المحدّد',
      docusignFinePrint: '.عند الانتهاء من الاطّلاع على الشروط والأحكام الموقّعة الخاصّة بك، ستتمّ إعادتك إلى هذه الصفحة لإكمال عمليّة الشراء .docusign.com سينقلك هذا الرابط إلى',
      gracias: '!شكرا',
      step1: ':الخطوة الأولى',
      step1Description: 'الخطوة 2',
      optionSelect: 'حدّد خياراتك',
      step2: 'الخطوة 2',
      step2Description: 'وقّع على الشروط والأحكام',
      clickHere: 'اضغط هنا',
      clickHereDescription: 'لقراءة الأحكام والشروط الخاصّة بك والموافقة عليها',
      submit: 'احفظ وقدّم',
    }
  },
  de() {
    return {
      refundAmount: 'Erstattungsbetrag:',
      creditAmount: 'Überweisungsbetrag:',
      selected: 'Ihre Auswahl:',
      success: 'Erfolg! Ihre gewählte Option wurde übermittelt.',
      docusignFinePrint: 'Dieser Link führt Sie zu docusign.com. Nach der Unterzeichnung der Allgemeinen Geschäftsbedingungen werden Sie auf diese Seite zurückgeleitet, um Ihren Kauf abzuschließen.',
      gracias: 'Vielen Dank!',
      step1: '1. Schritt:',
      step1Description: 'Treffen Sie Ihre Auswahl',
      optionSelect: 'Wählen Sie Ihre Option',
      step2: '2. Schritt:',
      step2Description: 'Unterzeichnen Sie die allgemeinen Geschäftsbedingungen',
      clickHere: 'Hier klicken',
      clickHereDescription: 'um die allgemeinen Geschäftsbedingungen zu lesen und zu akzeptieren.',
      submit: 'Speichern & Übermitteln',
    }
  },
  enGB() {
    return {
      refundAmount: 'Refund Amount:',
      creditAmount: 'Transfer Amount:',
      selected: 'Your Selection:',
      success: 'Success! Your selected option has been submitted.',
      docusignFinePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      gracias: 'Thank you!',
      step1: 'Step 1:',
      step1Description: 'Make Your Selection',
      optionSelect: 'Select Your Option',
      step2: 'Step 2:',
      step2Description: 'Sign Terms and Conditions',
      clickHere: 'Click here',
      clickHereDescription: 'to read and agree to your terms and conditions.',
      submit: 'Save & Submit',
    }
  },
  esMX() {
    return {
      refundAmount: 'Monto del reembolso:',
      creditAmount: 'Monto de la transferencia:',
      selected: 'Su selección:',
      success: '¡Éxito! Su opción seleccionada ha sido enviada.',
      docusignFinePrint: 'Este enlace lo llevará a docusign.com. Una vez que haya firmado sus términos y condiciones, volverá a esta página para completar su compra.',
      gracias: '¡Gracias!',
      step1: 'Paso 1:',
      step1Description: 'Hacer su selección',
      optionSelect: 'Seleccione Su Opción',
      step2: 'Paso 2:',
      step2Description: 'Firmar los Términos y Condiciones',
      clickHere: 'Haga clic aquí',
      clickHereDescription: 'para leer y aceptar sus términos y condiciones.',
      submit: 'Guardar & Enviar',
    }
  },
  es() {
    return {
      refundAmount: 'Importe del Reembolso:',
      creditAmount: 'Importe de la Transferencia:',
      selected: 'Tu Selección:',
      success: '¡Éxito! Tu opción seleccionada ha sido transmitida.',
      docusignFinePrint: 'Este enlace te llevará a docusign.com. Una vez que hayas firmado los términos y condiciones, volverás a esta página para completar tu compra.',
      gracias: '¡Gracias!',
      step1: 'Paso 1:',
      step1Description: 'Haz Tu Selección',
      optionSelect: 'Selecciona Tu Opción',
      step2: 'Paso 2:',
      step2Description: 'Firmar los Términos y Condiciones',
      clickHere: 'Pulsa aquí',
      clickHereDescription: 'para leer y aceptar tus términos y condiciones.',
      submit: 'Guardar y Enviar',
    }
  },
  fr() {
    return {
      refundAmount: 'Montant du remboursement :',
      creditAmount: 'Montant du versement :',
      selected: 'Votre sélection :',
      success: 'Votre choix a été enregistré et envoyé avec succès !',
      docusignFinePrint: 'Ce lien vous dirigera vers le site docusign.com. Une fois que vous aurez signé les conditions générales d\'utilisation, vous serez redirigé vers cette page pour finaliser votre achat.',
      gracias: 'Merci !',
      step1: 'Étape 1 :',
      step1Description: 'Faites votre sélection',
      optionSelect: 'Choisissez votre option',
      step2: 'Étape 2 :',
      step2Description: 'Signez les conditions d\'utilisation',
      clickHere: 'Cliquez ici',
      clickHereDescription: 'pour lire et accepter nos conditions d\'utilisation.',
      submit: 'Enregistrer & Envoyer',
    }
  },
  it() {
    return {
      refundAmount: 'Importo del rimborso:',
      creditAmount: 'Importo del trasferimento:',
      selected: 'Selezione:',
      success: 'Vittoria! L\'opzione selezionata è stata inviata.',
      docusignFinePrint: 'Questo collegamento ti indirizzerà a docusign.com. Una volta presa visione dei termini e delle condizioni, verrai reindirizzato a questa pagina per il completamento dell\'acquisto.',
      gracias: 'Grazie!',
      step1: 'Primo passaggio:',
      step1Description: 'fa\' la tua scelta',
      optionSelect: 'Scagli un\'opzione',
      step2: 'Secondo passaggio:',
      step2Description: 'sottoscrivi termini e condizioni',
      clickHere: 'Clicca qui',
      clickHereDescription: 'per leggere e accettare Termini e condizioni.',
      submit: 'Salva e invia',
    }
  },
  ja() {
    return {
      refundAmount: '返金額:',
      creditAmount: '払込金額:',
      selected: 'お客様の選択:',
      success: '成功！選択オプションが送信されました。',
      docusignFinePrint: 'このリンクをクリックすると、docusign.comに移動します。契約条件への署名が完了すると、このページに戻って購入を完了します。',
      gracias: 'ありがとうございました！',
      step1: 'ステップ 1:',
      step1Description: '選択します',
      optionSelect: 'オプションを選択してください',
      step2: 'ステップ 2:',
      step2Description: '利用規約に署名する',
      clickHere: 'ここをクリック',
      clickHereDescription: '利用規約を読んで同意します。',
      submit: '保存して送信',
    }
  },
  pl() {
    return {
      refundAmount: 'Refund Amount:',
      creditAmount: 'Transfer Amount:',
      selected: 'Your Selection:',
      success: 'Success! Your selected option has been submitted.',
      docusignFinePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      gracias: 'Thank you!',
      step1: 'Step 1:',
      step1Description: 'Make Your Selection',
      optionSelect: 'Select Your Option',
      step2: 'Step 2:',
      step2Description: 'Sign Terms and Conditions',
      clickHere: 'Click here',
      clickHereDescription: 'to read and agree to your terms and conditions.',
      submit: 'Save & Submit',
    }
  },
  po() {
    return {
      refundAmount: 'Refund Amount:',
      creditAmount: 'Transfer Amount:',
      selected: 'Your Selection:',
      success: 'Success! Your selected option has been submitted.',
      docusignFinePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      gracias: 'Thank you!',
      step1: 'Step 1:',
      step1Description: 'Make Your Selection',
      optionSelect: 'Select Your Option',
      step2: 'Step 2:',
      step2Description: 'Sign Terms and Conditions',
      clickHere: 'Click here',
      clickHereDescription: 'to read and agree to your terms and conditions.',
      submit: 'Save & Submit',
    }
  },
  zhCN() {
    return {
      refundAmount: '退款金额：',
      creditAmount: '接送金额：',
      selected: '您的选择：',
      success: '成功！您选择的选项已提交。',
      docusignFinePrint: '点击该链接可访问docusign.com网站。同意条款和条件后，您将返回此页面以完成支付。',
      gracias: '谢谢！',
      step1: '第1步：',
      step1Description: '请选择',
      optionSelect: '选择您的选项',
      step2: '第2步：',
      step2Description: '签署条款和条件',
      clickHere: '点击此处',
      clickHereDescription: '阅读并同意条款和条件。',
      submit: '保存并提交',
    }
  },
}
