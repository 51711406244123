const namespaced = true

const state = {
  orderItemId:0,
  quantity: 0,
}

const mutations = {
  updateOrderItemId(state, id) { // Namespaced
    state.orderItemId = id
  },

  updateTransportItemQuantity(state, quantity) { // Namespaced
    state.quantity = quantity
  }
}

const getters = {
  getTransportItemQuantity: (state) => {
    return state.quantity
  }
}

export default {
  namespaced,
  state,
  mutations,
  getters,
}
