import Cart from './cart'
import CancellationForm from './cancellation_form'
import CheckoutAddress from './checkout_address'
import CheckoutHotelDetails from './checkout_hotel_details'
import CheckoutAddOns from './checkout_add_on'
import CheckoutTermsAndConditions from './checkout_terms_and_conditions'
import CheckoutPayment from './checkout_payment'
import Confirmation from './confirmation'
import CreditCardForm from './credit_card_form'
import F1TvCodes from './f1_tv_codes'
import FanaticCodes from './fanatic_codes'
import FormPackage from './form_package'
import H4H from './h4h'
import MementoCodes from './memento_codes'
import NavCart from './nav_cart'
import Opportunity from './opportunity'
import Orders from './orders'
import ProtectGroup from './protect_group'
import PaymentTerms from './payment_terms'
import RoomingLists from './rooming_lists'
import SeatingChart from './seating_chart'
import ShipmentCodes from './shipment_codes'
import SignIn from './sign_in'
import EventInterests from './event_interests_form'

export const messages = {
  en: {
    eventInterests: EventInterests.en(),
    cart: Cart.en(),
    cancellation_form: CancellationForm.en(),
    checkout: {
      addOn: CheckoutAddOns.en(),
      addPoints: 'Use your {programName}',
      address: CheckoutAddress.en(),
      hotelDetails: CheckoutHotelDetails.en(),
      docusign: CheckoutTermsAndConditions.en(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.en(),
      protectGroup: ProtectGroup.en(),
      yourPoints: 'Your {programName}'
    },
    confirmation: Confirmation.en(),
    creditCardForm: CreditCardForm.en(),
    f1TvCodes: F1TvCodes.en(),
    fanaticCodes: FanaticCodes.en(),
    formPackage: FormPackage.en(),
    h4h: H4H.en(),
    signIn: SignIn.en(),
    navCart: NavCart.en(),
    orders: Orders.en(),
    opportunity: Opportunity.en(),
    paymentTerms: PaymentTerms.en(),
    roomingLists: RoomingLists.en(),
    seatingChart: SeatingChart.en(),
    shipmentCodes: ShipmentCodes.en(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'STORE',
      tickets: 'TICKETS',
      hospitality: 'HOSPITALITY',
      experiences: 'EXPERIENCES',
      sign_in_register: '<i class="icon-account"></i> SIGN IN <em>OR</em> REGISTER'
    },
    cookie: {
      cookie_consent: 'We use cookies to personalize content analyze our traffic and to capture information provided by our visitors to contact them about our events and services. You consent to <a href="/cookies-policy" target="_blank">our cookies</a> if you continue to use this website.',
      cookieConsentGpeFirst: 'We use cookies. Find out more',
      cookieConsentGpeLink: 'here',
      cookieConsentGpeLast:'. By using the site you accept the policy.',
      continue: 'Continue'
    },
    customer_service_form: {
      thank_you: 'Thank you for your submission!',
      message: 'Our customer service team will be in contact with you shortly about your inquiry.'
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired:'You must use an American Express card to complete your purchase.',
    creditCardNumber: 'Credit Card Number',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'due {date}',
    expirationDate: 'Expiration Date (mm / yy)',
    forgotYourPassword: 'Forgot your password?',
    form: {
      checkin_date: 'Check In Date',
      comments: 'Comments',
      company_name: 'Company Name',
      corporate: 'Is this request for a corporate group?',
      confirmation_number: 'Confirmation Number',
      email: 'Email',
      event: 'Event',
      first_name: 'First Name',
      get_info: 'Get Info',
      how_many: 'Number of People',
      hotel_name: 'Hotel Name',
      last_name: 'Last Name',
      event_or_company_name: 'Event Name / Company',
      marketing_email_consent: 'I agree to our <a href=\'/privacy-policy\' target=\'_blank\'>privacy policy</a> and to be contacted regarding my selected event.',
      nba_marketing_email_consent: 'I agree to our privacy policy and to be contacted regarding my selected event.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>', 
      nba_privacy_policy_consent: 'I agree to the <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA Terms of Use</a> and <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Privacy Policy</a>.',
      nba_products_services_consent: 'Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA Experiences.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Next',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      phone: 'Phone',
      reseller_checkbox: 'Are you a Travel Adviser/Reseller?',
      sign_up_for_nba_info: 'Sign Up For NBA Info',
      submit: 'Submit'
    },
    logIn: 'Log In',
    logInOrSignUp: 'Log In or Sign Up to Continue',
    messages: {
      sensitiveData: 'Please do not enter sensitive information',
      close: 'Close',
      questions: 'If you have immediate questions or concerns, please call',
      required: 'This field is required',
      sent_to_email: 'Your inquiry has been forwarded to a {brand_name} representative who will be in touch soon! A confirmation will be sent to your email, {email}.',
      thank_you: 'Thank You!'
    },
    mementoCodes: MementoCodes.en(),
    no: 'No',
    noHotel: 'No Hotel',
    noPackage: 'No Package',
    packageNotUpdated: 'Package was not able to update',
    paidDate: 'paid {date}',
    parkingPass: 'Would you like to add { parkingPassQty } complimentary parking pass to your order?',
    passwordComplexity: 'Password must include at least one lowercase letter, one uppercase letter, one special character and one digit',
    payment_term_1: 'Pay in Full Today',
    payment_term_2: 'Pay 50% today and 50% later',
    payment_term_3: 'Pay 1/3 today and 2 more payments later',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Please review the following fields:',
    remove: 'Remove',
    request_packages: 'Request Information Below',
    required: 'This field is required',
    roomQtyError: 'The number of rooms may not exceed the number of packages',
    rejectRoomQuantity: 'The number of rooms may not exceed the number of packages.',
    rejectCountry: 'Country is not permitted.',
    signUp: 'Sign up',
    sorryWeCouldNot: 'Sorry, we could not process your order. Please contact us if the problem continues.',
    submitFormError: 'Sorry, we could not submit your request.  Please try again later',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Today',
    totalDueToday: 'Total Due Today',
    viewDetails: 'View Details»',
    yes: 'Yes'
  },
  ar: {
    eventInterests: EventInterests.ar(),
    cart: Cart.ar(),
    cancellation_form: CancellationForm.ar(),
    checkout: {
      addOn: CheckoutAddOns.ar(),
      addPoints: 'استخدم {programName} الخاصّ بك',
      address: CheckoutAddress.ar(),
      hotelDetails: CheckoutHotelDetails.ar(),
      docusign: CheckoutTermsAndConditions.ar(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.ar(),
      protectGroup: ProtectGroup.ar(),
      yourPoints: 'الخاصّ بك {programName}',
    },
    confirmation: Confirmation.ar(),
    creditCardForm: CreditCardForm.ar(),
    f1TvCodes: F1TvCodes.ar(),
    fanaticCodes: FanaticCodes.ar(),
    formPackage: FormPackage.ar(),
    h4h: H4H.ar(),
    signIn: SignIn.ar(),
    navCart: NavCart.ar(),
    orders: Orders.ar(),
    opportunity: Opportunity.ar(),
    paymentTerms: PaymentTerms.ar(),
    roomingLists: RoomingLists.ar(),
    seatingChart: SeatingChart.ar(),
    shipmentCodes: ShipmentCodes.ar(),
    topBar: {
      authentics: 'AUTHENTICS أصالة ',
      store: 'المتجر',
      tickets: 'التذاكر',
      hospitality: 'الضيافة',
      experiences: 'التجارب',
      sign_in_register: '<i class="icon-account"></i> سجّل الدخول <em>أو</em> اشترك',
    },
    cookie: {
      cookie_consent: ' <a href="/cookies-policy" target="_blank">نحن نستخدم ملفات تعريف الارتباط لتخصيص المحتوى وتحليل حركة المرور لدينا والتقاط البيانات الّتي يوفّرها زوّارنا للاتّصال بهم بشأن الأحداث والخدمات الّتي نقدمها. أنت توافق علىإذا واصلت استعمال هذا الموقع</a>ملفّات تعريف الارتباط الخاصّة بنا',
      cookieConsentGpeFirst: 'نستخدم ملفّات تعريف الارتباط في موقعنا. اكتشف المزيد',
      cookieConsentGpeLink: 'هنا',
      cookieConsentGpeLast: '.سيتّصل بك فريق خدمة الحرفاء قريبًا حول استفساراتك',
      continue: 'واصلْ',
    },
    customer_service_form: {
      thank_you: 'Thank you for your submission!',
      message: '.سيتّصل بك فريق خدمة الحرفاء قريبًا حول استفساراتك',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: ' لإتمام عملية الشراء American Express يتعيّن عليك استخدام بطاقة ',
    creditCardNumber: 'رقم البطاقة البنكيّة',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV رمز التحقّق من البطاقة ',
    dueDate: 'آخر أجل {date}',
    expirationDate: 'تاريخ انتهاء الصلاحيّة (الشهر / السنة)',
    forgotYourPassword: 'هل نسيت كلمة العبور؟',
    form: {
      checkin_date: 'تاريخ الوصول إلى النزل',
      comments: 'التعليقات',
      company_name: 'اسم الشركة',
      corporate: 'هل هذا الطلب تابع لمجموعة شركات؟',
      confirmation_number: 'رقم التأكيد',
      email: 'البريد الإلكترونيّ',
      event: 'الحدث/الفعاليّة',
      first_name: 'الاسم',
      get_info: 'اُحْصُل على المعلومة',
      how_many: 'عدد الأشخاص',
      hotel_name: 'اسم الفندق',
      last_name: 'اللقب',
      event_or_company_name: 'اسم الحدث / الشركة',
      marketing_email_consent: 'أوافق على <a href=\'/privacy-policy\' target=\'/blank\'>سياسة الخصوصيّة</a> وأن يتمّ الاتّصال بي بخصوص الحدث الّذي اخترتُه',
      nba_marketing_email_consent: '.أوافق على سياسة الخصوصيّة الخاصّة بموقعكم وأن يقع الاتّصال بي، بخصوص الحدث الّذي اِخْتُرته',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'والحجر الصحّي ومرض أفراد العائلة COVID-19 عدوى – <a href="https://refundable.me/covid/" target="_blank">اطّلع على التفاصيل</a>',
      nba_products_services_consent: 'NBA يُرْجى استخدام معلوماتي الشخصيّة في الرابطة الوطنيّة لكرة السلّة الأمريكيّةNBA لإرسال رسائل وإعلانات حول منتجات ومبادرات الرابطة الوطنيّة لكرة السلّة الأمريكيّة',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'التالي',
      password: 'كلمة العبور',
      password_confirmation: 'تأكيد كلمة العبور',
      phone: 'الهاتف',
      reseller_checkbox: 'هل أنت مستشار رِحْلات / موزّع؟',
      sign_up_for_nba_info: ' NBA اشترك للحصول على معلومات',
      submit: 'قدّم/أرْسِلْ',
    },
    logIn: 'تسجيل الدخول',
    logInOrSignUp: 'سجّل دخولك أو اشترك للمُواصلة',
    messages: {
      sensitiveData: 'من فضلك لا تُدْخل بيانات حسّاسة',
      close: 'قريب',
      questions: 'إذا كانت لديك إشكاليّات أو استفسارات آنيّة، يُرجى الاتّصال بنا',
      required: 'بيانات هذه الخانة مطلوبة',
      sent_to_email: 'مقابل 50 دولار F1 إليك رمز متجر',
      thank_you: 'شكرا',
    },
    mementoCodes: MementoCodes.ar(),
    no: 'لا توجد أماكن إقامة',
    noHotel: 'لا توجد باقات',
    noPackage: 'لا توجد باقات',
    packageNotUpdated: 'الباقة غير قابلة للتحديثات',
    paidDate: 'تمّت عمليّة الدفع {date}',
    parkingPass: 'هل ترغب في إضافة تصريح ركن سيّارة مجاني  {parkingPassQty} إلى طلبك؟',
    passwordComplexity: 'يجب أن تتضمّن كلمة المرور حروف ورموز وأرقام',
    payment_term_1: 'ادفع الثمن كاملاً اليوم',
    payment_term_2: 'ادفع % 50 اليوم و% 50 لاحقًا',
    payment_term_3: 'ادفع 3/1 اليوم والقسطين المُتبقّيَيْن لاحقا',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: ':يرجى مراجعة البيانات التالية',
    remove: 'مطلوب',
    request_packages: 'طلب المعلومات أدناه',
    required: 'بيانات هذه الخانة مطلوبة',
    roomQtyError: 'لا يجوز أن يتجاوز عدد الغرف عدد الباقات',
    rejectRoomQuantity: 'لا يجوز أن يتجاوز عدد الغرف عدد الباقات',
    rejectCountry: 'الخدمات في هذا البلد غير مُتاحة',
    signUp: 'اشترك معنا',
    sorryWeCouldNot: ' .تُطَبّق ساعات العمل المعمول بها في الولايات المتّحدة الأمريكيّة ',
    submitFormError: 'المعذرة، لم نتمكّن من تقديم طلبك. يُرجى إعادة المحاولة في وقت لاحق',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'هذا اليوم',
    totalDueToday: 'Total Due Today',
    viewDetails: 'اُنظر التفاصيل»',
    yes: 'نعم',
  },
  de: {
    eventInterests: EventInterests.de(),
    cart: Cart.de(),
    cancellation_form: CancellationForm.de(),
    checkout: {
      addOn: CheckoutAddOns.de(),
      addPoints: 'Nutzen Sie Ihr {programName}',
      address: CheckoutAddress.de(),
      hotelDetails: CheckoutHotelDetails.de(),
      docusign: CheckoutTermsAndConditions.de(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.de(),
      protectGroup: ProtectGroup.de(),
      yourPoints: 'Ihr {programName}',
    },
    confirmation: Confirmation.de(),
    creditCardForm: CreditCardForm.de(),
    f1TvCodes: F1TvCodes.de(),
    fanaticCodes: FanaticCodes.de(),
    formPackage: FormPackage.de(),
    h4h: H4H.de(),
    signIn: SignIn.de(),
    navCart: NavCart.de(),
    orders: Orders.de(),
    opportunity: Opportunity.de(),
    paymentTerms: PaymentTerms.de(),
    roomingLists: RoomingLists.de(),
    seatingChart: SeatingChart.de(),
    shipmentCodes: ShipmentCodes.de(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'STORE',
      tickets: 'TICKETS',
      hospitality: 'HOSPITALITY',
      experiences: 'ERLEBNISSE',
      sign_in_register: '<i class="icon-account"></i> ANMELDEN <em>ODER</em> REGISTRIEREN',
    },
    cookie: {
      cookie_consent: 'Wir verwenden Cookies, um Inhalte zu personalisieren, unseren Web-Datenverkehr zu analysieren und Informationen zu erfassen, die von unseren Besuchern zur Verfügung gestellt werden, um sie bezüglich unserer Veranstaltungen und Dienstleistungen zu kontaktieren. Sie akzeptieren <a href="/cookies-policy" target="_blank">unsere Cookies</a>, wenn Sie diese Webseite weiterhin nutzen.',
      cookieConsentGpeFirst: 'Wir verwenden Cookies. Erfahren Sie mehr',
      cookieConsentGpeLink: 'hier',
      cookieConsentGpeLast: '. Durch die Nutzung der Website akzeptieren Sie diese Bestimmungen.',
      continue: 'Fortfahren',
    },
    customer_service_form: {
      thank_you: 'Vielen Dank für Ihre Übermittlung!',
      message: 'Unser Kundenbetreuungsteam wird Sie bezüglich Ihrer Anfrage in Kürze kontaktieren.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'Sie müssen eine American Express-Karte verwenden, um Ihren Einkauf abzuschließen.',
    creditCardNumber: 'Kreditkartennummer',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'Kartenprüfnummer (CVV)',
    dueDate: 'Fällig am {date}',
    expirationDate: 'Verfallsdatum (MM/YY)',
    forgotYourPassword: 'Passwort vergessen?',
    form: {
      checkin_date: 'Check-in Datum',
      comments: 'Kommentare',
      company_name: 'Firmenname',
      corporate: 'Ist diese Anfrage für ein Unternehmen?',
      confirmation_number: 'Bestätigungsnummer',
      email: 'E-Mail',
      event: 'Veranstaltung',
      first_name: 'Vorname',
      get_info: 'Info erhalten',
      how_many: 'Anzahl Personen',
      hotel_name: 'Hotelname',
      last_name: 'Nachname',
      event_or_company_name: 'Veranstaltungsname / Unternehmen',
      marketing_email_consent: 'Ich stimme den <a href=\'/privacy-policy\' target=\'/blank\'>Datenschutzbestimmungen</a> zu und bin damit einverstanden, dass ich bezüglich meiner ausgewählten Veranstaltung kontaktiert werde.',
      nba_marketing_email_consent: 'Ich stimme den Datenschutzbestimmungen zu und bin damit einverstanden, dass ich bezüglich meiner ausgewählten Veranstaltung kontaktiert werden darf.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'Ich stimme den <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA-Nutzungsbedingungen</a> und <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Datenschutzbestimmungen</a> zu.',
      nba_products_services_consent: 'Ich bin damit einverstanden, dass die NBA meine persönlichen Daten verwendet, um mir Nachrichten und Werbung über Produkte und Initiativen der NBA und der NBA Experiences zu schicken.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Nächster',
      password: 'Passwort',
      password_confirmation: 'Passwort Bestätigung',
      phone: 'Telefon',
      reseller_checkbox: 'Sind Sie ein Reiseberater / Vertriebspartner?',
      sign_up_for_nba_info: 'Anmeldung für NBA-Info',
      submit: 'Abschicken',
    },
    logIn: 'Anmelden',
    logInOrSignUp: 'Melden Sie sich an oder Registrieren Sie sich um weiterzufahren',
    messages: {
      sensitiveData: 'Bitte geben Sie keine vertraulichen Daten ein.',
      close: 'Schließen',
      questions: 'Falls Sie dringende Fragen oder Anliegen haben, rufen Sie uns bitte an',
      required: 'Dies ist ein Pflichtfeld',
      sent_to_email: 'Ihre Anfrage wurde an einen {brand_name} Verkaufsberater weitergeitet, der Sie in Kürze kontaktieren wird! Eine Bestätigung wird an Ihre E-Mail-Adresse, {email}, geschickt.',
      thank_you: 'Vielen Dank!',
    },
    mementoCodes: MementoCodes.de(),
    no: 'Nein',
    noHotel: 'Kein Hotel',
    noPackage: 'Kein Paket',
    packageNotUpdated: 'Das Paket kann nicht aktualisiert werden',
    paidDate: 'bezahlt am {date}',
    parkingPass: 'Möchten Sie { parkingPassQty } kostenlose(n) Parkausweis(e) zu Ihrer Bestellung hinzufügen?',
    passwordComplexity: 'Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Zahl beinhalten.',
    payment_term_1: 'Heute den Gesamtbetrag bezahlen',
    payment_term_2: 'Bezahlen Sie 50% heute und 50% später',
    payment_term_3: 'Bezahlen Sie heute 1/3 und 2 weitere Zahlungen später',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Bitte überprüfen Sie die folgenden Felder:',
    remove: 'Entfernen',
    request_packages: 'Informationen anfordern',
    required: 'Dies ist ein Pflichtfeld',
    roomQtyError: 'Die Anzahl der Zimmer darf die Anzahl der Pakete nicht überschreiten',
    rejectRoomQuantity: 'Die Anzahl der Zimmer darf die Anzahl der Pakete nicht überschreiten.',
    rejectCountry: '',
    signUp: 'Anmelden',
    sorryWeCouldNot: 'Es tut uns leid, aber wir können mit Ihrer Bestellung nicht weiterfahren. Bitte kontaktieren Sie uns, falls das Problem weiter besteht.',
    submitFormError: 'Es tut uns leid, wir konnten Ihre Anfrage nicht übermitteln. Bitte versuchen Sie es später noch einmal.',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Heute',
    totalDueToday: 'Total Due Today',
    viewDetails: 'Details anzeigen»',
    yes: 'Ja',
  },
  'en-GB': {
    eventInterests: EventInterests.enGB(),
    cart: Cart.enGB(),
    cancellation_form: CancellationForm.enGB(),
    checkout: {
      addOn: CheckoutAddOns.enGB(),
      addPoints: 'Use your {programName}',
      address: CheckoutAddress.enGB(),
      hotelDetails: CheckoutHotelDetails.enGB(),
      docusign: CheckoutTermsAndConditions.enGB(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.enGB(),
      protectGroup: ProtectGroup.enGB(),
      yourPoints: 'Your {programName}',
    },
    confirmation: Confirmation.enGB(),
    creditCardForm: CreditCardForm.enGB(),
    f1TvCodes: F1TvCodes.enGB(),
    fanaticCodes: FanaticCodes.enGB(),
    formPackage: FormPackage.enGB(),
    h4h: H4H.enGB(),
    signIn: SignIn.enGB(),
    navCart: NavCart.enGB(),
    orders: Orders.enGB(),
    opportunity: Opportunity.enGB(),
    paymentTerms: PaymentTerms.enGB(),
    roomingLists: RoomingLists.enGB(),
    seatingChart: SeatingChart.enGB(),
    shipmentCodes: ShipmentCodes.enGB(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'STORE',
      tickets: 'TICKETS',
      hospitality: 'HOSPITALITY',
      experiences: 'EXPERIENCES',
      sign_in_register: '<i class="icon-account"></i> SIGN IN <em>OR</em> REGISTER',
    },
    cookie: {
      cookie_consent: 'We use cookies to personalize content analyze our traffic and to capture information provided by our visitors to contact them about our events and services. You consent to <a href="/cookies-policy" target="_blank">our cookies</a> if you continue to use this website.',
      cookieConsentGpeFirst: 'We use cookies. Find out more',
      cookieConsentGpeLink: 'here',
      cookieConsentGpeLast: '. By using the site you accept the policy.',
      continue: 'Continue',
    },
    customer_service_form: {
      thank_you: 'Thank you for your submission!',
      message: 'Our customer service team will be in contact with you shortly about your inquiry.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'You must use an American Express card to complete your purchase.',
    creditCardNumber: 'Credit Card Number',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'due {date}',
    expirationDate: 'Expiration Date (mm / yy)',
    forgotYourPassword: 'Forgot your password?',
    form: {
      checkin_date: 'Check In Date',
      comments: 'Comments',
      company_name: 'Company Name',
      corporate: 'Is this request for a corporate group?',
      confirmation_number: 'Confirmation Number',
      email: 'Email',
      event: 'Event',
      first_name: 'First Name',
      get_info: 'Get Info',
      how_many: 'Number of People',
      hotel_name: 'Hotel Name',
      last_name: 'Last Name',
      event_or_company_name: 'Event Name / Company',
      marketing_email_consent: 'I agree to our <a href=\'/privacy-policy\' target=\'_blank\'>privacy policy</a> and to be contacted regarding my selected event.',
      nba_marketing_email_consent: 'I agree to our privacy policy and to be contacted regarding my selected event.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'I agree to the <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA Terms of Use</a> and <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Privacy Policy</a>.',
      nba_products_services_consent: 'Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA Experiences.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Next',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      phone: 'Phone',
      reseller_checkbox: 'Are you a Travel Adviser/Reseller?',
      sign_up_for_nba_info: 'Sign Up For NBA Info',
      submit: 'Submit',
    },
    logIn: 'Log In',
    logInOrSignUp: 'Log In or Sign Up to Continue',
    messages: {
      sensitiveData: 'Please do not enter sensitive information',
      close: 'Close',
      questions: 'If you have immediate questions or concerns, please call',
      required: 'This field is required',
      sent_to_email: 'Your inquiry has been forwarded to a {brand_name} representative who will be in touch soon! A confirmation will be sent to your email, {email}.',
      thank_you: 'Thank You!',
    },
    mementoCodes: MementoCodes.enGB(),
    no: 'No',
    noHotel: 'No Hotel',
    noPackage: 'No Package',
    packageNotUpdated: 'Package was not able to update',
    paidDate: 'paid {date}',
    parkingPass: 'Would you like to add { parkingPassQty } complimentary parking pass to your order?',
    passwordComplexity: 'Password must include at least one lowercase letter, one uppercase letter, one special character and one digit',
    payment_term_1: 'Pay in Full Today',
    payment_term_2: 'Pay 50% today and 50% later',
    payment_term_3: 'Pay 1/3 today and 2 more payments later',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Please review the following fields:',
    remove: 'Remove',
    request_packages: 'Request Information Below',
    required: 'This field is required',
    roomQtyError: 'The number of rooms may not exceed the number of packages',
    rejectRoomQuantity: 'The number of rooms may not exceed the number of packages.',
    rejectCountry: 'Country is not permitted.',
    signUp: 'Sign up',
    sorryWeCouldNot: 'Sorry, we could not process your order. Please contact us if the problem continues.',
    submitFormError: 'Sorry, we could not submit your request.  Please try again later',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Today',
    totalDueToday: 'Total Due Today',
    viewDetails: 'View Details»',
    yes: 'Yes',
  },
  'es-MX': {
    eventInterests: EventInterests.esMX(),
    cart: Cart.esMX(),
    cancellation_form: CancellationForm.esMX(),
    checkout: {
      addOn: CheckoutAddOns.esMX(),
      addPoints: 'Use su {programName}',
      address: CheckoutAddress.esMX(),
      hotelDetails: CheckoutHotelDetails.esMX(),
      docusign: CheckoutTermsAndConditions.esMX(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.esMX(),
      protectGroup: ProtectGroup.esMX(),
      yourPoints: 'Su {programName}',
    },
    confirmation: Confirmation.esMX(),
    creditCardForm: CreditCardForm.esMX(),
    f1TvCodes: F1TvCodes.esMX(),
    fanaticCodes: FanaticCodes.esMX(),
    formPackage: FormPackage.esMX(),
    h4h: H4H.esMX(),
    signIn: SignIn.esMX(),
    navCart: NavCart.esMX(),
    orders: Orders.esMX(),
    opportunity: Opportunity.esMX(),
    paymentTerms: PaymentTerms.esMX(),
    roomingLists: RoomingLists.esMX(),
    seatingChart: SeatingChart.esMX(),
    shipmentCodes: ShipmentCodes.esMX(),
    topBar: {
      authentics: 'AUTÉNTICOS',
      store: 'TIENDA',
      tickets: 'BOLETOS',
      hospitality: 'HOSPITALIDAD',
      experiences: 'EXPERIENCIAS',
      sign_in_register: '<i class="icon-account"></i> INICIAR SESIÓN <em>OR</em> REGISTRARSE',
    },
    cookie: {
      cookie_consent: 'Usamos cookies para personalizar el contenido, analizar nuestro tráfico y capturar la información proporcionada por nuestros visitantes para contactarlos en relación a nuestros eventos y servicios. Usted consiente <a href="/cookies-policy" target="_blank">el uso de las cookies</a> si continúa utilizando este sitio web.',
      cookieConsentGpeFirst: 'Usamos cookies, obtenga más información.',
      cookieConsentGpeLink: 'aquí',
      cookieConsentGpeLast: 'Al usar el sitio acepta la política',
      continue: 'Continuar',
    },
    customer_service_form: {
      thank_you: '¡Muchas gracias!',
      message: 'Nuestro equipo de atención al cliente se pondrá en contacto con usted en breve acerca de su consulta.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'Debe usar una tarjeta American Express para realizar su compra.',
    creditCardNumber: 'Número de Tarjeta de Crédito',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'a pagar {date}',
    expirationDate: 'Fecha de Vencimiento (mm / yy)',
    forgotYourPassword: '¿Olvidó su contraseña?',
    form: {
      checkin_date: 'Fecha de entrada',
      comments: 'Comentarios',
      company_name: 'Nombre de la Empresa',
      corporate: '¿Esta solicitud es para un grupo corporativo?',
      confirmation_number: 'Número de confirmación',
      email: 'Correo electrónico',
      event: 'Evento',
      first_name: 'Nombre',
      get_info: 'Obtener información',
      how_many: 'Número de personas',
      hotel_name: 'Nombre del Hotel',
      last_name: 'Apellido',
      event_or_company_name: 'Nombre del evento / Compañía',
      marketing_email_consent: 'Acepto la <a href=\'/privacy-policy\' target=\'/blank\'>política de privacidad</a> y acepto ser contactado con respecto a mi evento seleccionado.',
      nba_marketing_email_consent: 'Acepto la política de privacidad y acepto ser contactado con respecto a mi evento seleccionado.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'Acepto la <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA Terms of Use</a> and <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Política de Privacidad</a>.',
      nba_products_services_consent: 'Por favor, utilice mi información personal para que la NBA me envíe mensajes y anuncios sobre productos e iniciativas de la NBA y NBA Experiences.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Siguiente',
      password: 'Contraseña',
      password_confirmation: 'confirmación de contraseña',
      phone: 'Teléfono',
      reseller_checkbox: '¿Es usted un Agente de Viajes/Revendedor?',
      sign_up_for_nba_info: 'Registrarse para obtener info de la NBA',
      submit: 'Enviar',
    },
    logIn: 'Entrar',
    logInOrSignUp: 'Inicie Sesión o Regístrese para Continuar',
    messages: {
      sensitiveData: 'Favor de no ingresar datos sensibles.',
      close: 'Cerrar',
      questions: 'Si tiene preguntas o dudas inmediatas, favor de llamarnos',
      required: 'Este campo es requerido',
      sent_to_email: '¡Su pregunta ha sido enviada a un representante de {brand_name} que se pondrá en contacto con usted muy pronto! Una confirmación ha sido enviada a su correo electrónico, {email}.',
      thank_you: '¡Gracias!',
    },
    mementoCodes: MementoCodes.esMX(),
    no: 'No ',
    noHotel: 'Sin Hotel',
    noPackage: 'Sin Paquete',
    packageNotUpdated: 'Paquete no pudo actualizarse',
    paidDate: 'pagado {date}',
    parkingPass: '¿Le gustaría agregar { parkingPassQty } pase(s) de estacionamiento de cortesía a su pedido?',
    passwordComplexity: 'La contraseña debe incluir por lo menos una letra minúscula, una mayúscula, un símbolo especial y un dígito.',
    payment_term_1: 'Realice el Pago Total Hoy',
    payment_term_2: 'Realice un Pago por el 50% y pague el 50% restante después',
    payment_term_3: 'Realice un Pago por 1/3 y haga dos pagos más después',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Favor de revisar los siguientes campos:',
    remove: 'Eliminar',
    request_packages: 'Solicita Información Abajo',
    required: 'Este campo es requerido',
    roomQtyError: 'El número de habitaciones no puede exceder el número de paquetes',
    rejectRoomQuantity: 'El número de habitaciones no puede exceder el número de paquetes.',
    rejectCountry: '',
    signUp: 'registrarse',
    sorryWeCouldNot: 'Lo sentimos, no podemos procesar su orden. Favor de contactarnos si el problema continúa.',
    submitFormError: 'Lo sentimos, no pudimos enviar su solicitud. Favor de intentar nuevamente más tarde.',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Hoy',
    totalDueToday: 'Total Due Today',
    viewDetails: 'Ver Detalles»',
    yes: 'Sí',
  },
  es: {
    eventInterests: EventInterests.es(),
    cart: Cart.es(),
    cancellation_form: CancellationForm.es(),
    checkout: {
      addOn: CheckoutAddOns.es(),
      addPoints: 'Utiliza tu {programName}',
      address: CheckoutAddress.es(),
      hotelDetails: CheckoutHotelDetails.es(),
      docusign: CheckoutTermsAndConditions.es(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.es(),
      protectGroup: ProtectGroup.es(),
      yourPoints: 'Tu {programName}',
    },
    confirmation: Confirmation.es(),
    creditCardForm: CreditCardForm.es(),
    f1TvCodes: F1TvCodes.es(),
    fanaticCodes: FanaticCodes.es(),
    formPackage: FormPackage.es(),
    h4h: H4H.es(),
    signIn: SignIn.es(),
    navCart: NavCart.es(),
    orders: Orders.es(),
    opportunity: Opportunity.es(),
    paymentTerms: PaymentTerms.es(),
    roomingLists: RoomingLists.es(),
    seatingChart: SeatingChart.es(),
    shipmentCodes: ShipmentCodes.es(),
    topBar: {
      authentics: 'AUTÉNTICOS',
      store: 'TIENDA',
      tickets: 'ENTRADAS',
      hospitality: 'HOSPITALIDAD',
      experiences: 'EXPERIENCIAS',
      sign_in_register: '<i class="icon-account"></i> INSCRIBIRSE <em>O</em> REGISTRARSE',
    },
    cookie: {
      cookie_consent: 'Utilizamos cookies para personalizar el contenido, analizar nuestro tráfico y capturar la información proporcionada por nuestros visitantes para ponernos en contacto con ellos sobre nuestros eventos y servicios. Consientes a <a href="/cookies-policy" target="_blank">nuestras cookies</a> si continúas utilizando este sitio web.',
      cookieConsentGpeFirst: 'Utilizamos cookies. Más información',
      cookieConsentGpeLink: 'aquí',
      cookieConsentGpeLast: '. Al utilizar el sitio, aceptas la política.',
      continue: 'Continuar',
    },
    customer_service_form: {
      thank_you: '¡Gracias por tu aportación!',
      message: 'Nuestro equipo de atención al cliente se pondrá en contacto contigo en breve para tratar tu consulta.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'Debes utilizar una tarjeta American Express para completar tu compra.',
    creditCardNumber: 'Número de Tarjeta de Crédito',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'con plazo de {date}',
    expirationDate: 'Fecha de caducidad (mm / aa)',
    forgotYourPassword: '¿Has olvidado tu contraseña?',
    form: {
      checkin_date: 'Fecha De Entrada ',
      comments: 'Comentarios',
      company_name: 'Nombre de la Empresa',
      corporate: '¿Esta solicitud es para un grupo empresarial?',
      confirmation_number: 'Número de Confirmación',
      email: 'Correo electrónico',
      event: 'Evento',
      first_name: 'Nombre',
      get_info: 'Obtener Información',
      how_many: 'Número de Personas',
      hotel_name: 'Nombre del Hotel',
      last_name: 'Apellido',
      event_or_company_name: 'Nombre del Evento / Empresa',
      marketing_email_consent: 'Estoy de acuerdo con nuestra <a href=\'/privacy-policy\' target=\'/blank\'>política de privacidad</a> y a ser contactado con respecto a mi evento seleccionado.',
      nba_marketing_email_consent: 'Estoy de acuerdo con nuestra política de privacidad y con que se me contacte en relación con el evento que he seleccionado.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'Estoy de acuerdo con los <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>Términos de Uso de la NBA</a> y <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Política de Privacidad</a>.',
      nba_products_services_consent: 'Por favor, utilicen mis datos personales para que la NBA me envíe mensajes y publicidad sobre productos e iniciativas de la NBA y de la NBA.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Siguiente',
      password: 'Contraseña',
      password_confirmation: 'Contraseña confirmada',
      phone: 'Teléfono',
      reseller_checkbox: '¿Eres un Asesor/Revendedor de Viajes?',
      sign_up_for_nba_info: 'Inscríbete Para Información de la NBA',
      submit: 'Enviar',
    },
    logIn: 'Iniciar Sesión',
    logInOrSignUp: 'Inicia Sesión o Regístrate para Continuar',
    messages: {
      sensitiveData: 'Favor de no ingresar datos sensibles.',
      close: 'Cerrar',
      questions: 'Si tienes preguntas o dudas inmediatas, por favor llama al',
      required: 'Este apartado es obligatorio',
      sent_to_email: '¡Tu consulta ha sido remitida a un {brand_name} representante que se pondrá en contacto en breve! Se enviará una confirmación a tu correo electrónico, {email}.',
      thank_you: '¡Gracias!',
    },
    mementoCodes: MementoCodes.es(),
    no: 'No',
    noHotel: 'Sin Hotel',
    noPackage: 'Sin Paquete',
    packageNotUpdated: 'El Paquete no ha podido actualizarse',
    paidDate: 'pagado {date}',
    parkingPass: '¿Quieres añadir { parkingPassQty } tarjeta de aparcamiento gratuita a tu pedido?',
    passwordComplexity: 'La contraseña debe incluir al menos una letra minúscula, una mayúscula, un carácter especial y un dígito',
    payment_term_1: 'Pagar por Completo Hoy',
    payment_term_2: 'Paga el 50% hoy y el 50% después',
    payment_term_3: 'Pagar 1/3 hoy y 2 pagos más tarde',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Por favor, revisa los siguientes apartados:',
    remove: 'Eliminar',
    request_packages: 'Solicita Información a Continuación',
    required: 'Este apartado es obligatorio',
    roomQtyError: 'El número de habitaciones no puede superar el número de paquetes',
    rejectRoomQuantity: 'El número de habitaciones no puede superar el número de paquetes.',
    rejectCountry: 'El país no está permitido.',
    signUp: 'Regístrate',
    sorryWeCouldNot: 'Lo sentimos, no hemos podido procesar tu pedido. Por favor, contáctanos si el problema continúa.',
    submitFormError: 'Lo sentimos, no hemos podido enviar tu solicitud. Por favor, inténtalo de nuevo más tarde',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Hoy',
    totalDueToday: 'Total Due Today',
    viewDetails: 'Ver Detalles»',
    yes: 'Sí',
  },
  fr: {
    eventInterests: EventInterests.fr(),
    cart: Cart.fr(),
    cancellation_form: CancellationForm.fr(),
    checkout: {
      addOn: CheckoutAddOns.fr(),
      addPoints: 'Utilisez votre {programName}',
      address: CheckoutAddress.fr(),
      hotelDetails: CheckoutHotelDetails.fr(),
      docusign: CheckoutTermsAndConditions.fr(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.fr(),
      protectGroup: ProtectGroup.fr(),
      yourPoints: 'Votre {programName}',
    },
    confirmation: Confirmation.fr(),
    creditCardForm: CreditCardForm.fr(),
    f1TvCodes: F1TvCodes.fr(),
    fanaticCodes: FanaticCodes.fr(),
    formPackage: FormPackage.fr(),
    h4h: H4H.fr(),
    signIn: SignIn.fr(),
    navCart: NavCart.fr(),
    orders: Orders.fr(),
    opportunity: Opportunity.fr(),
    paymentTerms: PaymentTerms.fr(),
    roomingLists: RoomingLists.fr(),
    seatingChart: SeatingChart.fr(),
    shipmentCodes: ShipmentCodes.fr(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'BOUTIQUE',
      tickets: 'BILLETS',
      hospitality: 'ACCUEIL VIP',
      experiences: 'EXPÉRIENCES',
      sign_in_register: '<i class="icon-account"></i> SE CONNECTER <em>OU</em> S\'INSCRIRE',
    },
    cookie: {
      cookie_consent: 'Nous utilisons les cookies pour personnaliser nos contenus, analyser notre traffic et recueillir des informations sur nos visiteurs afin de les contacter à propos de nos événements et services. En continuant à utiliser ce site web, vous acceptez <a href="/cookies-policy" target="_blank">nos cookies</a>.',
      cookieConsentGpeFirst: 'Nous utilisons des cookies. En savoir plus',
      cookieConsentGpeLink: 'ici',
      cookieConsentGpeLast: '. En utilisant ce site, vous acceptez notre politique.',
      continue: 'Continuer',
    },
    customer_service_form: {
      thank_you: 'Merci de votre contribution !',
      message: 'Notre équipe de service client vous contactera sous peu au sujet de votre question.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'Vous devez utiliser une carte American Express pour compléter votre achat.',
    creditCardNumber: 'Numéro de carte de crédit',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'Cryptogramme visuel (CVV)',
    dueDate: 'dû avant le {date}',
    expirationDate: 'Date d\'expiration (mm/aa)',
    forgotYourPassword: 'Mot de passe oublié ?',
    form: {
      checkin_date: 'Date d\'arrivée',
      comments: 'Commentaires',
      company_name: 'Nom de la société',
      corporate: 'Votre requête concerne-t-elle un groupe d\'entreprise ?',
      confirmation_number: 'Numéro de confirmation',
      email: 'Adresse courriel',
      event: 'Événement',
      first_name: 'Prénom',
      get_info: 'Obtenir des informations',
      how_many: 'Nombre de personne',
      hotel_name: 'Nom de l\'hôtel',
      last_name: 'Nom de famille',
      event_or_company_name: 'Nom de l\'événement / Entreprise',
      marketing_email_consent: 'J\'accepte notre <a href=\'/privacy-policy\' target=\'/blank\'>politique de confidentialité</a> et d\'être contacté pour ce qui a trait à mon événement sélectionné.',
      nba_marketing_email_consent: 'J\'accepte la politique de confidentialité et d\'être contacté au sujet de l\'événement de mon choix.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'J\'accepte les <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>conditions d\'utilisation</a> et <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>la politique de confidentialité de la NBA</a>.',
      nba_products_services_consent: 'Veuillez utiliser mes informations pour que la NBA m\'envoie des messages et des publicités au sujet des produits et des initiatives de la NBA.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Suivant',
      password: 'Mot de passe',
      password_confirmation: 'Confirmation du mot de passe',
      phone: 'Téléphone',
      reseller_checkbox: 'Êtes-vous un agent/une agence de voyage ?',
      sign_up_for_nba_info: 'S\'inscrire à NBA Info',
      submit: 'Envoyer',
    },
    logIn: 'Se connecter',
    logInOrSignUp: 'Connectez-vous ou inscrivez-vous pour continuer',
    messages: {
      sensitiveData: 'Attention, évitez dentrer des données sensibles.',
      close: 'Fermer',
      questions: 'Si vous avez des questions ou des préoccupations immédiates, veuillez nous appeler',
      required: 'Ce champ est obligatoire',
      sent_to_email: 'Votre requête a été renvoyée à un représentant de {brand_name}, qui vous contactera sous peu ! Un courriel de confirmation vous sera envoyé à votre adresse courriel, {email}.',
      thank_you: 'Merci !',
    },
    mementoCodes: MementoCodes.fr(),
    no: 'Non',
    noHotel: 'Aucun hôtel',
    noPackage: 'Aucun forfait',
    packageNotUpdated: 'Impossible de mettre à jour le forfait',
    paidDate: 'payé le {date}',
    parkingPass: 'Désirez-vous ajouter { parkingPassQty } ticket(s) de stationnement gratuit(s) à votre commande ?',
    passwordComplexity: 'Le mot de passe doit inclure au moins une lettre minuscule, une lettre majuscule, un caractère spécial et un chiffre.',
    payment_term_1: 'Payer le total aujourd\'hui',
    payment_term_2: 'Payer 50 % aujourd\'hui puis 50 % plus tard',
    payment_term_3: 'Payer 1/3 aujourd\'hui et 2 autres paiements plus tard',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Veuillez revoir les champs suivants :',
    remove: 'Supprimer',
    request_packages: 'Demande d\'information ci-dessous',
    required: 'Ce champ est obligatoire',
    roomQtyError: 'Le nombre de chambres ne peut pas excéder le nombre de forfaits.',
    rejectRoomQuantity: 'Le nombre de chambres ne peut pas dépasser le nombre de forfaits.',
    rejectCountry: 'Pays non autorisé.',
    signUp: 'S\'inscrire',
    sorryWeCouldNot: 'Désolé, nous n\'avons pas pu traiter votre commande. Si le problème persiste, veuillez nous conctacter.',
    submitFormError: 'Désolé, nous n\'avons pas pu soumettre votre requête. Veuillez réessayer plus tard.',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Aujourd\'hui',
    totalDueToday: 'Total Due Today',
    viewDetails: 'Voir les détails»',
    yes: 'Oui',
  },
  it: {
    eventInterests: EventInterests.it(),
    cart: Cart.it(),
    cancellation_form: CancellationForm.it(),
    checkout: {
      addOn: CheckoutAddOns.it(),
      addPoints: 'Usa {programName}',
      address: CheckoutAddress.it(),
      hotelDetails: CheckoutHotelDetails.it(),
      docusign: CheckoutTermsAndConditions.it(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.it(),
      protectGroup: ProtectGroup.it(),
      yourPoints: 'I tuoi {programName}',
    },
    confirmation: Confirmation.it(),
    creditCardForm: CreditCardForm.it(),
    f1TvCodes: F1TvCodes.it(),
    fanaticCodes: FanaticCodes.it(),
    formPackage: FormPackage.it(),
    h4h: H4H.it(),
    signIn: SignIn.it(),
    navCart: NavCart.it(),
    orders: Orders.it(),
    opportunity: Opportunity.it(),
    paymentTerms: PaymentTerms.it(),
    roomingLists: RoomingLists.it(),
    seatingChart: SeatingChart.it(),
    shipmentCodes: ShipmentCodes.it(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'NEGOZIO',
      tickets: 'BIGLIETTI',
      hospitality: 'ACCOGLIENZA',
      experiences: 'ESPERIENZE',
      sign_in_register: '<i class="icon-account"></i> ACCEDI <em>O</em> REGISTRATI',
    },
    cookie: {
      cookie_consent: 'Utilizziamo i cookie per personalizzare i contenuti, per analizzare il traffico e per raccogliere le informazioni fornite da quei visitatori che desiderino essere ricontatti per ricevere ulteriori dettagli riguardo i nostri servizi e i nostri eventi. L\'utente dà il proprio consenso all\'utilizzo di <a href="/cookies-policy" target="_blank">cookie</a> da parte nostra nel momento stesso in cui continua a utilizzare questo sito.',
      cookieConsentGpeFirst: 'Perché utilizziamo i cookie? Per saperne di più!',
      cookieConsentGpeLink: 'qui',
      cookieConsentGpeLast: '. Utilizzando il presente sito, accetti la nostra Informativa.',
      continue: 'Continua',
    },
    customer_service_form: {
      thank_you: 'Grazie per la richiesta!',
      message: 'Il nostro team ti contatterà al più presto possibile riguardo alla richiesta che hai effettuato.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'È obbligatorio utilizzare una carta American Express per completare l\'acquisto.',
    creditCardNumber: 'Numero di carta di credito',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'scadenza {date}',
    expirationDate: 'Data di scadenza (mm / aa)',
    forgotYourPassword: 'Hai dimenticato la tua password?',
    form: {
      checkin_date: 'Data di check-in',
      comments: 'Commenti',
      company_name: 'Nome dell\'azienda',
      corporate: 'La tua richiesta riguarda un gruppo aziendale?',
      confirmation_number: 'Numero di conferma',
      email: 'Email',
      event: 'Evento',
      first_name: 'Nome',
      get_info: 'MAGGIORI INFORMAZIONI',
      how_many: 'Numero di ospiti',
      hotel_name: 'Nome dell\'albergo',
      last_name: 'Cognome',
      event_or_company_name: 'Nome dell\'evento/Azienda',
      marketing_email_consent: 'Accetto <a href=\'/privacy-policy\' target=\'/blank\'>l\'Informativa sulla privacy</a> e di essere ricontattato in merito all\'evento selezionato.',
      nba_marketing_email_consent: 'Accetto l\'Informativa sulla privacy e di essere ricontattato in merito all\'evento selezionato.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'Accetto le <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>Condizioni di utilizzo</a> e l\'<a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Informativa sulla privacy</a> di NBA.',
      nba_products_services_consent: 'Autorizzo NBA a utilizzare le mie informazioni personali per inviarmi messaggi e pubblicità su prodotti e iniziative dell\'NBA e sull\'NBA.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Il prossimo',
      password: 'Parola d\'ordine',
      password_confirmation: 'conferma password',
      phone: 'Telefono',
      reseller_checkbox: 'Sei un consulente di viaggio o rivenditore?',
      sign_up_for_nba_info: 'Iscriviti per informazioni sull\'NBA',
      submit: 'Invia',
    },
    logIn: 'Accedi',
    logInOrSignUp: 'Accedi o iscriviti per continuare',
    messages: {
      sensitiveData: 'Please do not enter sensitive information',
      close: 'Chiudi',
      questions: 'Se hai domande o dubbi urgenti, contattaci pure telefonicamente',
      required: 'Campo obbligatorio',
      sent_to_email: 'La tua richiesta è stata inviata a un addetto di {brand_name} che si metterà in contatto con te al più presto! Invieremo una conferma al tuo indirizzo email: {email}.',
      thank_you: 'Grazie!',
    },
    mementoCodes: MementoCodes.it(),
    no: 'No',
    noHotel: 'Nessun albergo',
    noPackage: 'Nessun pacchetto',
    packageNotUpdated: 'Non è stato possibile aggiornare il pacchetto',
    paidDate: 'pagato il {date}',
    parkingPass: 'Desideri aggiungere { parkingPassQty } pass per l\'area parcheggio al tuo ordine?',
    passwordComplexity: 'La password deve includere almeno una lettera minuscola, una lettera maiuscola, un carattere speciale e un numero',
    payment_term_1: 'Paga tutto subito',
    payment_term_2: 'Paga il 50% subito e il 50% in un secondo momento',
    payment_term_3: 'Paga un terzo dell\'importo subito e il restante in due tranche successive',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Ti preghiamo di controllare i seguenti campi:',
    remove: 'Rimuovi',
    request_packages: 'Richiesta di informazioni',
    required: 'Campo obbligatorio',
    roomQtyError: 'Il numero di camere non può superare il numero di pacchetti',
    rejectRoomQuantity: 'Il numero di camere non può essere superiore al numero di pacchetti.',
    rejectCountry: '',
    signUp: 'Iscriviti',
    sorryWeCouldNot: 'Ci dispiace, ma non è stato possibile elaborare il tuo ordine. Ti preghiamo di contattarci nel caso in cui il problema dovesse persistere.',
    submitFormError: 'Ci dispiace, ma non ci è stato possibile inviare la tua richiesta. Per favore riprova più tardi.',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Oggi',
    totalDueToday: 'Total Due Today',
    viewDetails: 'Visualizza dettagli»',
    yes: 'Sì',
  },
  ja: {
    eventInterests: EventInterests.ja(),
    cart: Cart.ja(),
    cancellation_form: CancellationForm.ja(),
    checkout: {
      addOn: CheckoutAddOns.ja(),
      addPoints: 'お客様の {programName}を使用',
      address: CheckoutAddress.ja(),
      hotelDetails: CheckoutHotelDetails.ja(),
      docusign: CheckoutTermsAndConditions.ja(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.ja(),
      protectGroup: ProtectGroup.ja(),
      yourPoints: 'お客様の{programName}',
    },
    confirmation: Confirmation.ja(),
    creditCardForm: CreditCardForm.ja(),
    f1TvCodes: F1TvCodes.ja(),
    fanaticCodes: FanaticCodes.ja(),
    formPackage: FormPackage.ja(),
    h4h: H4H.ja(),
    signIn: SignIn.ja(),
    navCart: NavCart.ja(),
    orders: Orders.ja(),
    opportunity: Opportunity.ja(),
    paymentTerms: PaymentTerms.ja(),
    roomingLists: RoomingLists.ja(),
    seatingChart: SeatingChart.ja(),
    shipmentCodes: ShipmentCodes.ja(),
    topBar: {
      authentics: '認証済み',
      store: 'ストア',
      tickets: 'チケット',
      hospitality: 'ホスピタリティ',
      experiences: '体験',
      sign_in_register: '<i class="icon-account"></i>サインイン<em>または</em> 登録',
    },
    cookie: {
      cookie_consent: '当ウェブサイトでは、コンテンツのパーソナライズとデータトラフィック解析を行う目的およびサイト訪問者より提供された情報を取得し当社サービスおよびイベントについて訪問者に連絡する目的でクッキーを使用しています。当ウェブサイトを引き続きご利用いただくことにより、あなたは当ウェブサイトの<a href="/cookies-policy" target="_blank">クッキーに関する方針に</a> 同意するものとします。',
      cookieConsentGpeFirst: 'クッキーを使用しています。詳細を見る',
      cookieConsentGpeLink: 'こちら',
      cookieConsentGpeLast: '. サイトを使用すると、ポリシーに同意したことになります。',
      continue: '続行',
    },
    customer_service_form: {
      thank_you: 'ご提出いただきありがとうございます。',
      message: '当社のカスタマーサービスチームが、お問い合わせについてすぐにご連絡いたします。',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'アメリカン・エキスプレスのカードでご購入を完了する必要があります。',
    creditCardNumber: 'クレジットカード番号',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV（セキュリティコード）',
    dueDate: '支払い期日 {date}',
    expirationDate: '有効期限 (mm / yy)',
    forgotYourPassword: 'パスワードをお忘れですか？',
    form: {
      checkin_date: 'チェックイン日付',
      comments: 'コメント',
      company_name: '企業名',
      corporate: 'このリクエストは法人向けですか？',
      confirmation_number: '予約番号',
      email: 'メール',
      event: 'イベント',
      first_name: '名',
      get_info: '情報を取得',
      how_many: '人数',
      hotel_name: 'ホテル名',
      last_name: '姓',
      event_or_company_name: 'イベント名 / 会社名',
      marketing_email_consent: '<a href=\'/privacy-policy\' target=\'/blank\'>プライバシーポリシー</a> に同意し、選択したイベントの連絡を受けることに同意します。',
      nba_marketing_email_consent: 'プライバシー ポリシーに同意し、選択したイベントに関して連絡を受けることに同意します。',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: '<a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA 利用規約<</a> および<a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>プライバシーポリシー</a>に同意します。.',
      nba_products_services_consent: '私の個人情報は、NBAおよびNBAの商品・取り組みに関するメッセージや広告をお送りするためにご利用ください。',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: '次へ',
      password: 'パスワード',
      password_confirmation: 'パスワードを確認',
      phone: '電話番号',
      reseller_checkbox: 'あなたは旅行アドバイザー/販売店ですか？',
      sign_up_for_nba_info: 'NBA情報にサインアップ',
      submit: '送信',
    },
    logIn: 'ログイン',
    logInOrSignUp: '続けるにはログインまたは登録してください',
    messages: {
      sensitiveData: '極秘データを入力しないでください。',
      close: '閉じる',
      questions: 'お急ぎのご質問・ご用件はこちらまでお電話ください。',
      required: '入力必須項目です',
      sent_to_email: 'お問合せは、{brand_name}へ送られ担当者から 連絡があります。確認メールがお客様のメール%{email}へ送られます。',
      thank_you: 'ありがとうございました!',
    },
    mementoCodes: MementoCodes.ja(),
    no: 'いいえ',
    noHotel: 'ホテルなし',
    noPackage: 'パッケージなし',
    packageNotUpdated: 'パッケージを更新できませんでした',
    paidDate: '支払い{date}',
    parkingPass: 'ご注文に { parkingPassQty } の無料パーキングパスを追加しますか?',
    passwordComplexity: 'パスワードには小文字、大文字、特殊文字、数字が少なくとも一つづつ必要です。 ',
    payment_term_1: '本日全額を支払い',
    payment_term_2: '本日総額の50%を支払い、後日50％を支払う',
    payment_term_3: '本日総額の3分の1を支払い、後日3分の2の残額を支払う',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: '以下の項目の入力内容を修正してください：',
    remove: '削除',
    request_packages: '以下で情報をリクエストする',
    required: '入力必須項目です',
    roomQtyError: 'パッケージ数より多い部屋数は選択できません',
    rejectRoomQuantity: 'パッケージ数より多い部屋数は選択できません。',
    rejectCountry: 'はい',
    signUp: '登録する',
    sorryWeCouldNot: '大変申し訳ございません、ご注文を完了できませんでした。引き続き問題が発生する場合は弊社までご連絡ください。',
    submitFormError: 'リクエストを処理できませんでした。恐れ入りますがしばらく後にもう一度操作してください。',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: '本日',
    totalDueToday: 'Total Due Today',
    viewDetails: '詳細を見る»',
    yes: 'はい',
  },
  pl: {
    eventInterests: EventInterests.pl(),
    cart: Cart.pl(),
    cancellation_form: CancellationForm.pl(),
    checkout: {
      addOn: CheckoutAddOns.pl(),
      addPoints: 'Use your {programName}',
      address: CheckoutAddress.pl(),
      hotelDetails: CheckoutHotelDetails.pl(),
      docusign: CheckoutTermsAndConditions.pl(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.pl(),
      protectGroup: ProtectGroup.pl(),
      yourPoints: 'Your {programName}',
    },
    confirmation: Confirmation.pl(),
    creditCardForm: CreditCardForm.pl(),
    f1TvCodes: F1TvCodes.pl(),
    fanaticCodes: FanaticCodes.pl(),
    formPackage: FormPackage.pl(),
    h4h: H4H.pl(),
    signIn: SignIn.pl(),
    navCart: NavCart.pl(),
    orders: Orders.pl(),
    opportunity: Opportunity.pl(),
    paymentTerms: PaymentTerms.pl(),
    roomingLists: RoomingLists.pl(),
    seatingChart: SeatingChart.pl(),
    shipmentCodes: ShipmentCodes.pl(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'STORE',
      tickets: 'TICKETS',
      hospitality: 'HOSPITALITY',
      experiences: 'EXPERIENCES',
      sign_in_register: '<i class="icon-account"></i> SIGN IN <em>OR</em> REGISTER',
    },
    cookie: {
      cookie_consent: 'We use cookies to personalize content analyze our traffic and to capture information provided by our visitors to contact them about our events and services. You consent to <a href="/cookies-policy" target="_blank">our cookies</a> if you continue to use this website.',
      cookieConsentGpeFirst: 'We use cookies. Find out more',
      cookieConsentGpeLink: 'tutaj',
      cookieConsentGpeLast: '. By using the site you accept the policy.',
      continue: 'kontynuuj',
    },
    customer_service_form: {
      thank_you: 'Thank you for your submission!',
      message: 'Our customer service team will be in contact with you shortly about your inquiry.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'You must use an American Express card to complete your purchase.',
    creditCardNumber: 'numer karty kredytowej',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'kod CVV',
    dueDate: 'należne {date}',
    expirationDate: 'data ważności (mm / rr)',
    forgotYourPassword: 'Nie pamiętasz hasła?',
    form: {
      checkin_date: 'Check In Date',
      comments: 'uwagi',
      company_name: 'nazwa firmy',
      corporate: 'Czy zapytanie dotyczy usługi dla firmy?',
      confirmation_number: 'Confirmation Number',
      email: 'e-mail',
      event: 'impreza',
      first_name: 'imię',
      get_info: 'Get Info',
      how_many: 'Number of People',
      hotel_name: 'Hotel Name',
      last_name: 'nazwisko',
      event_or_company_name: 'Event Name / Company',
      marketing_email_consent: 'I agree to our <a href=\'/privacy-policy\' target=\'_blank\'>privacy policy</a> and to be contacted regarding my selected event.',
      nba_marketing_email_consent: 'I agree to our privacy policy and to be contacted regarding my selected event.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'I agree to the <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA Terms of Use</a> and <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Privacy Policy</a>.',
      nba_products_services_consent: 'Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'następny',
      password: 'hasło',
      password_confirmation: 'potwierdzenie hasła',
      phone: 'telefon',
      reseller_checkbox: 'Are you a Travel Adviser/Reseller?',
      sign_up_for_nba_info: 'Sign Up For NBA Info',
      submit: 'prześlij',
    },
    logIn: 'zaloguj się',
    logInOrSignUp: 'zaloguj się lub zarejestruj, żeby kontynuować',
    messages: {
      sensitiveData: 'Please do not enter sensitive information',
      close: 'zamknij',
      questions: 'w razie pytań lub wątpliwości prosimy o kontakt telefoniczny',
      required: 'to pole jest wymagane',
      sent_to_email: 'Twoje zapytanie zostało przekazane przedstawicielowi {brand_name}, który wkrótce się z Tobą skontaktuje. Potwierdzenie wysłano na Twój adres e-mail, {email}. ',
      thank_you: 'Thank You!',
    },
    mementoCodes: MementoCodes.pl(),
    no: 'nie',
    noHotel: 'bez hotelu',
    noPackage: 'bez pakietu',
    packageNotUpdated: 'nie udało się zmienić pakietu',
    paidDate: 'paid {date}',
    parkingPass: 'Would you like to add { parkingPassQty } complimentary parking pass to your order?',
    passwordComplexity: 'hasło musi zawierać przynajmniej jedną małą literę, jedną wielką literę, jeden znak specjalny i jedną cyfrę',
    payment_term_1: 'zapłać całość dziś',
    payment_term_2: 'zapłać 50% dzisiaj i 50% w późniejszym terminie',
    payment_term_3: 'zapłać 1/3 dzisiaj, resztę dopłacisz później w dwóch ratach',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Proszę sprawdzić następujące pola:',
    remove: 'usuń',
    request_packages: 'prośba o dodatkowe informacje',
    required: 'to pole jest wymagane',
    roomQtyError: 'liczba pokoi nie może przekraczać liczby pakietów',
    rejectRoomQuantity: 'Liczba pokoi nie może przekraczać liczby pakietów.',
    rejectCountry: 'Państwo niedozwolone.',
    signUp: 'zarejestruj się',
    sorryWeCouldNot: 'Przepraszamy, nie mogliśmy zrealizować Twojego zamówienia. Prosimy o kontakt, jeśli problem się powtórzy.',
    submitFormError: 'Niestety nie mogliśmy przekazać Twojej prośby. Proszę spróbować później ',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'dzisiaj',
    totalDueToday: 'Total Due Today',
    viewDetails: 'View Details»',
    yes: 'tak',
  },
  po: {
    eventInterests: EventInterests.po(),
    cart: Cart.po(),
    cancellation_form: CancellationForm.po(),
    checkout: {
      addOn: CheckoutAddOns.po(),
      addPoints: 'Use your {programName}',
      address: CheckoutAddress.po(),
      hotelDetails: CheckoutHotelDetails.po(),
      docusign: CheckoutTermsAndConditions.po(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.po(),
      protectGroup: ProtectGroup.po(),
      yourPoints: 'Your {programName}',
    },
    confirmation: Confirmation.po(),
    creditCardForm: CreditCardForm.po(),
    f1TvCodes: F1TvCodes.po(),
    fanaticCodes: FanaticCodes.po(),
    formPackage: FormPackage.po(),
    h4h: H4H.po(),
    signIn: SignIn.po(),
    navCart: NavCart.po(),
    orders: Orders.po(),
    opportunity: Opportunity.po(),
    paymentTerms: PaymentTerms.po(),
    roomingLists: RoomingLists.po(),
    seatingChart: SeatingChart.po(),
    shipmentCodes: ShipmentCodes.po(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: 'STORE',
      tickets: 'TICKETS',
      hospitality: 'HOSPITALITY',
      experiences: 'EXPERIENCES',
      sign_in_register: '<i class="icon-account"></i> SIGN IN <em>OR</em> REGISTER',
    },
    cookie: {
      cookie_consent: 'We use cookies to personalize content analyze our traffic and to capture information provided by our visitors to contact them about our events and services. You consent to <a href="/cookies-policy" target="_blank">our cookies</a> if you continue to use this website.',
      cookieConsentGpeFirst: 'We use cookies. Find out more',
      cookieConsentGpeLink: 'here',
      cookieConsentGpeLast: '. By using the site you accept the policy.',
      continue: 'Continue',
    },
    customer_service_form: {
      thank_you: 'Thank you for your submission!',
      message: 'Our customer service team will be in contact with you shortly about your inquiry.',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: 'You must use an American Express card to complete your purchase.',
    creditCardNumber: 'Credit Card Number',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: 'due {date}',
    expirationDate: 'Expiration Date (mm / yy)',
    forgotYourPassword: 'Forgot your password?',
    form: {
      checkin_date: 'Check In Date',
      comments: 'Comments',
      company_name: 'Company Name',
      corporate: 'Is this request for a corporate group?',
      confirmation_number: 'Confirmation Number',
      email: 'Email',
      event: 'Event',
      first_name: 'First Name',
      get_info: 'Get Info',
      how_many: 'Number of People',
      hotel_name: 'Hotel Name',
      last_name: 'Last Name',
      event_or_company_name: 'Event Name / Company',
      marketing_email_consent: 'I agree to our <a href=\'/privacy-policy\' target=\'_blank\'>privacy policy</a> and to be contacted regarding my selected event.',
      nba_marketing_email_consent: 'I agree to our privacy policy and to be contacted regarding my selected event.',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: 'I agree to the <a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA Terms of Use</a> and <a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>Privacy Policy</a>.',
      nba_products_services_consent: 'Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA.',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: 'Next',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      phone: 'Phone',
      reseller_checkbox: 'Are you a Travel Adviser/Reseller?',
      sign_up_for_nba_info: 'Sign Up For NBA Info',
      submit: 'Submit',
    },
    logIn: 'Log In',
    logInOrSignUp: 'Log In or Sign Up to Continue',
    messages: {
      sensitiveData: 'Please do not enter sensitive information',
      close: 'Close',
      questions: 'If you have immediate questions or concerns, please call',
      required: 'This field is required',
      sent_to_email: 'Your inquiry has been forwarded to a {brand_name} representative who will be in touch soon! A confirmation will be sent to your email, {email}.',
      thank_you: 'Thank You!',
    },
    mementoCodes: MementoCodes.po(),
    no: 'No',
    noHotel: 'No Hotel',
    noPackage: 'No Package',
    packageNotUpdated: 'Package was not able to update',
    paidDate: 'paid {date}',
    parkingPass: 'Would you like to add { parkingPassQty } complimentary parking pass to your order?',
    passwordComplexity: 'Password must include at least one lowercase letter, one uppercase letter, one special character and one digit',
    payment_term_1: 'Pay in Full Today',
    payment_term_2: 'Pay 50% today and 50% later',
    payment_term_3: 'Pay 1/3 today and 2 more payments later',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: 'Please review the following fields:',
    remove: 'Remove',
    request_packages: 'Request Information Below',
    required: 'This field is required',
    roomQtyError: 'The number of rooms may not exceed the number of packages',
    rejectRoomQuantity: 'The number of rooms may not exceed the number of packages.',
    rejectCountry: 'Country is not permitted.',
    signUp: 'Sign up',
    sorryWeCouldNot: 'Sorry, we could not process your order. Please contact us if the problem continues.',
    submitFormError: 'Sorry, we could not submit your request.  Please try again later',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: 'Today',
    totalDueToday: 'Total Due Today',
    viewDetails: 'View Details»',
    yes: 'Yes',
  },
  'zh-CN': {
    eventInterests: EventInterests.zhCN(),
    cart: Cart.zhCN(),
    cancellation_form: CancellationForm.zhCN(),
    checkout: {
      addOn: CheckoutAddOns.zhCN(),
      addPoints: '使用您的{programName}',
      address: CheckoutAddress.zhCN(),
      hotelDetails: CheckoutHotelDetails.zhCN(),
      docusign: CheckoutTermsAndConditions.zhCN(),
      editCart: 'Edit Cart',
      information: 'Information',
      orderSummary: 'Order Summary',
      payment: CheckoutPayment.zhCN(),
      protectGroup: ProtectGroup.zhCN(),
      yourPoints: '您的{programName}',
    },
    confirmation: Confirmation.zhCN(),
    creditCardForm: CreditCardForm.zhCN(),
    f1TvCodes: F1TvCodes.zhCN(),
    fanaticCodes: FanaticCodes.zhCN(),
    formPackage: FormPackage.zhCN(),
    h4h: H4H.zhCN(),
    signIn: SignIn.zhCN(),
    navCart: NavCart.zhCN(),
    orders: Orders.zhCN(),
    opportunity: Opportunity.zhCN(),
    paymentTerms: PaymentTerms.zhCN(),
    roomingLists: RoomingLists.zhCN(),
    seatingChart: SeatingChart.zhCN(),
    shipmentCodes: ShipmentCodes.zhCN(),
    topBar: {
      authentics: 'AUTHENTICS',
      store: '商店',
      tickets: '门票',
      hospitality: '礼遇',
      experiences: '体验',
      sign_in_register: '<i class="icon-account"></i>登录<em>或</em>注册',
    },
    cookie: {
      cookie_consent: '我们通过cookies提供个性化内容、分析流量并收集用户提供的信息，以便就有关活动和服务与其联系。如果继续使用本网站，则表示您同意<a href="/cookies-policy" target="_blank">我们的 cookies</a>。',
      cookieConsentGpeFirst: '我们使用cookies。了解更多',
      cookieConsentGpeLink: '此处',
      cookieConsentGpeLast: '使用本网站即表示您接受该政策。',
      continue: '继续',
    },
    customer_service_form: {
      thank_you: '感谢您的提交！',
      message: '我们的客户服务团队将就您的询问尽快与您联系。',
    },
    allPurchases: '*All purchases will process in %{currency}',
    amexRequired: '您必须使用美国运通卡（American Express Card）来完成支付。',
    creditCardNumber: '信用卡卡号',
    currencyDisclaimer: '*Purchases in the selected currency will process in %{currency}. Currency amount is an estimate based on current exchange rates. Since processing in %{currency}, your bank could charge you additional fees.',
    currencyDisclaimerCharge: '*Payment will be processed in currency selected. Based on current exchange rates, converted from %{currency}',
    cVV: 'CVV',
    dueDate: '截止{date}',
    expirationDate: '截止日期（月/年）',
    forgotYourPassword: '忘记密码？',
    form: {
      checkin_date: '入住日期',
      comments: '其它',
      company_name: '公司名称',
      corporate: '是购买公司团体票吗？',
      confirmation_number: '确认号码',
      email: '电子邮件',
      event: '赛事',
      first_name: '名字',
      get_info: '了解详情',
      how_many: '人数',
      hotel_name: '酒店名称',
      last_name: '姓氏',
      event_or_company_name: '活动名称 / 公司',
      marketing_email_consent: '我同意<a href=\'/privacy-policy\' target=\'/blank\'>隐私政策</a>，并同意就我选择的活动与我联系。',
      nba_marketing_email_consent: '我同意隐私政策，并同意就我选择的活动与我联系。',
      nhl_marketing_email_consent: 'Yes, I want to receive news and offers from the National Hockey League Players\' Association. <a target=\'_blank\' href=\'https://nhlpa.com/\'>https://nhlpa.com/</a>  <a class=\'separator\' target=\'_blank\' href=\'https://nhlpa.com/the-pa/what-we-do/contact-us\'>https://nhlpa.com/the-pa/what-we-do/contact-us</a>',
      nba_privacy_policy_consent: '我同意<a href=\'https://www.nba.com/news/termsofuse/\' target=\'/blank\'>NBA使用条款</a>和<a href=\'http://www.nba.com/news/privacy_policy.html\' target=\'/blank\'>隐私政策</a>。',
      nba_products_services_consent: '请在NBA网站中使用我的个人信息以便可使其向我发送有关NBA及NBA产品&新活动的信息及广告。',
      nba_info_consent: 'When you submit this form, we will share this information with the NBA subject to the NBA\'s <a href=\'https://www.nba.com/termsofuse/\' target=\'/blank\'>Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      wnba_privacy_policy_consent: 'I agree to the <a href=\'https://www.wnba.com/terms-of-use\' target=\'/blank\'>WNBA Terms of Use</a> and <a href=\'https://www.nba.com/privacy-policy\' target=\'/blank\'>NBA Privacy Policy</a>.',
      wnba_products_services_consent: 'Please use my personal information for the WNBA to send me messages and advertisements about products and initiatives of the WNBA and WNBA Experiences.',
      wnba_info_consent: 'By agreeing to the above terms, you are consenting that your personal information will be collected, stored and processed in the United States and/or the European Union on behalf of NBA Properties, Inc. Details about how to withdraw your consent are available in the <a href=\'http://www.nba.com/privacy-policy\' target=\'/blank\'>Privacy Policy</a>.',
      xgames_privacy_policy_consent: 'Please use my personal information for X Games to send me messages and advertisements about products and initiatives of X Games and X Games Experiences. I agree to the <a href=\'https://www.xgames.com/x-games-terms-of-use\' target=\'/blank\'>X Games Terms of Use</a>  and <a href=\'https://www.xgames.com/x-games-privacy-policy\' target=\'/blank\'>Privacy Policy.</a>',
      nhl_privacy_policy: 'Your contact information will be shared with NHL, Member Clubs, Business Partners and/or National Hockey League Players\' Association in accordance with your consent above. You may unsubscribe at any time. Quint is seeking your consent on behalf of the NHL, its Member Clubs and the National Hockey League Players\' Association. <br><br> Visit <a href=\'https://www.nhl.com/info/learn-more-about-privacy\' target=\'_blank\'>Learn More</a> for NHL contact info and to learn about how NHL shares information with its Member Clubs. NHL may share contact information with third-party social media platforms for advertising purposes. Click <a href=\'https://privacyportal.onetrust.com/webform/c48a335c-3316-4893-8bc6-fcacbf57fc4a/5b38eaa6-1ff7-462b-9f7e-e26b7d448b46\' target=\'_blank\'>Privacy Portal</a> to opt-out at any time.',
      next: '下一页',
      password: '密码',
      password_confirmation: '确认密码',
      phone: '手机',
      reseller_checkbox: '您是旅游顾问/经销商吗？',
      sign_up_for_nba_info: '注册获取NBA信息',
      submit: '提交',
    },
    logIn: '登录',
    logInOrSignUp: '登录或注册以继续',
    messages: {
      sensitiveData: '请勿输入敏感数据。',
      close: '关闭',
      questions: '如果您有紧急问题或顾虑，请拨打电话联系我们',
      required: '必填项',
      sent_to_email: '我们已将您的咨询转发{brand_name}代表，他将尽快与您联系！确认邮件已发送至您的电子邮箱{email}。',
      thank_you: '谢谢！',
    },
    mementoCodes: MementoCodes.zhCN(),
    no: '否',
    noHotel: '无酒店',
    noPackage: '无套票',
    packageNotUpdated: '无法更新套票',
    paidDate: '支付日期{date}',
    parkingPass: '您要将{ parkingPassQty }免费停车卡加入到您的订单中吗？',
    passwordComplexity: '密码必须至少包含一个小写字母、一个大写字母、一个特殊字符和一个数字',
    payment_term_1: '立即全额支付',
    payment_term_2: '立即支付50％，之后支付50％',
    payment_term_3: '立即支付1/3款项，剩下的分两次支付',
    paymentNum: 'Payment {paymentNum}:',
    pleaseReview: '请查看以下字段：',
    remove: '移除',
    request_packages: '在下方请求更多信息',
    required: '必填项',
    roomQtyError: '房间数不得超过套票数',
    rejectRoomQuantity: '房间数不得超过套票数。',
    rejectCountry: '无效的国家',
    signUp: '注册',
    sorryWeCouldNot: '抱歉，我们无法处理您的订单。如果问题持续出现请联系我们。',
    submitFormError: '抱歉，我们无法提交您的请求。请稍后再试',
    taxDisclaimer: 'Amount includes all applicable local taxes',
    today: '今天',
    totalDueToday: 'Total Due Today',
    viewDetails: '查看详情»',
    yes: '是',
  },
}
