/* global locale */

const csrfToken = () => {
  let tok = document.getElementsByTagName('meta')['csrf-token']
  if (tok) { // csrf-token is null in testing
    return tok.getAttribute('content')
  } else {
    return null
  }
}

export default {
  fetchWithLocale(path, method, body) {
    return this.fetchWithoutLocale(`/${locale}${path}`, method, body).
      then(res => { return res })
  },

  fetchWithoutLocale(path, method, body) {
    return fetch(path, {
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken()
      },
      method: method
    }).then(response => {
      return response.json().then(body => { return body })
    }).catch(() => {
      return {}
    })
  }
}
