/* eslint-disable no-undef */
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? module.exports = factory(require('lodash.clonedeep')) :
    typeof define === 'function' && define.amd
      ? define(['lodash.clonedeep'], factory) :
      (global['vuex-turbolinks'] = factory(global.cloneDeep))
}(this, (function (cloneDeep) { 'use strict'

  // eslint-disable-next-line no-prototype-builtins
  cloneDeep = cloneDeep && cloneDeep.hasOwnProperty('default') ? cloneDeep['default'] : cloneDeep

  var plugin = function plugin(store) {
    var storeState = cloneDeep(store.state)

    document.addEventListener('turbo:visit', function () {
      store.replaceState(cloneDeep(storeState))
    })
  }

  return plugin

})))
