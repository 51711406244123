export default {

  en() {
    return {
      giftNumber: 'Gift {number}',
      instructions: 'Please click the below link(s) to choose your gift:'
    }
  },
  ar() {
    return {
      giftNumber: '{number} الهديّة',
      instructions: 'يطبّق تسعير الحجز المُبكّر حتّى 4 سبتمبر',
    }
  },
  de() {
    return {
      giftNumber: 'Geschenk {number}',
      instructions: 'Bitte klicken Sie auf die nachstehenden Links, um Ihr Geschenk auszuwählen:',
    }
  },
  enGB() {
    return {
      giftNumber: 'Gift {number}',
      instructions: 'Please click the below link(s) to choose your gift:',
    }
  },
  esMX() {
    return {
      giftNumber: 'Regalo {number}',
      instructions: 'Por favor haga clic en el enlace(s) a continuación para elegir su regalo:',
    }
  },
  es() {
    return {
      giftNumber: 'Regalo {number}',
      instructions: 'Por favor, haz clic en el/los siguiente/s enlace/s para elegir tu regalo:',
    }
  },
  fr() {
    return {
      giftNumber: 'Cadeau {number}',
      instructions: 'Cliquez sur le(s) lien(s) ci-dessous pour choisir votre cadeau :',
    }
  },
  it() {
    return {
      giftNumber: 'Omaggio {number}',
      instructions: 'Clicca sul collegamento sottostante per scegliere l\'omaggio che desideri ricevere:',
    }
  },
  ja() {
    return {
      giftNumber: 'ギフト {number}',
      instructions: '以下のリンクをクリックして、ギフトを選択してください：',
    }
  },
  pl() {
    return {
      giftNumber: 'upominek {number}',
      instructions: ' Proszę kliknąć w poniższy link, żeby wybrać upominek dla siebie:',
    }
  },
  po() {
    return {
      giftNumber: 'Gift {number}',
      instructions: 'Please click the below link(s) to choose your gift:',
    }
  },
  zhCN() {
    return {
      giftNumber: '礼物{number}',
      instructions: '请点击以下链接选择您的礼品：',
    }
  },
}
