export default {

  en() {
    return {
      sortBy: 'Sort By',
      lowToHigh: 'Price: Low to High',
      highToLow: 'Price: High to Low',
      bestSelling: 'Best Selling',
    }
  },
  ar() {
    return {
      sortBy: 'صنّف حسب',
      lowToHigh: 'Price: Low to High',
      highToLow: 'الثمن: من الأعلى إلى الأقلّ',
      bestSelling: 'الأكثر مبيعًا',
    }
  },
  de() {
    return {
      sortBy: 'Sortieren nach',
      lowToHigh: 'Preis: Niedrig nach Hoch',
      highToLow: 'Preis: Hoch nach Niedrig',
      bestSelling: 'Meistverkauftes Produkt',
    }
  },
  enGB() {
    return {
      sortBy: 'Sort By',
      lowToHigh: 'Price: Low to High',
      highToLow: 'Price: High to Low',
      bestSelling: 'Best Selling',
    }
  },
  esMX() {
    return {
      sortBy: 'Ordenar Por',
      lowToHigh: 'Precio: Bajo a Alto',
      highToLow: 'Precio: Alto a Bajo',
      bestSelling: 'Más Vendido',
    }
  },
  es() {
    return {
      sortBy: 'Ordenar Por',
      lowToHigh: 'Precio: de Menor a Mayor',
      highToLow: 'Precio: de Mayor a Menor',
      bestSelling: 'Más Vendidos',
    }
  },
  fr() {
    return {
      sortBy: 'Trier par',
      lowToHigh: 'Prix : ordre croissant',
      highToLow: 'Prix : ordre décroissant',
      bestSelling: 'Meilleures ventes',
    }
  },
  it() {
    return {
      sortBy: 'Ordina per',
      lowToHigh: 'Prezzo: Più economici',
      highToLow: 'Prezzo: Più cari',
      bestSelling: 'Più venduti',
    }
  },
  ja() {
    return {
      sortBy: '並び替え',
      lowToHigh: '価格：低額から高額',
      highToLow: '価格：高額から低額',
      bestSelling: 'ベストセラー',
    }
  },
  pl() {
    return {
      sortBy: 'Sort By',
      lowToHigh: 'Price: Low to High',
      highToLow: 'Price: High to Low',
      bestSelling: 'Best Selling',
    }
  },
  po() {
    return {
      sortBy: 'Sort By',
      lowToHigh: 'Price: Low to High',
      highToLow: 'Price: High to Low',
      bestSelling: 'Best Selling',
    }
  },
  zhCN() {
    return {
      sortBy: '检索结果排序',
      lowToHigh: '价格：从低到高',
      highToLow: '价格：从高到低',
      bestSelling: '热销套餐',
    }
  },
}
