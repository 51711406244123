export default {
  en() {
    return {
      codeHeader: 'Here is your $50 USD code for the F1 Store:',
      enterCode: 'Enter the gift code at checkout under the \'Payment & Discounts\' section',
      f1StoreLink: 'Go to the <a href={link} target="_blank" rel="noopener noreferrer">F1 Store</a>',
      instructionsHeader: 'To redeem your gift, please follow these instructions:',
      saveOffPurchase: 'Save $50 USD off your purchase!',
      selectGift: 'Select your favorite team or driver merchandise, or choose from a range of F1 themed gifts and accessories',
      yourGiftCode: 'Your {eventName} Gift'
    }
  },
  ar() {
    return {
      codeHeader: 'مقابل 50 دولار F1 إليك رمز متجر',
      enterCode: '.\'أدخل رمز الهديّة عند تسجيل الخروج ضمن قسم \'الدفع والخصومات',
      f1StoreLink: 'اذْهب إلى <a href={link} target="_blank" rel="noopener noreferrer">F1 متجر</a>',
      instructionsHeader: ':للحصول على هديّتك، يُرْجى اتّباع هذه الخطوات',
      saveOffPurchase: '!وفّر 50 دولارًا أمريكيًا على ثمن مشترياتك',
      selectGift: 'أو اختر من بين مجموعة من الهدايا والاكسسوارات ذات محور معيّن  F1اختر بضائع فريقك أو سائقك المفضّل للـ',
      yourGiftCode: 'هديّة {eventName} الخاصّة بك',
    }
  },
  de() {
    return {
      codeHeader: 'Hier ist Ihr USD 50 Code für den F1 Store:',
      enterCode: 'Geben Sie den Geschenkcode an der Kasse im Abschnitt \'Zahlung & Ermäßigungen\' ein.',
      f1StoreLink: 'Besuchen Sie den <a href={link} target="_blank" rel="noopener noreferrer">F1 Store</a>',
      instructionsHeader: 'Bitte folgen Sie diesen Anweisungen, um Ihr Geschenk einzulösen:',
      saveOffPurchase: 'Sparen Sie USD 50 bei Ihrem Einkauf!',
      selectGift: 'Wählen Sie Merchandising-Artikel von Ihrem Lieblingsteam oder -fahrer oder suchen Sie sich aus einer Reihe von F1 Geschenken und Accessoires Ihren Favoriten aus.',
      yourGiftCode: 'Ihr {eventName} Geschenk',
    }
  },
  enGB() {
    return {
      codeHeader: 'Here is your $50 USD code for the F1 Store:',
      enterCode: 'Enter the gift code at checkout under the \'Payment & Discounts\' section',
      f1StoreLink: 'Go to the <a href={link} target="_blank" rel="noopener noreferrer">F1 Store</a>',
      instructionsHeader: 'To redeem your gift, please follow these instructions:',
      saveOffPurchase: 'Save $50 USD off your purchase!',
      selectGift: 'Select your favorite team or driver merchandise, or choose from a range of F1 themed gifts and accessories',
      yourGiftCode: 'Your {eventName} Gift',
    }
  },
  esMX() {
    return {
      codeHeader: 'Aquí está su código de regalo de $50 USD para la Tienda F1:',
      enterCode: 'Ingrese el código de regalo al finalizar la compra en la sección\'Pagos y descuentos\'',
      f1StoreLink: 'Ir a la <a href={link} target="_blank" rel="noopener noreferrer">Tienda F1</a>',
      instructionsHeader: 'Para obtener su regalo, favor de seguir estas instrucciones:',
      saveOffPurchase: '¡Ahorre $50 USD en su compra!',
      selectGift: 'Elija mercancía de su equipo o piloto favorito, o elija entre una amplia gama de regalos y accesorios de F1',
      yourGiftCode: 'Su Regalo {eventName}',
    }
  },
  es() {
    return {
      codeHeader: 'Aquí tienes tu código de $50 USD para la Tienda F1:',
      enterCode: 'Introduce el código de regalo en el momento de la compra en la sección \'Pago y Descuentos\'',
      f1StoreLink: 'Ir a la <a href={link} target="_blank" rel="noopener noreferrer">Tienda F1</a>',
      instructionsHeader: 'Para canjear tu regalo, sigue estas instrucciones:',
      saveOffPurchase: '¡Ahorra $50 USD en tu compra!',
      selectGift: 'Selecciona los artículos de tu equipo o piloto favorito, o elige entre una gama de regalos y accesorios con temática de F1',
      yourGiftCode: 'Tu {eventName} Regalo',
    }
  },
  fr() {
    return {
      codeHeader: 'Voici votre code de réduction de 50 USD sur la boutique F1 :',
      enterCode: 'Entrez le code cadeau lors du paiement sous la section \'Paiement & remises\'',
      f1StoreLink: 'Rendez-vous sur <a href={link} target="_blank" rel="noopener noreferrer">la boutique F1</a>.',
      instructionsHeader: 'Pour obtenir votre cadeau, veuillez suivre les instructions suivantes :',
      saveOffPurchase: 'Économisez 50 $ US sur votre achat !',
      selectGift: 'Choisissez un article de votre écurie ou de votre pilote favori, ou choisissez parmi les cadeaux et les accessoires au thème de la F1.',
      yourGiftCode: 'Votre cadeau pour le {eventName}',
    }
  },
  it() {
    return {
      codeHeader: 'Ecco il tuo codice sconto da 50$ valido per l\'F1 Store:',
      enterCode: 'Inserisci il codice omaggio alla cassa nella sezione "Pagamento e sconti"',
      f1StoreLink: 'Vai al <a href={link} target="_blank" rel="noopener noreferrer">F1 Store</a>',
      instructionsHeader: 'Se desideri riscattare il tuo buono, ti invitiamo a seguire queste istruzioni:',
      saveOffPurchase: 'Risparmia 50 $ sull\'acquisto!',
      selectGift: 'Scegli il merchandising della tua scuderia o del tuo pilota preferito oppure un qualsiasi altro gadget tra la vasta gamma di regali e accessori legati alla F1',
      yourGiftCode: 'Il tuo omaggio per il {eventName}',
    }
  },
  ja() {
    return {
      codeHeader: 'F1ストア用の50米ドルコードを以下に表示します。',
      enterCode: 'チェックアウト時に「支払いと割引」部分の下にギフトコードをご入力ください',
      f1StoreLink: '<a href={link} target="_blank" rel="noopener noreferrer">F1 ストア</a>へ行く',
      instructionsHeader: 'ギフトの引き換えには、以下の指示に従がってください：',
      saveOffPurchase: '購入から 50 米ドル節約できます!',
      selectGift: 'お気に入りのチームまたはドライバーの商品を選ぶか、F1をテーマにしたギフトやアクセサリをお選びください',
      yourGiftCode: 'お客様の{eventName} ギフト',
    }
  },
  pl() {
    return {
      codeHeader: 'kod na rabat w wysokości 50 USD na zakupy w sklepie F1:',
      enterCode: 'Enter the gift code at checkout under the \'Payment & Discounts\' section',
      f1StoreLink: 'przejdź do <a href={link} target="_blank" rel="noopener noreferrer">sklepu F1</a> ',
      instructionsHeader: 'Co zrobić, żeby skorzystać z rabatu:',
      saveOffPurchase: 'Save $50 USD off your purchase!',
      selectGift: 'wybierz produkty swojego ulubionego zespołu lub kierowcy bądź wybierz pozycje spośród upominków i akcesoriów F1',
      yourGiftCode: 'Twój upominek na {eventName}',
    }
  },
  po() {
    return {
      codeHeader: 'Here is your $50 USD code for the F1 Store:',
      enterCode: 'Enter the gift code at checkout under the \'Payment & Discounts\' section',
      f1StoreLink: 'Go to the <a href={link} target="_blank" rel="noopener noreferrer">F1 Store</a>',
      instructionsHeader: 'To redeem your gift, please follow these instructions:',
      saveOffPurchase: 'Save $50 USD off your purchase!',
      selectGift: 'Select your favorite team or driver merchandise, or choose from a range of F1 themed gifts and accessories',
      yourGiftCode: 'Your {eventName} Gift',
    }
  },
  zhCN() {
    return {
      codeHeader: '这是您的F1商店50美元礼品代码：',
      enterCode: '结账时在‘付款和折扣’下面输入礼品代码',
      f1StoreLink: '跳转至<a href={link} target="_blank" rel="noopener noreferrer">F1商店</a>',
      instructionsHeader: '请按照以下说明兑换礼品：',
      saveOffPurchase: '购买可省50美元！',
      selectGift: '选择您喜欢的车队或车手商品，或从一系列F1主题礼品和配件中选择您中意的',
      yourGiftCode: '您的{eventName}礼品',
    }
  },
}
