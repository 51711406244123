export default {
  en() {
    return {
      login: 'Log In',
      alreadyMember: 'Already a member? Log in with your username and password.',
      returning: 'Returning Customers',
      newCustomer: 'New Customers',
      createAccount: 'Create An Account',
      createDesc: 'Create an account to manage your payments, get order updates and more!',
      createAccountShort: 'Create Account',
      confirmPassword: 'Confirm Password',
    }
  },
  ar() {
    return {
      login: 'تسجيل الدخول',
      alreadyMember: 'هل أنت عضو؟ سجّل دخولك باستخدام اسم المستخدم وكلمة المرور الخاصّيْن بك',
      returning: 'الحرفاء الّذين يحجزون من جديد',
      newCustomer: 'الحرفاء الجُدد',
      createAccount: 'أنشأ حسابًا',
      createDesc: 'هل أنت عضو؟ سجّل دخولك باستخدام اسم المستخدم وكلمة المرور الخاصّيْن بك',
      createAccountShort: 'أنشئ حسابًا',
      confirmPassword: 'أكّد كلمة العبور',
    }
  },
  de() {
    return {
      login: 'Anmelden',
      alreadyMember: 'Schon Mitglied? Melden Sie sich mit Ihrem Benutzernamen und Passwort an.',
      returning: 'Bestehende Kunden',
      newCustomer: 'Neue Kunden',
      createAccount: 'Erstellen Sie ein Konto',
      createDesc: 'Erstellen Sie ein Konto, um Ihre Zahlungen zu verwalten, Aktualisierungen Ihrer Bestellungen zu erhalten und vieles mehr!',
      createAccountShort: 'Konto erstellen',
      confirmPassword: 'Passwort bestätigen',
    }
  },
  enGB() {
    return {
      login: 'Log In',
      alreadyMember: 'Already a member? Log in with your username and password.',
      returning: 'Returning Customers',
      newCustomer: 'New Customers',
      createAccount: 'Create An Account',
      createDesc: 'Create an account to manage your payments, get order updates and more!',
      createAccountShort: 'Create Account',
      confirmPassword: 'Confirm Password',
    }
  },
  esMX() {
    return {
      login: 'Entrar',
      alreadyMember: '¿Ya es miembro? Inicie sesión con su nombre de usuario y contraseña.',
      returning: 'Clientes que ya han comprado antes',
      newCustomer: 'Nuevos Clientes',
      createAccount: 'Crear una Cuenta',
      createDesc: 'Cree una cuenta para administrar sus pagos, obtener información de sus pedidos ¡y más!',
      createAccountShort: 'Crear Cuenta',
      confirmPassword: 'Confirmar contraseña',
    }
  },
  es() {
    return {
      login: 'Iniciar Sesión',
      alreadyMember: '¿Ya eres miembro? Inicia sesión con tu nombre de usuario y contraseña.',
      returning: 'Clientes que Regresan',
      newCustomer: 'Nuevos Clientes',
      createAccount: 'Crear Una Cuenta',
      createDesc: '¡Crea una cuenta para gestionar tus pagos, recibir actualizaciones de tus pedidos y mucho más!',
      createAccountShort: 'Crear Cuenta',
      confirmPassword: 'Confirmar Contraseña',
    }
  },
  fr() {
    return {
      login: 'Se connecter',
      alreadyMember: 'Déjà membre ? Connectez-vous avec votre nom d\'utilisateur et votre mot de passe.',
      returning: 'Clients déjà inscrits',
      newCustomer: 'Nouveaux clients',
      createAccount: 'Créer un compte',
      createDesc: 'Créez un compte pour gérer vos paiements, recevoir des nouvelles de vos commandes, et encore davantage !',
      createAccountShort: 'Créer un compte',
      confirmPassword: 'Confirmer le mot de passe',
    }
  },
  it() {
    return {
      login: 'Accedi',
      alreadyMember: 'Sei già un nostro cliente? Accedi con nome utente e password.',
      returning: 'Clienti registrati',
      newCustomer: 'Nuovi clienti',
      createAccount: 'Crea un account',
      createDesc: 'Crea un account se desideri gestire i tuoi pagamenti, ricevere aggiornamenti sugli ordini e tanto altro ancora!',
      createAccountShort: 'Crea un account',
      confirmPassword: 'Conferma la password',
    }
  },
  ja() {
    return {
      login: 'ログイン',
      alreadyMember: '既にメンバーの方は、ユーザー名とパスワードでログインしてください。',
      returning: 'リピーター',
      newCustomer: '新規のお客様',
      createAccount: 'アカウントを作成',
      createDesc: 'アカウントを作成すると、支払いの管理や注文の更新などができます。',
      createAccountShort: 'アカウントを作成',
      confirmPassword: 'パスワードの確認',
    }
  },
  pl() {
    return {
      login: 'zaloguj się',
      alreadyMember: 'Already a member? Log in with your username and password.',
      returning: 'Returning Customers',
      newCustomer: 'New Customers',
      createAccount: 'Create An Account',
      createDesc: 'Create an account to manage your payments, get order updates and more!',
      createAccountShort: 'Create Account',
      confirmPassword: 'Confirm Password',
    }
  },
  po() {
    return {
      login: 'Log In',
      alreadyMember: 'Already a member? Log in with your username and password.',
      returning: 'Returning Customers',
      newCustomer: 'New Customers',
      createAccount: 'Create An Account',
      createDesc: 'Create an account to manage your payments, get order updates and more!',
      createAccountShort: 'Create Account',
      confirmPassword: 'Confirm Password',
    }
  },
  zhCN() {
    return {
      login: '登录',
      alreadyMember: '已经是会员？通过用户名和密码登录。',
      returning: '回头客',
      newCustomer: '新客户',
      createAccount: '创建帐户',
      createDesc: '创建一个帐户来，用于管理付款，并获取订单更新信息等！',
      createAccountShort: '创建账户',
      confirmPassword: '确认密码',
    }
  },
}
