import FetchCall from '../../../shared/local_fetch_call'
const namespaced = true

const state = {
  isGuest: '',
  emailAddress: '',
  section: 'none',
  number: 1,
  // paymentFields: {},
  paymentAttempted: false,
  hotelInCart: false,
  topDiv: null,
  applyCredit: true,
  creditApplied: 0,
  protectGroupPremium: 0,
  protectGroupCost: 0,
  protectGroupCostChargeCurrency: 0,
  rewardPoints: '$0.00',
  protectGroupPremiumDecorated: '$',
  protectGroupCostDecorated: '$',
  protectGroupDecisionMade:false,
  privacyConsent: false,
  reservations: [],
  roomInformation: [],
  sameAsBilling: [],
  billing: {
    address1: '',
    address2: '',
    city: '',
    companyName: '',
    corporate: false,
    country: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    purchaseOrderNumber: '',
    state: '',
    zipCode: ''
  },
  stripeFormLoading: false,
  addressErrors: {
    country: false,
    state: false,
    zip_code: false
  }
}

const mutations = {

  updateStripeFormLoading(state, bool) {
    state.stripeFormLoading = bool
  },

  updateAddressErrors(state, errors){
    state.addressErrors = errors
  },

  setRoomInfo(state, item){

    for (let i = 0; i < item.room_quantity; i++) {
      let index = state.reservations.map(x => x.order_item_id).indexOf(item.id)
      let reservation = state.reservations.splice(index, 1)[0]

      let inState = state.roomInformation.find(room => room.id == reservation.reservation_id)
      let checkInDate = item.hotel_reservations[0] ? item.hotel_reservations[0].hotel_check_in_date : null
      let checkOutDate = item.hotel_reservations[0] ? item.hotel_reservations[0].hotel_check_out_date : null
      if(!inState) {
        state.roomInformation.push({
          id: reservation.reservation_id,
          orderItemId: item.id,
          numberOfAdults: 2,
          name: reservation.name,
          hotelName: item.hotel_display_name,
          checkIn: checkInDate,
          checkOut: checkOutDate
        })
        state.sameAsBilling.push(false)
      }
    }
  },
  updateRewardPoints(state, amount){
    state.rewardPoints = amount
  },
  setReservations(state,reservations){
    state.reservations = reservations
  },
  // updatePaymentFields(state, newFields) {
  // const pf = state.paymentFields
  // state.paymentFields = Object.assign({}, pf, newFields)
  // },
  updateReservationName(state, name){
    state.reservationName = name
  },
  customerMadeProtectGroupDecision(state){
    state.protectGroupDecisionMade = true
  },
  updateHotelInCart(state, hotelInCart) {
    state.hotelInCart = hotelInCart
  },
  toggleCreditApplication(state){
    state.applyCredit = !state.applyCredit
  },
  updateCreditAmount(state, amount){
    state.creditApplied = amount
  },
  turnOffCredit(state){
    state.applyCredit = false
  },
  updateSection(state, section) {
    if (section == 'protect-group'){
      let protectGroupForm = document.getElementsByClassName('middle-checkout-column')[0]
      protectGroupForm.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      if (state.topDiv) {
        state.topDiv.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      state.section = section
    }
  },
  updateSectionNumber(state, numberOfAddOns) {
    if (numberOfAddOns > 0) {
      state.number = 0
    } else {
      state.number = 1
    }
  },

  setTopDiv(state, div) {
    state.topDiv = div
  },
  updateFirstName(state, firstName) {
    state.billing.firstName = firstName
  },
  updateAddress1(state, address1) {
    state.billing.address1 = address1
  },
  updateAddress2(state, address2) {
    state.billing.address2 = address2
  },
  updateCity(state, city) {
    state.billing.city = city
  },
  updateCompanyName(state, name) {
    state.billing.companyName = name
  },
  updateCorporate(state, value) {
    state.billing.corporate = value
  },
  updateCountry(state, country) {
    state.billing.country = country
  },
  updateEmailAddress(state, email) {
    state.emailAddress = email
  },
  updateLastName(state, lastName) {
    state.billing.lastName = lastName
  },
  updatePhoneNumber(state, phoneNumber) {
    state.billing.phoneNumber = phoneNumber
  },
  updatePurchaseOrderNumber(state, pon) {
    state.billing.purchaseOrderNumber = pon
  },
  updateState(state, stateName) {
    state.billing.state = stateName
  },
  updateZipCode(state, zipCode) {
    state.billing.zipCode = zipCode
  },
  updateIsGuest(state, bool) {
    state.isGuest = bool
  },
  updatePaymentAttempted(state, bool) {
    state.paymentAttempted = bool
  }
}

const actions = {
  async updateSection({ commit, state }, section) {
    let replacedSection = state.section
    commit('updateSection', section)

    if (replacedSection == 'hotel' && state.roomInformation.length > 0) {
      updateHotelReservationInfo(state.roomInformation)
    }
  },
  async updateReservationInfo({ state }) {
    if (state.roomInformation.length > 0) {
      updateHotelReservationInfo(state.roomInformation)
    }
  },
}

const getters = {

}

const updateHotelReservationInfo = async (roomInformation) => {
  roomInformation.forEach(async info => {
    let body = JSON.stringify({
      hotel_reservations: {
        name: info.name,
        number_of_adults: info.numberOfAdults
      }
    })
    await FetchCall.fetchWithoutLocale(
      `/api/checkout_hotel_reservations/${info.id}`,
      'PUT',
      body
    )
  })
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
