export default {
  en() {
    return {
      address1: '*Street Address Line 1',
      address2: 'Street Address Line 2',
      billingAddress: 'Billing Address',
      billingAndShipping: 'Billing & Shipping Address',
      blockedEmail: 'Your email address has been blocked. You must have a valid email to order. Please contact us at {phoneNumber} for assistance.',
      city: '*City',
      companyName: 'Company Name',
      contactInfo: 'Contact Information',
      corporate: 'Is your purchase for a corporate group?',
      country: '*Country',
      disclaimer: 'If your billing address is a P.O. Box, your package will need to be picked up on site.',
      firstName: '*First Name',
      lastName: '*Last Name',
      phoneNumber: '*Phone Number',
      purchaseOrderNumber: 'Purchase Order Number',
      state: '*State',
      zipCode: '*Zip Code / Postal Code',
    }
  },
  ar() {
    return {
      address1: '1العنوان الشخصيّ الخطّ ',
      address2: ' عنوان الشارع السطر 2',
      billingAddress: 'عنوان إرسال الفواتير',
      billingAndShipping: 'عنوان إرسال الفواتير والشحن',
      blockedEmail: '.تمّ حظر بريدك الإلكترونيّ. يجب أن يكون لديك بريد إلكترونيّ صالح لتقديم طلبك. يُرْجى الاتّصال بنا على الرقم {رقم الهاتف} للحصول على الدعم والمساعدة',
      city: 'المدينة*',
      companyName: 'اسم الشركة',
      contactInfo: 'Contact Information',
      corporate: 'هل عمليّة شرائك تابعة لمجموعة شركات؟',
      country: 'الاسم*',
      disclaimer: 'إذا كان عنوان إرسال الفواتير الخاص بك هو صندوق بريديّ بمركز بريد،  يجب أن يتم استلام الطرد الخاص بك من الموقع',
      firstName: 'اللقب*',
      lastName: 'اللقب*',
      phoneNumber: 'الولاية*',
      purchaseOrderNumber: 'رقم طلب الشراء',
      state: 'الولاية*',
      zipCode: 'الترقيم البريديّ',
    }
  },
  de() {
    return {
      address1: '*Adresszeile 1',
      address2: 'Adresszusatz',
      billingAddress: 'Rechnungsadresse',
      billingAndShipping: 'Rechnungs- und Lieferadresse',
      blockedEmail: 'Ihre E-Mail Adresse wurde gesperrt. Sie benötigen für Ihre Bestellung eine gültige E-Mail-Adresse. Bitte kontaktieren Sie uns unter {phoneNumber} für weitere Unterstützung.',
      city: '*Ort',
      companyName: 'Firmenname',
      contactInfo: 'Contact Information',
      corporate: 'Machen Sie Ihren Einkauf für ein Unternehmen?',
      country: '*Land',
      disclaimer: 'Wenn Ihre Rechnungsadresse eine Postfachadresse ist, muss Ihr Paket vor Ort abgeholt werden.',
      firstName: '*Vorname',
      lastName: '*Nachname',
      phoneNumber: '*Telefonnummer',
      purchaseOrderNumber: 'Bestellnummer',
      state: '*Bundesland',
      zipCode: '*Postleitzahl',
    }
  },
  enGB() {
    return {
      address1: '*Street Address Line 1',
      address2: 'Street Address Line 2',
      billingAddress: 'Billing Address',
      billingAndShipping: 'Billing & Shipping Address',
      blockedEmail: 'Your email address has been blocked. You must have a valid email to order. Please contact us at {phoneNumber} for assistance.',
      city: '*City',
      companyName: 'Company Name',
      contactInfo: 'Contact Information',
      corporate: 'Is your purchase for a corporate group?',
      country: '*Country',
      disclaimer: 'If your billing address is a P.O. Box, your package will need to be picked up on site.',
      firstName: '*First Name',
      lastName: '*Last Name',
      phoneNumber: '*Phone Number',
      purchaseOrderNumber: 'Purchase Order Number',
      state: '*State',
      zipCode: '*Zip Code / Postal Code',
    }
  },
  esMX() {
    return {
      address1: '*Dirección Línea 1',
      address2: 'Dirección Línea 2',
      billingAddress: 'Dirección de facturación',
      billingAndShipping: 'Dirección de Facturación y Envío',
      blockedEmail: 'Su dirección de correo electrónico ha sido bloqueada. Debe tener un correo electrónico válido para ordenar. Por favor comuníquese con nosotros al {phoneNumber} para obtener ayuda.',
      city: '*Ciudad',
      companyName: 'Nombre de la Empresa',
      contactInfo: 'Contact Information',
      corporate: '¿Su compra es para un grupo corporativo?',
      country: '*País',
      disclaimer: 'Si su dirección de facturación es un apartado postal, tendrá que recoger su paquete en el sitio.',
      firstName: '*Primer nombre',
      lastName: '*Apellido',
      phoneNumber: '*Número de teléfono',
      purchaseOrderNumber: 'Número de Orden de Compra',
      state: '*Estado',
      zipCode: '*Código postal',
    }
  },
  es() {
    return {
      address1: '*Dirección de la Calle Línea 1',
      address2: 'Dirección de la Calle Línea 2',
      billingAddress: 'Dirección de Facturación',
      billingAndShipping: 'Dirección de Facturación y Envío',
      blockedEmail: 'Tu dirección de correo electrónico ha sido bloqueada. Debes tener un correo electrónico válido para realizar un pedido. Ponte en contacto con nosotros en {phoneNumber} para obtener ayuda.',
      city: '*Ciudad',
      companyName: 'Nombre de la Empresa',
      contactInfo: 'Contact Information',
      corporate: '¿Tu compra es para un grupo empresarial?',
      country: '*País',
      disclaimer: 'Si tu dirección de facturación es un Apartado de Correos, tu paquete tendrá que ser recogido en el lugar.',
      firstName: '*Nombre',
      lastName: '*Apellido',
      phoneNumber: '*Número de Teléfono',
      purchaseOrderNumber: 'Número de Pedido',
      state: '*Estado',
      zipCode: '*Código Postal',
    }
  },
  fr() {
    return {
      address1: '*Adresse ligne 1',
      address2: 'Adresse postale, Ligne 2',
      billingAddress: 'Adresse de facturation',
      billingAndShipping: 'Adresse de facturation et de livraison',
      blockedEmail: 'Votre adresse courriel a été bloquée. Vous devez disposer d\'une adresse courriel pour passer une commande. Veuillez nous contacter au {phoneNumber} pour une assistance.',
      city: '*Ville',
      companyName: 'Nom de la société',
      contactInfo: 'Contact Information',
      corporate: 'Votre achat est-il à destination d\'un groupe d\'entreprise ?',
      country: '*Pays',
      disclaimer: 'Si votre adresse de facturation est une boîte postale, votre forfait devra être récupéré sur place.',
      firstName: '*Prénom',
      lastName: '*Nom de famille',
      phoneNumber: '*Numéro de téléphone',
      purchaseOrderNumber: 'Numéro de commande',
      state: '*État',
      zipCode: '*Code postal',
    }
  },
  it() {
    return {
      address1: '*Indirizzo',
      address2: 'Indirizzo (aggiuntivo)',
      billingAddress: 'Indirizzo di fatturazione',
      billingAndShipping: 'Indirizzi di fatturazione e di spedizione',
      blockedEmail: 'L\'indirizzo email è stato bloccato. Devi avere un indirizzo email valido per ordinare. Ti preghiamo di contattarci al numero {phoneNumber} per ricevere assistenza.',
      city: '*Città',
      companyName: 'Nome dell\'azienda',
      contactInfo: 'Contact Information',
      corporate: 'Il tuo acquisto è destinato a un gruppo aziendale?',
      country: '*Nazione',
      disclaimer: 'In caso il tuo indirizzo di fatturazione corrispondesse a una casella postale, il tuo pacchetto dovrà essere ritirato sul posto.',
      firstName: '*Nome',
      lastName: '*Cognome',
      phoneNumber: '*Numero di telefono',
      purchaseOrderNumber: 'Numero dell\'ordine d\'acquisto',
      state: '*Provincia',
      zipCode: '*CAP',
    }
  },
  ja() {
    return {
      address1: '*所在地住所 1行目',
      address2: '住所2',
      billingAddress: '請求先住所',
      billingAndShipping: 'ご請求先および配送先住所',
      blockedEmail: 'あなたのメールアドレスはブロックされています。注文するには有効な電子メールが必要です。サポートが必要な場合は {phoneNumber} までご連絡ください。',
      city: '*市町村',
      companyName: '企業名',
      contactInfo: 'Contact Information',
      corporate: 'お客様のご注文は法人利用でのご購入ですか？',
      country: '*国',
      disclaimer: '請求先住所に私書箱をご指定の場合、チケットパッケージは現地でのお受け取りとなります。',
      firstName: '*名前',
      lastName: '*苗字',
      phoneNumber: '*電話番号',
      purchaseOrderNumber: '発注番号',
      state: '*州',
      zipCode: '*郵便番号',
    }
  },
  pl() {
    return {
      address1: '*Street Address Line 1',
      address2: 'ulica i numer domu cd.',
      billingAddress: 'Billing Address',
      billingAndShipping: 'adres rozliczeniowy i do doręczeń',
      blockedEmail: 'Twój adres e-mail został zablokowany. Aby złożyć zamówienie, musisz mieć ważny adres e-mail. Skontaktuj się z nami pod numerem {phoneNumber}, aby uzyskać pomoc.',
      city: '*City',
      companyName: 'nazwa firmy',
      contactInfo: 'Contact Information',
      corporate: 'Czy to zakup dla firmy?',
      country: '*Country',
      disclaimer: 'Jeśli w adresie rozliczeniowym podana jest skrytka pocztowa, pakiet należy odebrać osobiście na miejscu. ',
      firstName: '*First Name',
      lastName: '*Last Name',
      phoneNumber: '*Phone Number',
      purchaseOrderNumber: 'numer zamówienia',
      state: '*State',
      zipCode: '*Zip Code / Postal Code',
    }
  },
  po() {
    return {
      address1: '*Street Address Line 1',
      address2: 'Street Address Line 2',
      billingAddress: 'Billing Address',
      billingAndShipping: 'Billing & Shipping Address',
      blockedEmail: 'Your email address has been blocked. You must have a valid email to order. Please contact us at {phoneNumber} for assistance.',
      city: '*City',
      companyName: 'Company Name',
      contactInfo: 'Contact Information',
      corporate: 'Is your purchase for a corporate group?',
      country: '*Country',
      disclaimer: 'If your billing address is a P.O. Box, your package will need to be picked up on site.',
      firstName: '*First Name',
      lastName: '*Last Name',
      phoneNumber: '*Phone Number',
      purchaseOrderNumber: 'Purchase Order Number',
      state: '*State',
      zipCode: '*Zip Code / Postal Code',
    }
  },
  zhCN() {
    return {
      address1: '*街道地址行1',
      address2: '街道地址 2',
      billingAddress: '账单地址',
      billingAndShipping: '账单及送货地址',
      blockedEmail: '您的电子邮箱地址已被屏蔽。订购时须输入有效的电子邮箱地址。请拨打{phoneNumber}来联系我们获取帮助。',
      city: '*城市',
      companyName: '公司名称',
      contactInfo: 'Contact Information',
      corporate: '购买公司团体票吗？',
      country: '*国家/地区',
      disclaimer: '如果您的帐单地址为邮政信箱，则您需要现场领取套票。',
      firstName: '*名字',
      lastName: '*姓氏',
      phoneNumber: '*电话号码',
      purchaseOrderNumber: '订单号',
      state: '*省',
      zipCode: '*邮编/邮政编码',
    }
  },
}
