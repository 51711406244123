const namespaced = true

const state = {
  eventName:'',
  eventLocation:null,
  arnSiteId: null,
  protectGroupEnabled: true,
  protectGroupPremium: 0,
  formCurrency: 'USD',
  formRate: 1.0,
  status: null
}

const mutations = {
  updateEventLocation(state, location) { // Namespaced
    state.eventLocation = location
  },
  updateArnSiteId(state, id) { // Namespaced
    state.arnSiteId = id
  },
  updateStatus(state, status) { // Namespaced
    state.status = status
  }
}

export default {
  namespaced,
  state,
  mutations,
}
