const namespaced = true

const state = {
  hotel: null,
  reservations: [],
  reservation: null,
  reservationsObj: null,
}

const mutations = {
  setAllReservationsObj(state, reservationsObj) {
    state.reservationsObj = reservationsObj
  },
  setReservations(state, reservation) {
    state.reservation = reservation
  },
  selectReservation(state, reservation) {
    state.reservation = reservation
  },
  deselectReservation(state) {
    state.reservation = null
  },
  setReservationHotel(state, hotel) {
    state.hotel = hotel
  }
}

const getters = {
  getAllReservationsObj: state => {
    return state.reservationsObj
  },
  getReservation: state => {
    return state.reservation
  },
  getReservationHotel: state => {
    return state.hotel
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations
}