const namespaced = true

const state = {
  email: ''
}

const mutations = {
  updateEmail(state, email) {
    state.email = email
  }
}

export default {
  namespaced,
  state,
  mutations,
}