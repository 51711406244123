import { trackPageView as gtmTrackPageView } from '../../../default/google_tag_manager.js'

const namespaced = true

const state = {
  display: null
}

const mutations = {
  displayCart(state) {
    if(!document.querySelector('.cart-edit-page')){
      document.querySelector('body').classList.add('nav-cart-displaying')
    }
    gtmTrackPageView(`https://${window.location.host}/cart`, 'Cart Slideout', '/cart')
    state.display = true
  },
  hideCart(state) {
    document.querySelector('body').classList.remove('nav-cart-displaying')
    state.display = false
  }

}

const getters = {
  getCartDisplay: (state) => {
    return state.display
  },
  openClass: (state) => {
    if (state.display == true) {
      return 'display'
    } else if (state.display == false) {
      return 'closed'
    } else {
      return ''
    }
  }
}


export default {
  namespaced,
  state,
  mutations,
  getters
}
