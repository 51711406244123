export default {

  en() {
    return {
      addCreditCard: 'Add Credit Card',
      canceled: 'Payment Term Canceled',
      charged_on: 'Charged on %{date}',
      due: 'Due on %{date}',
      due_passed: 'Due date has passed',
      due_soon: 'This payment will be charged within 24 hours',
      due_with_card: 'Will be charged on %{date} with card ending in %{last4}',
      error_saving: 'There was an error saving your card',
      pay_amount: 'Pay %{amount}',
      pay_now: 'Pay Now',
      payment_details:'Your Payment Details',
      payment_number: 'Payment %{number}:',
      payment_terms: 'Payment Terms',
      please_call: 'Please call %{link} if you have any issues.',
      processing_amount: 'Processing %{amount}',
      refund: 'A refund of %{amount} was processed of on %{date}',
      select_terms: 'Select Payment Term(s) to Update'
    }
  },
  ar() {
    return {
      addCreditCard: 'أضِفْ إلى سلّة/عربة التسوّق',
      canceled: 'تمّ إلغاء شرط الدفع',
      charged_on: 'تمّ شحنها %{date}',
      due: 'الاستحقاق %{date}',
      due_passed: 'تمّ تجاوز تاريخ الاستحقاق',
      due_soon: 'سيتمّ خَصْم المبلغ عند إدخال بيانات بطاقتك',
      due_with_card: 'سيتمّ تحصيل الرسوم في٪ {date} على أن تنتهي البطاقة بـ٪ {last4}',
      error_saving: 'الجمعة: أصفر',
      pay_amount: 'ادفع %{amount}',
      pay_now: 'أضف بطاقة جديدة',
      payment_details: 'تفاصيل الدفع الخاصّة بك',
      payment_number: ':الدفع %{number}',
      payment_terms: ' مدّة الدفع وطريقته',
      please_call: '.الرجاء الاتّصال بـ %{link} إذا كانت لديك أيّة استفسارات',
      processing_amount: 'معالجة %{amount}',
      refund: 'تمّت معالجة استرجاع٪ {amount} بتاريخ٪ {date}',
      select_terms: 'اختر قسط/أقساط الدفع للتحيين',
    }
  },
  de() {
    return {
      addCreditCard: 'Kreditkarte hinzufügen',
      canceled: 'Zahlungsmodalität storniert',
      charged_on: 'Zahlung erfasst',
      due: 'Fällig am %{date}',
      due_passed: 'Fälligkeitsdatum ist abgelaufen',
      due_soon: 'Diese Zahlung wird in den nächsten 24 Stunden verarbeitet',
      due_with_card: 'Wird am %{date} der Karte mit den Endziffern %{last4} belastet',
      error_saving: 'Bei der Speicherung Ihrer Karte ist ein Fehler aufgetreten',
      pay_amount: 'Bezahlen Sie %{amount}',
      pay_now: 'Jetzt bezahlen',
      payment_details: 'Ihre Zahlungsangaben',
      payment_number: 'Zahlung %{number}:',
      payment_terms: 'Zahlungsfristen',
      please_call: 'Bitte rufen Sie uns unter der Rufnummer %{link} an, falls irgendwelche Probleme auftreten.',
      processing_amount: '%{amount} wird verarbeitet',
      refund: 'Eine Rückerstattung von %{amount} wurde am %{date} verarbeitet',
      select_terms: 'Wählen Sie zum Aktualisieren die Zahlungsmodalität(en) aus.',
    }
  },
  enGB() {
    return {
      addCreditCard: 'Add Credit Card',
      canceled: 'Payment Term Canceled',
      charged_on: 'Charged on %{date}',
      due: 'Due on %{date}',
      due_passed: 'Due date has passed',
      due_soon: 'This payment will be charged within 24 hours',
      due_with_card: 'Will be charged on %{date} with card ending in %{last4}',
      error_saving: 'There was an error saving your card',
      pay_amount: 'Pay %{amount}',
      pay_now: 'Pay Now',
      payment_details: 'Your Payment Details',
      payment_number: 'Payment %{number}:',
      payment_terms: 'Payment Terms',
      please_call: 'Please call %{link} if you have any issues.',
      processing_amount: 'Processing %{amount}',
      refund: 'A refund of %{amount} was processed of on %{date}',
      select_terms: 'Select Payment Term(s) to Update',
    }
  },
  esMX() {
    return {
      addCreditCard: 'Añadir Tarjeta de Crédito',
      canceled: 'Plazo de Pago Cancelado',
      charged_on: 'Pago Capturado',
      due: 'Pagadero en %{date}',
      due_passed: 'Fecha del pago ha pasado',
      due_soon: 'Este pago será cargado dentro de las próximas 24 horas',
      due_with_card: 'Será cobrado en %{date} con la tarjeta terminación %{last4}',
      error_saving: 'Hubo un error al guardar su tarjeta',
      pay_amount: 'Pagar %{amount}',
      pay_now: 'Pagar Ahora',
      payment_details: 'Detalles de Su Pago',
      payment_number: 'Pago %{number}:',
      payment_terms: 'Plazos de Pago',
      please_call: 'Favor de llamar al %{link} si tiene cualquier tipo de problema',
      processing_amount: 'Procesando %{amount}',
      refund: 'Un reembolso por %{amount} fue procesado en %{date}',
      select_terms: 'Seleccione los Plazos de Pago por Actualizar',
    }
  },
  es() {
    return {
      addCreditCard: 'Añadir Tarjeta de Crédito',
      canceled: 'Plazo de Pago Cancelado',
      charged_on: 'Cobrado el %{date}',
      due: 'Fecha de vencimiento %{date}',
      due_passed: 'La fecha de vencimiento ha pasado',
      due_soon: 'Este pago se cobrará en 24 horas',
      due_with_card: 'Se cobrará el %{date} con tarjeta terminada en %{last4}',
      error_saving: 'Ha habido un error al guardar tu tarjeta',
      pay_amount: 'Paga %{amount}',
      pay_now: 'Pagar Ahora',
      payment_details: 'Detalles de Tu Pago',
      payment_number: 'Pago %{number}:',
      payment_terms: 'Condiciones de Pago',
      please_call: 'Por favor, llama al %{link} si tienes algún problema.',
      processing_amount: 'Procesando %{amount}',
      refund: 'Un reembolso de %{amount} fue procesado en %{date}',
      select_terms: 'Selecciona el/los Término(s) de Pago a Actualizar',
    }
  },
  fr() {
    return {
      addCreditCard: 'Ajouter une carte de crédit',
      canceled: 'Délai de paiement annulé',
      charged_on: 'Paiement débité le %{date}',
      due: 'Date d\'échéance : %{date}',
      due_passed: 'L\'échéance du paiement est dépassée',
      due_soon: 'Ce paiement sera facturé dans les 24 heures',
      due_with_card: 'Sera prélevé le %{date} sur la carte bancaire se terminant par %{last4}',
      error_saving: 'Une erreur est survenue lors de l\'enregistrement de votre carte',
      pay_amount: 'Payer %{amount}',
      pay_now: 'Payer maintenant',
      payment_details: 'Vos coordonnées de paiement',
      payment_number: 'Paiement %{number} :',
      payment_terms: 'Modalités de paiement',
      please_call: 'Veuillez appeler le %{link} si vous rencontrez le moindre problème.',
      processing_amount: 'Traitement de %{amount}',
      refund: 'Un remboursement de %{amount} a effectué le %{date}',
      select_terms: 'Sélectionnez les modalités de paiement que vous souhaitez mettre à jour.',
    }
  },
  it() {
    return {
      addCreditCard: 'Aggiungi carta di credito',
      canceled: 'Pagamento annullato',
      charged_on: 'Addebito effettuato',
      due: 'Da pagare entro il %{date}',
      due_passed: 'Data di scadenza superata',
      due_soon: 'Questo pagamento verrà addebitato entro 24 ore',
      due_with_card: 'Pagherai il %{date} tramite carta con cifre finali %{last4}',
      error_saving: 'Si è verificato un errore durante il salvataggio della tua carta',
      pay_amount: 'Paghi %{amount}',
      pay_now: 'Paga subito',
      payment_details: 'Le tue informazioni di pagamento',
      payment_number: 'Pagamento %{number}:',
      payment_terms: 'Modalità di pagamento',
      please_call: 'Per qualsiasi problema, ti preghiamo di contattare il numero %{link}.',
      processing_amount: 'Pagamento di %{amount} in elaborazione',
      refund: 'Abbiamo elaborato un rimborso pari a %{amount} in data %{date}',
      select_terms: 'Seleziona la modalità di pagamento da aggiornare',
    }
  },
  ja() {
    return {
      addCreditCard: 'クレジットカード情報を追加',
      canceled: '支払い条件がキャンセルされました',
      charged_on: '支払が認証されました',
      due: '期限：％{date}',
      due_passed: '期日が過ぎました',
      due_soon: 'ご請求額は24時間以内に課金されます。',
      due_with_card: '%{last4} で終わるカードで %{date} に請求されます',
      error_saving: 'カードの保存中にエラーが発生しました',
      pay_amount: ' %{amount}を支払う',
      pay_now: 'すぐ払う',
      payment_details: 'お支払い詳細',
      payment_number: '支払い %{number}:',
      payment_terms: 'お支払い期間',
      please_call: '問題が発生した場合には %{link} までお電話ください。',
      processing_amount: '処理中 %{amount}　',
      refund: '％{amount}の払い戻しは％{date}に処理されました',
      select_terms: '更新する支払い条件を選択してください',
    }
  },
  pl() {
    return {
      addCreditCard: 'dodaj kartę kredytową',
      canceled: 'termin płatności anulowany',
      charged_on: 'należność pobrana %{date}',
      due: 'należne %{date}',
      due_passed: 'upłynął termin płatności',
      due_soon: 'należność zostanie pobrana w ciągu 24 godzin',
      due_with_card: 'zostanie pobrana %{date} z karty o końcówce %{last4}',
      error_saving: 'wystąpił błąd podczas zapisywania karty',
      pay_amount: 'zapłać %{amount}',
      pay_now: 'zapłać teraz',
      payment_details: 'Your Payment Details',
      payment_number: 'płatność %{number}:',
      payment_terms: 'terminy płatności',
      please_call: 'W razie wątpliwości proszę dzwonić pod numer %{link}.',
      processing_amount: 'pobieramy %{amount}',
      refund: 'zwrot w wysokości %{amount} przekazano %{date}',
      select_terms: 'wybierz termin(y) płatności w celu zaktualizowania      ',
    }
  },
  po() {
    return {
      addCreditCard: 'Add Credit Card',
      canceled: 'Payment Term Canceled',
      charged_on: 'Charged on %{date}',
      due: 'Due on %{date}',
      due_passed: 'Due date has passed',
      due_soon: 'This payment will be charged within 24 hours',
      due_with_card: 'Will be charged on %{date} with card ending in %{last4}',
      error_saving: 'There was an error saving your card',
      pay_amount: 'Pay %{amount}',
      pay_now: 'Pay Now',
      payment_details: 'Your Payment Details',
      payment_number: 'Payment %{number}:',
      payment_terms: 'Payment Terms',
      please_call: 'Please call %{link} if you have any issues.',
      processing_amount: 'Processing %{amount}',
      refund: 'A refund of %{amount} was processed of on %{date}',
      select_terms: 'Select Payment Term(s) to Update',
    }
  },
  zhCN() {
    return {
      addCreditCard: '添加信用卡',
      canceled: '已取消付款方式',
      charged_on: 'Charged on %{date}',
      due: '截止日为 %{date}',
      due_passed: '付款日已过',
      due_soon: '款项将在24小时内被扣除',
      due_with_card: '将在％{date}日收取卡号尾数为％{last4}的费用',
      error_saving: '保存您的银行卡时出错',
      pay_amount: '支付%{amount}',
      pay_now: '立即支付',
      payment_details: '您的付款明细',
      payment_number: '支付%{number}：',
      payment_terms: '付款方式',
      please_call: '如果您有任何问题，请只致电 %{link}。',
      processing_amount: '处理%{amount}',
      refund: '已于％{date}％处理{amount}的退款',
      select_terms: '选择要更新的付款条款',
    }
  },
}
