export default {
  en() {
    return {
      accept: 'Accept', // https://qe360.quintevents.com/admin/phrases/20122
      additionalHotelDisclaimer: 'Additional taxes and fees may apply during checkout and upon check-in at the hotel. Transportation not included.',
      addHotel: 'PLEASE NOTE: You must add a hotel stay to your cart to continue checking out',
      amenities: 'Amenities',
      airportShuttle: 'Airport Shuttle',
      back: 'Back to package',
      breakfast: 'Complimentary Breakfast',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'Checkout',
      chooseAccomodation: 'Choose Your Accommodations',
      chooseAccomodationDescription: 'Below is a listing of hotel offerings through {brand_name}. If you don\'t need a hotel, ',
      chooseOptionalAccomodation: 'Choose Your Optional Accommodation',
      chooseRoom: 'Choose Room',
      chooseARoom: 'Choose A Room',
      clickHereToContinue: 'click here to continue to checkout.',
      continueToCheckout: 'Yes, Continue to Checkout',
      contractedTotal: 'for {checkIn} ~ {checkOut}',
      cugAccountLink:'Purchased a hotel through our booking portal? Give us a call at 1.512.691.9555 for any questions related to your previous orders.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destination',
      destinationPlaceholder: 'City, State, Country',
      distance: 'Distance',
      exclusiveHotel: 'Exclusive Hotel',
      exclusiveHotelFinePrint: 'Includes taxes, fees and {quantity}night stay',
      exclusiveRates: 'EXCLUSIVE RATES',
      featured: 'FEATURED',
      featuredWithTransportation: 'FEATURED: WITH TRANSPORTATION',
      filterBy: 'Filter By',
      fitness: 'Fitness Center',
      hotelOfferings: 'Below is a listing of hotel offerings through {brand_name}.',
      imageDisclaimer: 'It is the responsibility of the property chain and/or the individual property to ensure the accuracy of the photos displayed. We are not responsible for any inaccuracies in the photos.',
      internet: 'Internet Access',
      kitchen: 'Kitchen/Kitchenette',
      listView: 'List View',
      mapView: 'Map View',
      nights: 'nights',
      noHotelProceed: 'Don\'t need a hotel? Checkout',
      note: 'NOTE:',
      nowChooseAccomodation: 'Now, choose your accommodation',
      otherHotels: 'Other Hotel Offerings',
      packageSuccess: 'Success! {package_name} has been added to your cart.',
      perNight: 'per night',
      pets: 'Pets Allowed',
      pool: 'Pool',
      price: 'Price',
      priceChange: 'There has been a price change',
      proceedToCart: 'Yes, proceed to cart',
      proceedToCheckout: 'Yes, proceed to checkout',
      propertyDescription: 'Property Description',
      reject: 'Reject',
      restaurant: 'Restaurant On-Site',
      retailTotal: 'for {quantity} nights',
      reviews: 'Reviews',
      room: 'Room',
      Rooms: 'Rooms',
      roomNum: '# of Rooms',
      rooms: 'Rooms',
      roomRemaining: 'Only {quantity} room remaining',
      roomsRemaining: 'Only {quantity} rooms remaining',
      roomTotal: 'for {quantity} nights',
      roomcashTooltipText: 'Redeem your RoomCash to receive a discounted nightly rate on your hotel room. The amount of your discount will be deducted from your RoomCash balance. Please note, RoomCash may not be redeemed for more than the available discount amount per night.',
      saveAmtRoomcash: 'save {amt} per night',
      seeAllProperties: 'See all properties',
      seeOtherOptions: 'No, see other options',
      selectRoom: 'Select Room',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Show Additional Hotels',
      showLess: 'Show Less',
      showMore: 'Show More',
      somethingDifferent: 'Looking for something different? We have more hotels available.',
      sortBy: 'Sort By',
      sortFilter: 'Sort/Filter',
      starRating: 'Star Rating',
      startsAt: 'Starts at',
      stars: 'stars',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'to accompany your ticket',
      toFromHotel: 'One-time transportation to and from the event venue and hotel on days included with purchase',
      updateSearch: 'Update Search',
      userReviews: 'User Reviews',
      yourPackage: 'Your Package',
    }
  },
  ar() {
    return {
      accept: 'اقبل \'، // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: '.قد يتمّ تطبيق ضرائب ورسوم إضافيّة عند تسجيل الخروج من الفندق و تسجيل الوصول إليه. العرض غير شامل للنقل',
      addHotel: 'يُرجى ملاحظة ما يلي: يجب إضافة إقامة في فندق إلى عربة التسوّق الخاصة بك لمواصلة عملية الدفع ومغادرة الفندق',
      amenities: 'مرافق الراحة والرفاهيّة',
      airportShuttle: 'خدمة النقل من/إلى المطار',
      back: 'إفطار مجانيّ',
      breakfast: 'إفطار مجانيّ',
      checkIn: 'تسجيل الوصول إلى الفندق',
      checkOut: 'تسجيل الخروج من الفندق',
      checkoutStep: 'نقطة الدفع',
      chooseAccomodation: 'اُنقر هنا لمواصلة تسجيل الخروج',
      chooseAccomodationDescription: '،فيما يلي قائمة بعروض الفنادق من بين {brand_name}. إذا كنت لا تحتاج إلى فندق',
      chooseOptionalAccomodation: 'اختر مكان إقامتك الإضافيّ',
      chooseRoom: 'اختر الغرفة',
      chooseARoom: 'اختر غرفة',
      clickHereToContinue: 'اختر مكان إقامتك الإضافيّ',
      continueToCheckout: 'نعم، واصل عمليّة الدفع والمغادرة',
      contractedTotal: 'لـ {checkIn} ~ {checkOut}',
      cugAccountLink: 'هل حجزت فندق من خلال بوّابة الحجز الخاصّة بنا؟ اتّصل بنا على 1.512.691.9555 لأيّة استفسارات تتعلّق بطلباتك السابقة',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'الوجهة',
      destinationPlaceholder: 'المسافة',
      distance: 'المسافة',
      exclusiveHotel: 'السعر يشمل الضرائب والرسوم و %(الكميّة) - ليلة الإقامة',
      exclusiveHotelFinePrint: 'اُرْفض',
      exclusiveRates: 'أسعار حصريّة',
      featured: 'مُمَيّز',
      featuredWithTransportation: ' مع خدمة النقل :مميّز',
      filterBy: 'صنّف حسب',
      fitness: 'تقع على عاتق المجموعة / أو الفرد مسؤوليّة ضمان دقّة الصور المعروضة. نحن لسنا مسؤولين عن أيّة أخطاء قد تطرأ عليها ',
      hotelOfferings: '{brand_name} فيما يلي قائمة بعروض الفنادق من خلال ',
      imageDisclaimer: 'تقع على عاتق المجموعة / أو الفرد مسؤوليّة ضمان دقّة الصور المعروضة. نحن لسنا مسؤولين عن أيّة أخطاء قد تطرأ عليها ',
      internet: 'الوصول إلى شبكة الإنترنت',
      kitchen: 'عرض القائمة',
      listView: 'عرض القائمة',
      mapView: 'عرض الخريطة',
      nights: ':ملاحظة',
      noHotelProceed: 'ألا تحتاج إلى إقامة في فندق؟ يُرجى تسجيل الخروج',
      note: 'نعم، واصل لتسجيل الخروج',
      nowChooseAccomodation: 'اختر مكان إقامتك، الآن',
      otherHotels: 'عروض الفنادق الأخرى',
      packageSuccess: 'لقد تمّت العمليّة بنجاح! {package_name} تمّ إضافته إلى سلّة تسوّقك',
      perNight: 'في الليلة',
      pets: 'يمكن اصطحاب الحيوانات',
      pool: 'عمولة المُقامرة',
      price: 'الثمن',
      priceChange: 'كان هناك تغيير في السعر',
      proceedToCart: 'نعم، واصل إلى سلّة التسوّق',
      proceedToCheckout: 'نعم، واصل لتسجيل الخروج',
      propertyDescription: 'وصف الملكيّة',
      reject: 'لا، اِطّلع على خيارات أخرى',
      restaurant: 'مطعم على عين المكان',
      retailTotal: 'لـ {quantity} الليالي المُقضّاة',
      reviews: 'الآراء',
      room: 'الغرفة',
      Rooms: 'الغرف',
      roomNum: '# من الغرف',
      rooms: 'الغرف',
      roomRemaining: 'فقط {quantity} عدد الغرف المُتبقّية',
      roomsRemaining: 'لقراءة الأحكام والشروط الخاصّة بك والموافقة عليها',
      roomTotal: 'لـ {quantity} الليالي المُقضّاة',
      roomcashTooltipText: '.الّذي تملكه RoomCash الخاص بك للحصول على سعر ليليّ مُخفّض على غرفتك بالفندق. سيتم خصم مبلغ التخفيض الخاصّ بك من رصيد RoomCash استرجع رصيد.لا يتمّ استرجاعه بمبلغ يفوق مبلغ الخصم المُتاح في كلّ ليلة RoomCash يُرجى ملاحظة أنّ',
      saveAmtRoomcash: 'وفّر {amt} في اليلة',
      seeAllProperties: 'إظهار كلّ الخصائص',
      seeOtherOptions: 'لا، اِطّلع على خيارات أخرى',
      selectRoom: 'اختر غرفة',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'عرض فنادق إضافية',
      showLess: 'أظهر أقلّ',
      showMore: 'أظهر المزيد',
      somethingDifferent: 'هل تبحث عن شيء مختلف؟ لدينا المزيد من الفنادق المتاحة',
      sortBy: 'صنّف حسب',
      sortFilter: 'تصنيف/انتقاء',
      starRating: 'يبدأ من',
      startsAt: 'يبدأ من',
      stars: 'stars',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: 'المجموع',
      toAccompanyTicket: 'لا تنس تذكرتك قصد الاستظهار بها',
      toFromHotel: 'One-time transportation to and from the event venue and hotel on days included with purchase',
      updateSearch: 'حيّن عمليّة البحث',
      userReviews: 'أراء المُستخدمين',
      yourPackage: 'باقتك',
    }
  },
  de() {
    return {
      accept: 'Akzeptieren\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Beim Ein- oder Auschecken im Hotel können zusätzliche Steuern und Gebühren anfallen. Transfers sind nicht inbegriffen.',
      addHotel: 'HINWEIS: Sie müssen einen Hotelaufenthalt zu Ihrem Warenkorb hinzufügen, um fortzufahren',
      amenities: 'Ausstattung',
      airportShuttle: 'Flughafen-Shuttle',
      back: 'Zurück zum Paket',
      breakfast: 'Kostenloses Frühstück',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'Kasse',
      chooseAccomodation: 'Wählen Sie Ihre Unterkunft',
      chooseAccomodationDescription: 'Nachfolgend finden Sie eine Liste der Hotelangebote von {brand_name}. Wenn Sie kein Hotel benötigen, ',
      chooseOptionalAccomodation: 'Wählen Sie Ihre optionale Unterkunft',
      chooseRoom: 'Zimmer wählen',
      chooseARoom: 'Wählen Sie ein Zimmer',
      clickHereToContinue: 'Nachstehend finden Sie eine Auflistung von Hotelangeboten durch %{brand_name}. Wenn Sie kein Hotel benötigen, ',
      continueToCheckout: 'Ja, weiter zur Kasse',
      contractedTotal: 'für {checkIn} ~ {checkOut}',
      cugAccountLink: 'Haben Sie ein Hotel über unser Buchungsportal gebucht? Rufen Sie uns unter +1.512.691.9555 an, wenn Sie Fragen zu Ihren bisherigen Bestellungen haben.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Reiseziel',
      destinationPlaceholder: 'Stadt, Staat/Provinz, Land',
      distance: 'Distanz',
      exclusiveHotel: 'Exklusives Hotel',
      exclusiveHotelFinePrint: 'Inklusive Steuern, Gebühren und {quantity} Übernachtungen',
      exclusiveRates: 'EXKLUSIVPREISE',
      featured: 'FEATURED',
      featuredWithTransportation: 'FEATURED: WITH TRANSPORTATION',
      filterBy: 'Filtern nach',
      fitness: 'Fitnesscenter',
      hotelOfferings: 'Nachstehend finden Sie eine Auflistung der Hotelangebote von {brand_name}.',
      imageDisclaimer: 'Es liegt in der Verantwortung der Hotelkette und/oder des einzelnen Hotels, die Genauigkeit der gezeigten Bilder zu gewährleisten. Wir sind nicht für ungenaue oder fehlerhafte Bilder verantwortlich.',
      internet: 'Internetzugang',
      kitchen: 'Küche/Kochnische',
      listView: 'Listenansicht',
      mapView: 'Kartenansicht',
      nights: 'Nächte',
      noHotelProceed: 'Benötigen Sie kein Hotel? Zur Kasse',
      note: 'HINWEIS:',
      nowChooseAccomodation: 'Wählen Sie jetzt Ihre Unterkunft',
      otherHotels: 'Weitere Hotelangebote',
      packageSuccess: 'Geschafft! {package_name} wurde zu Ihrem Warenkorb hinzugefügt.',
      perNight: 'pro Nacht',
      pets: 'Haustiere erlaubt',
      pool: 'Pool',
      price: 'Preis',
      priceChange: 'Es hat eine Preisänderung gegeben',
      proceedToCart: 'Ja, weiter zum Warenkorb',
      proceedToCheckout: 'Ja, weiter zur Kasse',
      propertyDescription: 'Hotelbeschreibung',
      reject: 'Ablehnen',
      restaurant: 'Restaurant vor Ort',
      retailTotal: 'für {quantity} Nächte',
      reviews: 'Bewertungen',
      room: 'Zimmer',
      Rooms: 'Zimmer',
      roomNum: 'Anzahl Zimmer',
      rooms: 'Zimmer',
      roomRemaining: 'Nur noch {quantity} Zimmer verfügbar',
      roomsRemaining: 'Nur noch {quantity} Zimmer übrig',
      roomTotal: 'für {quantity} Nächte',
      roomcashTooltipText: 'Lösen Sie Ihr RoomCash gegen einen ermäßigten Übernachtungspreis für Ihr Hotelzimmer ein. Der Betrag Ihrer Ermäßigung wird von Ihrem RoomCash-Guthaben abgezogen. Bitte beachten Sie, dass RoomCash nur für den verfügbaren Ermäßigungsbetrag pro Nacht eingelöst werden kann.',
      saveAmtRoomcash: 'Sparen Sie {amt} pro Nacht',
      seeAllProperties: 'Alle Hotels anzeigen',
      seeOtherOptions: 'Nein, andere Optionen ansehen',
      selectRoom: 'Zimmer auswählen',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Weitere Hotels anzeigen',
      showLess: 'Weniger anzeigen',
      showMore: 'Mehr anzeigen',
      somethingDifferent: 'Suchen Sie etwas anderes? Wir bieten noch weitere Hotels an.',
      sortBy: 'Sortieren nach',
      sortFilter: 'Sortieren/Filtern',
      starRating: 'Sternebewertung',
      startsAt: 'Ab',
      stars: 'Sterne',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: 'Total',
      toAccompanyTicket: 'zu Ihrem Ticket hinzu',
      toFromHotel: 'Einmaliger Transfer zwischen dem Veranstaltungsort und dem Hotel an den im Kauf eingeschlossenen Tagen',
      updateSearch: 'Suche aktualisieren',
      userReviews: 'Kundenbewertungen',
      yourPackage: 'Ihr Paket',
    }
  },
  enGB() {
    return {
      accept: 'Accept\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Additional taxes and fees may apply during checkout and upon check-in at the hotel. Transportation not included.',
      addHotel: 'PLEASE NOTE: You must add a hotel stay to your cart to continue checking out',
      amenities: 'Amenities',
      airportShuttle: 'Airport Shuttle',
      back: 'Back to package',
      breakfast: 'Complimentary Breakfast',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'Checkout',
      chooseAccomodation: 'Choose Your Accommodations',
      chooseAccomodationDescription: 'Below is a listing of hotel offerings through {brand_name}. If you don\'t need a hotel, ',
      chooseOptionalAccomodation: 'Choose Your Optional Accommodation',
      chooseRoom: 'Choose Room',
      chooseARoom: 'Choose A Room',
      clickHereToContinue: 'click here to continue to checkout.',
      continueToCheckout: 'Yes, Continue to Checkout',
      contractedTotal: 'for {checkIn} ~ {checkOut}',
      cugAccountLink: 'Purchased a hotel through our booking portal? Give us a call at 1.512.691.9555 for any questions related to your previous orders.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destination',
      destinationPlaceholder: 'City, State, Country',
      distance: 'Distance',
      exclusiveHotel: 'Exclusive Hotel',
      exclusiveHotelFinePrint: 'Includes taxes, fees and {quantity}night stay',
      exclusiveRates: 'EXCLUSIVE RATES',
      featured: 'FEATURED',
      featuredWithTransportation: 'FEATURED: WITH TRANSPORTATION',
      filterBy: 'Filter By',
      fitness: 'Fitness Center',
      hotelOfferings: 'Below is a listing of hotel offerings through {brand_name}.',
      imageDisclaimer: 'It is the responsibility of the property chain and/or the individual property to ensure the accuracy of the photos displayed. We are not responsible for any inaccuracies in the photos.',
      internet: 'Internet Access',
      kitchen: 'Kitchen/Kitchenette',
      listView: 'List View',
      mapView: 'Map View',
      nights: 'nights',
      noHotelProceed: 'Don\'t need a hotel? Checkout',
      note: 'NOTE:',
      nowChooseAccomodation: 'Now, choose your accommodation',
      otherHotels: 'Other Hotel Offerings',
      packageSuccess: 'Success! {package_name} has been added to your cart.',
      perNight: 'per night',
      pets: 'Pets Allowed',
      pool: 'Pool',
      price: 'Price',
      priceChange: 'There has been a price change',
      proceedToCart: 'Yes, proceed to cart',
      proceedToCheckout: 'Yes, proceed to checkout',
      propertyDescription: 'Property Description',
      reject: 'Reject',
      restaurant: 'Restaurant On-Site',
      retailTotal: 'for {quantity} nights',
      reviews: 'Reviews',
      room: 'Room',
      Rooms: 'Rooms',
      roomNum: '# of Rooms',
      rooms: 'Rooms',
      roomRemaining: 'Only {quantity} room remaining',
      roomsRemaining: 'Only {quantity} rooms remaining',
      roomTotal: 'for {quantity} nights',
      roomcashTooltipText: 'Redeem your RoomCash to receive a discounted nightly rate on your hotel room. The amount of your discount will be deducted from your RoomCash balance. Please note, RoomCash may not be redeemed for more than the available discount amount per night.',
      saveAmtRoomcash: 'save {amt} per night',
      seeAllProperties: 'See all properties',
      seeOtherOptions: 'No, see other options',
      selectRoom: 'Select Room',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Show Additional Hotels',
      showLess: 'Show Less',
      showMore: 'Show More',
      somethingDifferent: 'Looking for something different? We have more hotels available.',
      sortBy: 'Sort By',
      sortFilter: 'Sort/Filter',
      starRating: 'Star Rating',
      startsAt: 'Starts at',
      stars: 'stars',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'to accompany your ticket',
      toFromHotel: 'One-time transportation to and from the event venue and hotel on days included with purchase',
      updateSearch: 'Update Search',
      userReviews: 'User Reviews',
      yourPackage: 'Your Package',
    }
  },
  esMX() {
    return {
      accept: 'Accept\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Pueden aplicar tarifas e impuestos adicionales al momento del pago y al momento del registro en el hotel. La transportación no está incluida.',
      addHotel: 'TOME NOTA: Debe añadir una estadía de hotel a su carrito para seguir con el proceso de pago',
      amenities: 'Amenidades',
      airportShuttle: 'Shuttle al Aeropuerto',
      back: 'Regresar al paquete',
      breakfast: 'Desayuno en Cortesía',
      checkIn: 'Entrada',
      checkOut: 'Salida',
      checkoutStep: 'Proceder al Pago',
      chooseAccomodation: 'Elija Su Alojamiento',
      chooseAccomodationDescription: 'A continuación se muestra una lista de ofertas de hoteles a través de {brand_name}. Si no necesita un hotel,',
      chooseOptionalAccomodation: 'Elija su Alojamiento Opcional',
      chooseRoom: 'Elegir Habitación',
      chooseARoom: 'Elija una Habitación',
      clickHereToContinue: 'A continuación encontrará una lista de ofertas de hotel a través de %{brand_name}. Si no necesita un hotel, ',
      continueToCheckout: 'Sí, seguir al pago',
      contractedTotal: 'del {checkIn} ~ {checkOut}',
      cugAccountLink: '¿Compró un hotel a través de nuestro portal de reservas? Llámenos al 1.512.691.9555 para cualquier pregunta relacionada con sus pedidos anteriores.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destino',
      destinationPlaceholder: 'Ciudad, Estado, País',
      distance: 'Distancia',
      exclusiveHotel: 'Hotel Exclusivo',
      exclusiveHotelFinePrint: 'Incluye impuestos, tarifas y {quantity}noches de estadía',
      exclusiveRates: 'TARIFAS EXCLUSIVAS',
      featured: 'DESTACADO',
      featuredWithTransportation: 'DESTACADO: CON TRANSPORTACIÓN',
      filterBy: 'Filtrar Por',
      fitness: 'Centro de Fitness',
      hotelOfferings: 'Abajo encontrará una lista de ofertas de hotel a través de {brand_name}.',
      imageDisclaimer: 'Es responsabilidad de la cadena de propiedad y/o de la propiedad individual garantizar la precisión de las fotos que se muestran. No somos responsables de ninguna imprecisión en las fotos.',
      internet: 'Acceso a Internet ',
      kitchen: 'Cocina/Cocineta',
      listView: 'Vista de Lista',
      mapView: 'Vista del Mapa',
      nights: 'noches',
      noHotelProceed: '¿No necesita un hotel? Proceda al pago',
      note: 'NOTA:',
      nowChooseAccomodation: 'Ahora elija su alojamiento',
      otherHotels: 'Otras Ofertas de Hotel',
      packageSuccess: '¡Éxito! {package_name} ha sido añadido a su carrito.',
      perNight: 'por noche',
      pets: 'Mascotas Permitidas',
      pool: 'Piscina',
      price: 'Precio',
      priceChange: 'Ha habido un cambio en el precio',
      proceedToCart: 'Sí, proceder al carrito',
      proceedToCheckout: 'Sí, proceder al pago',
      propertyDescription: 'Descripción de la Propiedad',
      reject: 'Rechazar',
      restaurant: 'Restaurante en el Sitio',
      retailTotal: 'por {quantity} noches',
      reviews: 'Reseñas',
      room: 'Habitación',
      Rooms: 'Habitaciones',
      roomNum: '# de Habitaciones',
      rooms: 'Habitaciones',
      roomRemaining: 'Solo quedan {quantity} habitaciones',
      roomsRemaining: 'Solo quedan {quantity} habitaciones',
      roomTotal: 'por {quantity} noches',
      roomcashTooltipText: 'Canjee su RoomCash para recibir una tarifa nocturna con descuento en su habitación de hotel. El monto de su descuento se deducirá de su saldo de RoomCash. Tenga en cuenta que RoomCash no se puede canjear por más del monto de descuento disponible por noche.',
      saveAmtRoomcash: 'ahorre {amt} por noche',
      seeAllProperties: 'Ver todas las propiedades',
      seeOtherOptions: 'No, ver otras opciones',
      selectRoom: 'Seleccionar Habitación',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Mostrar Hoteles Adicionales',
      showLess: 'Ver Menos',
      showMore: 'Ver Más',
      somethingDifferent: '¿Busca algo distinto? Tenemos más hoteles disponibles.',
      sortBy: 'Ordenar Por',
      sortFilter: 'Orden/Filtros',
      starRating: 'Clasificación por Estrellas',
      startsAt: 'Empieza en',
      stars: 'estrellas',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'para acompañar su boleto',
      toFromHotel: 'Transportación por una vez desde y hacia la sede del evento y el hotel en días incluidos con su compra',
      updateSearch: 'Actualizar Búsqueda',
      userReviews: 'Reseñas de Usuarios',
      yourPackage: 'Su Paquete',
    }
  },
  es() {
    return {
      accept: 'Aceptar\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Es posible que se apliquen impuestos y tasas adicionales durante la salida y el registro en el hotel. Transporte no incluido.',
      addHotel: 'ATENCIÓN: Debes añadir la estancia en el hotel a tu carrito para continuar con el proceso de compra',
      amenities: 'Servicios',
      airportShuttle: 'Transporte al Aeropuerto',
      back: 'Volver al paquete',
      breakfast: 'Desayuno Complementario',
      checkIn: 'Registrarse',
      checkOut: 'Salida',
      checkoutStep: 'Comprobar',
      chooseAccomodation: 'Elige Tus Alojamientos',
      chooseAccomodationDescription: 'A continuación se muestra una lista de ofertas de hoteles a través de {brand_name}. Si no necesitas un hotel, ',
      chooseOptionalAccomodation: 'Elige Tu Alojamiento Opcional',
      chooseRoom: 'Elegir Habitación',
      chooseARoom: 'Elegir Una Habitación',
      clickHereToContinue: 'A continuación se muestra una lista de ofertas de hoteles a través de %{brand_name}. Si no necesitas un hotel, ',
      continueToCheckout: 'Sí, Continúa con la Verificación',
      contractedTotal: 'para {checkIn} ~ {checkOut}',
      cugAccountLink: '¿Has comprado un hotel a través de nuestro portal de reservas? Llámanos al 1.512.691.9555 para cualquier pregunta relacionada con tus pedidos anteriores.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destino',
      destinationPlaceholder: 'Ciudad, Estado, País',
      distance: 'Distancia',
      exclusiveHotel: 'Hotel Exclusivo',
      exclusiveHotelFinePrint: 'Incluye impuestos, tasas y {quantity}estancia en la noche',
      exclusiveRates: 'TARIFAS EXCLUSIVAS',
      featured: 'DESTACADO',
      featuredWithTransportation: 'DESTACADO: CON TRANSPORTE',
      filterBy: 'Filtrar Por',
      fitness: 'Centro de Fitness',
      hotelOfferings: 'A continuación se muestra una lista de ofertas de hoteles a través de {brand_name}.',
      imageDisclaimer: 'Es responsabilidad de la agencia inmobiliaria y/o de la propiedad individual asegurar la exactitud de las fotos mostradas. No nos hacemos responsables de las inexactitudes de las fotos.',
      internet: 'Acceso a Internet',
      kitchen: 'Cocina/Cocinilla',
      listView: 'Ver Lista',
      mapView: 'Ver Mapa',
      nights: 'noches',
      noHotelProceed: '¿No necesitas un hotel? Pagar',
      note: 'NOTA:',
      nowChooseAccomodation: 'Ahora, elige tu alojamiento',
      otherHotels: 'Otras Ofertas de Hoteles',
      packageSuccess: '¡Éxito! {package_name} ha sido añadido a tu carrito.',
      perNight: 'por noche',
      pets: 'Se Admiten Mascotas',
      pool: 'Piscina',
      price: 'Precio',
      priceChange: 'Ha habido un cambio de precio',
      proceedToCart: 'Sí, ir al carrito',
      proceedToCheckout: 'Sí, pasa al pago',
      propertyDescription: 'Descripción de la Propiedad',
      reject: 'Rechazar',
      restaurant: 'Restaurante En El Lugar',
      retailTotal: 'para {quantity} noches',
      reviews: 'Reseñas',
      room: 'Habitación',
      Rooms: 'Habitaciones',
      roomNum: '# de habitaciones',
      rooms: 'Habitaciones',
      roomRemaining: 'Sólo {quantity} habitación restante',
      roomsRemaining: 'Sólo {quantity} habitaciones restantes',
      roomTotal: 'para {quantity} noches',
      roomcashTooltipText: 'Canjea tu RoomCash para recibir una tarifa nocturna con descuento en tu habitación de hotel. El importe de tu descuento se deducirá de tu saldo de RoomCash. Recuerda que el RoomCash no puede canjearse por un importe superior al descuento disponible por noche.',
      saveAmtRoomcash: 'ahorrar {amt} por noche',
      seeAllProperties: 'Ver todas las propiedades',
      seeOtherOptions: 'No, ver otras opciones',
      selectRoom: 'Seleccionar Habitación',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Mostrar Hoteles Adicionales',
      showLess: 'Mostrar Menos',
      showMore: 'Mostrar Más',
      somethingDifferent: '¿Buscas algo diferente? Tenemos más hoteles disponibles.',
      sortBy: 'Ordenar Por',
      sortFilter: 'Ordenar/Filtrar',
      starRating: 'Clasificación por Estrellas',
      startsAt: 'Empieza en',
      stars: 'estrellas',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'para acompañar tu entrada',
      toFromHotel: 'Transporte único de ida y vuelta al lugar del evento y al hotel en los días incluidos en la compra',
      updateSearch: 'Actualizar Búsqueda',
      userReviews: 'Reseñas de Usuarios',
      yourPackage: 'Tu Paquete',
    }
  },
  fr() {
    return {
      accept: 'Accepter\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Des taxes et frais supplémentaires peuvent s\'appliquer au cours du paiement et au lors de l\'enregistrement à l\'hôtel. Le transport n\'est pas inclus.',
      addHotel: 'À NOTER : Vous devez ajouter un séjour en hôtel à votre panier pour pouvoir procéder à la finalisation de la commande',
      amenities: 'Aménagements',
      airportShuttle: 'Navette aéroport',
      back: 'Retour à la page du forfait',
      breakfast: 'Petit déjeuner inclus',
      checkIn: 'Arrivée',
      checkOut: 'Départ',
      checkoutStep: 'Procéder au paiement',
      chooseAccomodation: 'Choisissez votre hébergement.',
      chooseAccomodationDescription: 'Vous trouverez ci-dessous une liste d\'offres d\'hôtels par {brand_name}. Si vous n\'avez pas besoin d\'un hôtel,',
      chooseOptionalAccomodation: 'Choisissez vos prestations de séjour en option',
      chooseRoom: 'Choisissez votre chambre',
      chooseARoom: 'Choisissez une chambre',
      clickHereToContinue: 'Vous trouverez ci-dessous une liste d\'offres d\'hôtels via %{brand_name}. Si vous n\'avez pas besoin d\'un hôtel, ',
      continueToCheckout: 'Oui, continuer vers le paiement',
      contractedTotal: 'pour {checkIn} ~ {checkOut}',
      cugAccountLink: 'Vous avez réservé votre hôtel par le biais de notre portail de réservation ? Appelez-nous au 1 512 691 9555 si vous avez des questions liées à vos commandes précédentes.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destination',
      destinationPlaceholder: 'Ville, État, Pays',
      distance: 'Distance',
      exclusiveHotel: 'Hôtel exclusif',
      exclusiveHotelFinePrint: 'Taxes, frais, et séjour de {quantity} nuitées inclus',
      exclusiveRates: 'TARIFS EXCLUSIFS',
      featured: 'À L\'AFFICHE',
      featuredWithTransportation: 'À L\'AFFICHE : AVEC NAVETTE',
      filterBy: 'Filtrer par',
      fitness: 'Centre de fitness',
      hotelOfferings: 'Vous trouverez ci-dessous une liste d\'offres hôtelières par {brand_name}.',
      imageDisclaimer: 'Il incombe à la chaîne et/ou au particulier propriétaire(s) de l\'établissement d\'assurer l\'exactitude des photos affichées. Nous ne sommes pas responsables de toute inexactitude apparaissant sur les photos.',
      internet: 'Accès Internet',
      kitchen: 'Cuisine/Kitchenette',
      listView: 'Liste',
      mapView: 'Carte',
      nights: 'nuits',
      noHotelProceed: 'Pas besoin d\'un hôtel ? Paiement',
      note: 'À NOTER :',
      nowChooseAccomodation: 'Maintenant, choisissez votre logement',
      otherHotels: 'Autres offres d\'hôtels',
      packageSuccess: 'Succès ! {package_name} a été ajouté à votre panier.',
      perNight: 'par nuitée',
      pets: 'Animaux de compagnie acceptés',
      pool: 'Piscine',
      price: 'Tarif',
      priceChange: 'Un prix a été modifié',
      proceedToCart: 'Oui, aller au panier',
      proceedToCheckout: 'Oui, accéder au paiement',
      propertyDescription: 'Description de la propriété',
      reject: 'Refuser',
      restaurant: 'Restaurant sur place',
      retailTotal: 'pour {quantity} nuits',
      reviews: 'Avis',
      room: 'Chambre',
      Rooms: 'Chambres',
      roomNum: 'Nombre de chambre(s)',
      rooms: 'Chambres',
      roomRemaining: 'Seulement {quantity} chambre restante',
      roomsRemaining: 'Seulement {quantity} chambres restantes',
      roomTotal: 'pour {quantity} nuits',
      roomcashTooltipText: 'Récupérez votre RoomCash pour recevoir une réduction sur les nuitées en hôtel. Le montant de votre remise sera déduit de votre réserve de RoomCash. À noter : le RoomCash ne peut pas être utilisé au-delà du montant maximum de réduction disponible par nuitée.',
      saveAmtRoomcash: 'économisez {amt} par nuitée',
      seeAllProperties: 'Voir toutes les propriétés',
      seeOtherOptions: 'Non, voir d\'autres options',
      selectRoom: 'Choisir une chambre',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Afficher plus d\'hôtels',
      showLess: 'Afficher moins',
      showMore: 'Afficher plus',
      somethingDifferent: 'Vous cherchez quelque chose de différent ? Nous avons plus d\'hôtels disponibles.',
      sortBy: 'Trier par',
      sortFilter: 'Trier/Filtrer',
      starRating: 'Nombre d\'étoiles',
      startsAt: 'Commence à',
      stars: 'étoiles',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'pour accompagner votre billet',
      toFromHotel: 'Une navette comprenant un aller-retour entre l\'hôtel au lieu de l\'événement lors des journées incluses dans l\'achat',
      updateSearch: 'Mettre à jour la recherche',
      userReviews: 'Avis des utilisateurs',
      yourPackage: 'Votre forfait',
    }
  },
  it() {
    return {
      accept: 'Accetta\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Potrebbero essere applicate delle imposte e delle commissioni aggiuntive al momento del check-out e del check-in in albergo. Trasferimenti non inclusi.',
      addHotel: 'NOTA BENE: È necessario aggiungere un soggiorno alberghiero prima di poter procedere con l\'acquisto',
      amenities: 'Servizi e attrattive',
      airportShuttle: 'Navetta da/per l\'aeroporto',
      back: 'Ritorna al pacchetto',
      breakfast: 'Colazione inclusa',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'Cassa',
      chooseAccomodation: 'Scegli la sistemazione',
      chooseAccomodationDescription: 'Di seguito troverai un elenco di alberghi offerti da {brand_name}. In caso non avessi bisogno di un albergo,',
      chooseOptionalAccomodation: 'Scegli la sistemazione opzionale',
      chooseRoom: 'Scegli la stanza',
      chooseARoom: 'Scegli una stanza',
      clickHereToContinue: 'Di seguito troverai un elenco di alberghi offerti da %{brand_name}. In caso non avessi bisogno di un albergo,',
      continueToCheckout: 'Sì, vai alla cassa',
      contractedTotal: 'per {checkIn} ~ {checkOut}',
      cugAccountLink: 'Hai prenotato un albergo sul nostro portale online? Telefonaci al numero 1.512.691.9555 per qualsiasi domanda relativa agli ordini.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destinazione',
      destinationPlaceholder: 'Città, Stato/Regione, Paese',
      distance: 'Distanza',
      exclusiveHotel: 'Albergo esclusivo',
      exclusiveHotelFinePrint: 'Include imposte, commissioni e soggiorno di %(quantity) notti',
      exclusiveRates: 'PREZZI ESCLUSIVI',
      featured: 'IN EVIDENZA',
      featuredWithTransportation: 'IN EVIDENZA: CON TRASPORTO',
      filterBy: 'Filtra per',
      fitness: 'Centro fitness',
      hotelOfferings: 'Di seguito troverai un elenco di alberghi offerti da {brand_name}.',
      imageDisclaimer: 'È responsabilità della catena e/o della singola struttura garantire l\'accuratezza delle fotografie mostrate. Non possiamo essere ritenuti responsabili per eventuali inesattezze presenti nelle foto.',
      internet: 'Accesso ad Internet',
      kitchen: 'Cucina o angolo cottura',
      listView: 'Elenco',
      mapView: 'Mappa',
      nights: 'notti',
      noHotelProceed: 'Non hai bisogno di un albergo? Cassa',
      note: 'NOTA BENE:',
      nowChooseAccomodation: 'Scegli il tipo di soggiorno alberghiero',
      otherHotels: 'Altre offerte alberghiere',
      packageSuccess: 'Vittoria! {package_name} è stato aggiunto al carrello.',
      perNight: 'per notte',
      pets: 'Animali ammessi',
      pool: 'Piscina',
      price: 'Prezzo',
      priceChange: 'Si è verificato un cambiamento di prezzo',
      proceedToCart: 'Sì, vai al carrello',
      proceedToCheckout: 'Sì, vai alla cassa',
      propertyDescription: 'Descrizione della struttura',
      reject: 'Rifiuta',
      restaurant: 'Ristorante sul posto',
      retailTotal: 'per {quantity} notti',
      reviews: 'Dicono di noi',
      room: 'Stanza',
      Rooms: 'Stanze',
      roomNum: '# di stanze',
      rooms: 'Stanze',
      roomRemaining: 'Resta soltanto {quantity} stanza',
      roomsRemaining: 'Restano soltanto {quantity} stanze',
      roomTotal: 'per {quantity} notti',
      roomcashTooltipText: 'Riscatta i RoomCash come sconto sulle tariffe giornaliere della tua camera d\'albergo. Ti preghiamo di notare che i RoomCash non possono essere riscattati per un importo superiore a quello disponibile per l\'acquisto di una notte.',
      saveAmtRoomcash: 'risparmia {amt} a notte',
      seeAllProperties: 'Vedi tutte le strutture',
      seeOtherOptions: 'No, visualizza altre opzioni',
      selectRoom: 'Scegli stanza',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Mostra ulteriori alberghi',
      showLess: 'Mostra di meno',
      showMore: 'Mostra di più',
      somethingDifferent: 'Cerchi qualcosa di diverso? Abbiamo ulteriori alberghi a disposizione.',
      sortBy: 'Ordina per',
      sortFilter: 'Ordina/Filtra',
      starRating: 'Stelle',
      startsAt: 'Prezzo da',
      stars: 'stelle',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: 'totale',
      toAccompanyTicket: 'da accoppiare al tuo biglietto',
      toFromHotel: 'Un solo trasporto giornaliero di andata e ritorno tra la location dell\'evento e l\'albergo è incluso nel prezzo di acquisto',
      updateSearch: 'Aggiorna ricerca',
      userReviews: 'Recensioni degli utenti',
      yourPackage: 'Il tuo pacchetto',
    }
  },
  ja() {
    return {
      accept: 'Accept\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'ホテルでのチェックアウト時およびチェックイン時に、追加の税金と手数料が適用される場合があります。交通費は含まれていません。',
      addHotel: '注意：購入を続行するには、カートにホテル滞在を追加する必要があります',
      amenities: 'アメニティ',
      airportShuttle: '空港シャトル',
      back: 'パッケージへ戻る',
      breakfast: '無料の朝食',
      checkIn: 'チェックイン',
      checkOut: 'チェックアウト',
      checkoutStep: 'レジ',
      chooseAccomodation: '宿泊施設を選ぶ',
      chooseAccomodationDescription: '以下は、{brand_name} が提供するホテルのリストです。ホテルが必要ない場合は、',
      chooseOptionalAccomodation: 'オプションの宿泊施設を選択してください',
      chooseRoom: '部屋を選ぶ',
      chooseARoom: '部屋を選ぶ',
      clickHereToContinue: '以下は、%{brand_name}を通じて提供されるホテルのリストです。ホテルが必要ない場合は',
      continueToCheckout: 'はい、チェックアウトを続行します',
      contractedTotal: '{checkIn} ~ {checkOut}の間',
      cugAccountLink: '予約ポータルからホテルを購入されましたか? 以前のご注文に関するご質問は、1.512.691.9555 までお電話ください。',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: '行き先',
      destinationPlaceholder: '市、州、国',
      distance: '距離',
      exclusiveHotel: '高級ホテル',
      exclusiveHotelFinePrint: '税金、手数料、そして{quantity}泊の宿泊費を含む',
      exclusiveRates: '特別料金',
      featured: '特徴',
      featuredWithTransportation: '特徴：輸送機関あり',
      filterBy: '検索条件',
      fitness: 'フィットネスセンター',
      hotelOfferings: '以下は、{brand_name}からのホテル提供品のリストです。',
      imageDisclaimer: '表示される写真の正確さは、個々の特質によって異なります。不確かな写真については責任を負いません。',
      internet: 'インターネットアクセス',
      kitchen: 'キッチン/簡易キッチン',
      listView: 'リストビュー',
      mapView: 'マップビュー',
      nights: '夜',
      noHotelProceed: 'ホテルは必要ありませんか？チェックアウト',
      note: '注意:',
      nowChooseAccomodation: '宿泊施設をお選びください',
      otherHotels: 'その他のホテル',
      packageSuccess: '成功です！ {package_name}がカートに追加されました。',
      perNight: '一泊あたり',
      pets: 'ペット可',
      pool: 'プール',
      price: '価格',
      priceChange: '価格が変更されました',
      proceedToCart: 'はい、カートに進みます',
      proceedToCheckout: 'はい、チェックアウトに進みます',
      propertyDescription: 'プロパティの説明',
      reject: '拒絶',
      restaurant: 'レストラン - 現地',
      retailTotal: '{quantity}泊用',
      reviews: 'レビュー',
      room: '部屋',
      Rooms: '部屋',
      roomNum: '# 部屋',
      rooms: '部屋',
      roomRemaining: '残り{quantity}部屋のみ',
      roomsRemaining: '残り {quantity}部屋のみ',
      roomTotal: '{quantity}泊用',
      roomcashTooltipText: 'RoomCash を引き換えて、ホテルの部屋の宿泊を割引料金で利用できます。割引額は、RoomCash の残高から差し引かれます。 RoomCash は、1 泊あたりの割引額を超えて引き換えることはできませんのでご注意ください。',
      saveAmtRoomcash: '一泊当たり {amt} 節約',
      seeAllProperties: '全プロパティを見る',
      seeOtherOptions: 'いいえ、他のオプションを見ます',
      selectRoom: '部屋を選ぶ',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: '追加ホテルを表示',
      showLess: '表示を減らす',
      showMore: '表示を増やす',
      somethingDifferent: '別のものをお探しですか？利用可能なホテルが他にもあります。',
      sortBy: '並び替え',
      sortFilter: '並べ替え/フィルター',
      starRating: '星評価',
      startsAt: '星',
      stars: '星',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: '合計',
      toAccompanyTicket: 'チケットを持って',
      toFromHotel: '購入日を含むイベント会場とホテルとの一度の往復送迎',
      updateSearch: '検索更新',
      userReviews: 'ユーザーレビュー',
      yourPackage: 'パッケージ',
    }
  },
  pl() {
    return {
      accept: 'Accept\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Additional taxes and fees may apply during checkout and upon check-in at the hotel. Transportation not included.',
      addHotel: 'PLEASE NOTE: You must add a hotel stay to your cart to continue checking out',
      amenities: 'Amenities',
      airportShuttle: 'Airport Shuttle',
      back: 'Back to package',
      breakfast: 'Complimentary Breakfast',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'przejdź do kasy',
      chooseAccomodation: 'Choose Your Accomodations',
      chooseAccomodationDescription: 'Below is a listing of hotel offerings through {brand_name}. If you don\'t need a hotel, ',
      chooseOptionalAccomodation: 'Choose Your Optional Accomodation',
      chooseRoom: 'Choose Room',
      chooseARoom: 'Choose A Room',
      clickHereToContinue: 'Below is a listing of hotel offerings through %{brand_name}. If you don\'t need a hotel, ',
      continueToCheckout: 'Yes, Continue to Checkout',
      contractedTotal: 'for {checkIn} ~ {checkOut}',
      cugAccountLink: 'Purchased a hotel through our booking portal? Give us a call at 1.512.691.9555 for any questions related to your previous orders.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destination',
      destinationPlaceholder: 'City, State, Country',
      distance: 'Distance',
      exclusiveHotel: 'Exclusive Hotel',
      exclusiveHotelFinePrint: 'Includes taxes, fees and {quantity}night stay',
      exclusiveRates: 'EXCLUSIVE RATES',
      featured: 'FEATURED',
      featuredWithTransportation: 'FEATURED: WITH TRANSPORTATION',
      filterBy: 'Filter By',
      fitness: 'Fitness Center',
      hotelOfferings: 'Below is a listing of hotel offerings through {brand_name}.',
      imageDisclaimer: 'It is the responsibility of the property chain and/or the individual property to ensure the accuracy of the photos displayed. We are not responsible for any inaccuracies in the photos.',
      internet: 'Internet Access',
      kitchen: 'Kitchen/Kitchenette',
      listView: 'List View',
      mapView: 'Map View',
      nights: 'nights',
      noHotelProceed: 'Don\'t need a hotel? Checkout',
      note: 'NOTE:',
      nowChooseAccomodation: 'Now, choose your accommodation',
      otherHotels: 'Other Hotel Offerings',
      packageSuccess: 'Success! {package_name} has been added to your cart.',
      perNight: 'per night',
      pets: 'Pets Allowed',
      pool: 'Pool',
      price: 'cena',
      priceChange: 'There has been a price change',
      proceedToCart: 'Yes, Proceed to Cart',
      proceedToCheckout: 'Yes, proceed to checkout',
      propertyDescription: 'Property Description',
      reject: 'Reject',
      restaurant: 'Restaurant-On-Site',
      retailTotal: 'for {quantity} nights',
      reviews: 'Reviews',
      room: 'Room',
      Rooms: 'Rooms',
      roomNum: '# of Rooms',
      rooms: 'Rooms',
      roomRemaining: 'Only {quantity} room remaining',
      roomsRemaining: 'Only {quantity} rooms remaining',
      roomTotal: 'for {quantity} nights',
      roomcashTooltipText: 'Redeem your RoomCash to receive a discounted nightly rate on your hotel room. The amount of your discount will be deducted from your RoomCash balance. Please note, RoomCash may not be redeemed for more than the available discount amount per night.',
      saveAmtRoomcash: 'save {amt} per night',
      seeAllProperties: 'See all properties',
      seeOtherOptions: 'No, see other options',
      selectRoom: 'Select Room',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Show Additional Hotels',
      showLess: 'Show Less',
      showMore: 'Show More',
      somethingDifferent: 'Looking for something different? We have more hotels available.',
      sortBy: 'Sort By',
      sortFilter: 'Sort/Filter',
      starRating: 'Star Rating',
      startsAt: 'Starts at',
      stars: 'stars',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'to accompany your ticket',
      toFromHotel: 'One-time transportation to and from the event venue and hotel on days included with purchase',
      updateSearch: 'Update Search',
      userReviews: 'User Reviews',
      yourPackage: 'Your Package',
    }
  },
  po() {
    return {
      accept: 'Accept\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: 'Additional taxes and fees may apply during checkout and upon check-in at the hotel. Transportation not included.',
      addHotel: 'PLEASE NOTE: You must add a hotel stay to your cart to continue checking out',
      amenities: 'Amenities',
      airportShuttle: 'Airport Shuttle',
      back: 'Back to package',
      breakfast: 'Complimentary Breakfast',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      checkoutStep: 'Checkout',
      chooseAccomodation: 'Choose Your Accommodations',
      chooseAccomodationDescription: 'Below is a listing of hotel offerings through {brand_name}. If you don\'t need a hotel, ',
      chooseOptionalAccomodation: 'Choose Your Optional Accommodation',
      chooseRoom: 'Choose Room',
      chooseARoom: 'Choose A Room',
      clickHereToContinue: 'Below is a listing of hotel offerings through %{brand_name}. If you don\'t need a hotel, ',
      continueToCheckout: 'Yes, Continue to Checkout',
      contractedTotal: 'for {checkIn} ~ {checkOut}',
      cugAccountLink: 'Purchased a hotel through our booking portal? Give us a call at 1.512.691.9555 for any questions related to your previous orders.',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: 'Destination',
      destinationPlaceholder: 'City, State, Country',
      distance: 'Distance',
      exclusiveHotel: 'Exclusive Hotel',
      exclusiveHotelFinePrint: 'Includes taxes, fees and {quantity}night stay',
      exclusiveRates: 'EXCLUSIVE RATES',
      featured: 'FEATURED',
      featuredWithTransportation: 'FEATURED: WITH TRANSPORTATION',
      filterBy: 'Filter By',
      fitness: 'Fitness Center',
      hotelOfferings: 'Below is a listing of hotel offerings through {brand_name}.',
      imageDisclaimer: 'It is the responsibility of the property chain and/or the individual property to ensure the accuracy of the photos displayed. We are not responsible for any inaccuracies in the photos.',
      internet: 'Internet Access',
      kitchen: 'Kitchen/Kitchenette',
      listView: 'List View',
      mapView: 'Map View',
      nights: 'nights',
      noHotelProceed: 'Don\'t need a hotel? Checkout',
      note: 'NOTE:',
      nowChooseAccomodation: 'Now, choose your accommodation',
      otherHotels: 'Other Hotel Offerings',
      packageSuccess: 'Success! {package_name} has been added to your cart.',
      perNight: 'per night',
      pets: 'Pets Allowed',
      pool: 'Pool',
      price: 'Price',
      priceChange: 'There has been a price change',
      proceedToCart: 'Yes, proceed to cart',
      proceedToCheckout: 'Yes, proceed to checkout',
      propertyDescription: 'Property Description',
      reject: 'Reject',
      restaurant: 'Restaurant On-Site',
      retailTotal: 'for {quantity} nights',
      reviews: 'Reviews',
      room: 'Room',
      Rooms: 'Rooms',
      roomNum: '# of Rooms',
      rooms: 'Rooms',
      roomRemaining: 'Only {quantity} room remaining',
      roomsRemaining: 'Only {quantity} rooms remaining',
      roomTotal: 'for {quantity} nights',
      roomcashTooltipText: 'Redeem your RoomCash to receive a discounted nightly rate on your hotel room. The amount of your discount will be deducted from your RoomCash balance. Please note, RoomCash may not be redeemed for more than the available discount amount per night.',
      saveAmtRoomcash: 'save {amt} per night',
      seeAllProperties: 'See all properties',
      seeOtherOptions: 'No, see other options',
      selectRoom: 'Select Room',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: 'Show Additional Hotels',
      showLess: 'Show Less',
      showMore: 'Show More',
      somethingDifferent: 'Looking for something different? We have more hotels available.',
      sortBy: 'Sort By',
      sortFilter: 'Sort/Filter',
      starRating: 'Star Rating',
      startsAt: 'Starts at',
      stars: 'stars',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: ' total',
      toAccompanyTicket: 'to accompany your ticket',
      toFromHotel: 'One-time transportation to and from the event venue and hotel on days included with purchase',
      updateSearch: 'Update Search',
      userReviews: 'User Reviews',
      yourPackage: 'Your Package',
    }
  },
  zhCN() {
    return {
      accept: '承兑\', // https://qe360.quintevents.com/admin/phrases/20122',
      additionalHotelDisclaimer: '在酒店办理入住和退房手续时可能需要支付额外的税费。不包含交通费。',
      addHotel: '请注意：您必须在购物车中添加酒店，才能继续付款',
      amenities: '便利设施/服务',
      airportShuttle: '机场接送',
      back: '返回套票',
      breakfast: '免费早餐',
      checkIn: '入住',
      checkOut: '退房',
      checkoutStep: '结账',
      chooseAccomodation: '选择您的住宿',
      chooseAccomodationDescription: '以下是通过{brand_name}提供的酒店清单。如果您不需要预订酒店，',
      chooseOptionalAccomodation: '选择您的可选酒店',
      chooseRoom: '选择房间',
      chooseARoom: '选择一个房间',
      clickHereToContinue: '以下是通过%{brand_name}提供的酒店清单。如果您不需要预订酒店，',
      continueToCheckout: '是的，继续结帐',
      contractedTotal: '针对{checkIn} ~ {checkOut}',
      cugAccountLink: '已通过我们的预订门户预订了酒店？有关您之前订单相关的任何问题，请拨打1.512.691.9555与我们联系。',
      customizeYourPackage: 'Customise Your {brandName}Package',
      destination: '目的地',
      destinationPlaceholder: '市，州，国家',
      distance: '距离',
      exclusiveHotel: '专属酒店',
      exclusiveHotelFinePrint: '包含税费和{quantity}晚住宿',
      exclusiveRates: '独家价格',
      featured: '精选',
      featuredWithTransportation: '精选：有交通服务',
      filterBy: '筛选条件',
      fitness: '健身房',
      hotelOfferings: '以下是{brand_name}提供的酒店列表。',
      imageDisclaimer: '所展示照片的精确性由属性链和/或单个属性决定，我们对照片中的任何错误概不负责。',
      internet: '互联网接入',
      kitchen: '厨房/小厨房',
      listView: '列表视图',
      mapView: '查看地图',
      nights: '晚',
      noHotelProceed: '不需要预订酒店？结账',
      note: '注意：',
      nowChooseAccomodation: '现在，请选择您的酒店',
      otherHotels: '其他酒店产品',
      packageSuccess: '成功！{package_name}已添加到您的购物车。',
      perNight: '每晚',
      pets: '允许携带宠物',
      pool: '游泳池',
      price: '价钱',
      priceChange: '价格有变动',
      proceedToCart: '是的，前往购物车',
      proceedToCheckout: '是的，请继续结算',
      propertyDescription: '酒店介绍',
      reject: '拒绝',
      restaurant: '内部餐厅',
      retailTotal: '{quantity} 晚',
      reviews: '评论',
      room: '房间',
      Rooms: '房间',
      roomNum: '房间数',
      rooms: '房间',
      roomRemaining: '仅剩{quantity}间客房',
      roomsRemaining: '仅剩{quantity}间客房',
      roomTotal: '{quantity} 晚',
      roomcashTooltipText: '兑换您的RoomCash积分即可享受酒店客房每晚折扣价。您的折扣金额将从您的RoomCash余额中扣除。请注意，RoomCash兑换的价格不得超过每晚的折扣金额。',
      saveAmtRoomcash: '每晚省{amt}',
      seeAllProperties: '查看所有酒店',
      seeOtherOptions: '否，查看其他选项',
      selectRoom: '选择房间',
      selectEvent: '1. Select Your {brandName}Event',
      selectTickets: '2. Select Your {brandName}Tickets',
      selectHotel: '3. Select Your Hotel',
      showAdditionalHotels: '显示更多酒店',
      showLess: '显示较少',
      showMore: '显示更多',
      somethingDifferent: '想寻求与以往不同的尝试？ 我们还有更多酒店可供选择。',
      sortBy: '检索结果排序',
      sortFilter: '分类/过滤',
      starRating: '星级',
      startsAt: '始于',
      stars: '星级',
      stepOne: 'Choose your desired {race} to see available ticket options.',
      stepTwo: 'Secure the perfect seat with excellent views of the {brandName}action.',
      stepThree: 'Find a comfortable and convenient hotel to relax & recharge.',
      tbd: '总计',
      toAccompanyTicket: '与门票配套',
      toFromHotel: '包含购买当日往返于活动场地和酒店之间的单次交通',
      updateSearch: '更新搜索',
      userReviews: '用户评论',
      yourPackage: '您的套票',
    }
  },
}
