const namespaced = true

const state = {
  dynamicProtectGroupEnabled: false,
  premiumAmount: 0,
  decoratedPremium: '$0'
}

const mutations = {
  updatePremium(state, amount) {
    state.premiumAmount = amount
  },
  updateDecoratedPremium(state, formatted_amount) {
    state.decoratedPremium = formatted_amount
  },
  updateDynamicProtectGroupEnabled(state, enabled) {
    state.dynamicProtectGroupEnabled = enabled
  },
}

export default {
  namespaced,
  state,
  mutations
}