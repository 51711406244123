import CartApi from '../../../shared/cart/cart_api'
const namespaced = true

const state = {
  order: {},
  cart: {},
  loading: false,
  loadingTerms: false,
  currencies: [],
  currentCurrency: 'USD',
  exchangeRate: 1.0
}

const mutations = {
  updateOrder(state, order) { // Namespaced
    state.order = order
  },
  updateCurrencies(state, currencies) { // Namespaced
    state.currencies = currencies
  },
  updateCurrentCurrency(state, currency) { // Namespaced
    state.currentCurrency = currency
  },
  updateExchangeRate(state, rate) { // Namespaced
    state.exchangeRate = rate
  },
  updateCart(state, cart) { // Namespaced
    state.cart = cart
  },
  updateLoading(state, boolean){
    state.loading = boolean
  },
  updateLoadingTerms(state, boolean){
    state.loadingTerms = boolean
  }
}

const getters = {
  getCurrentOrder: (state) => {
    return state.order
  },
  getCurrencies: (state) => {
    return state.currencies
  },
  emptyOrder: (state) => {
    return (state.order && Object.keys(state.order).length === 0 &&
    state.order.constructor === Object)
  }
}

const actions = {
  async getBackendOrder({ commit }){
    commit('updateLoading', true )
    let order = await CartApi.getOrder()

    if(Object.keys(order).includes('active_discounts') ||
      Object.keys(order).includes('cart_version') ||
      Object.keys(order).includes('h4h_program')){
      commit('updateCart', {
        active_discounts: order.active_discounts,
        cart_version: order.cart_version,
        h4h_program: order.h4h_program
      })
      delete order.cart_version
      delete order.active_discounts
      delete order.h4h_program
    }
    commit('updateOrder', order )


    let nav = document.getElementsByClassName('badge-number')[0]

    let subNav = document.getElementById('subNavBadge')
    if (order.item_count && nav) {
      Array.from(nav).forEach(element => {
        element.innerText = order.item_count
      })
      if(nav){nav.innerHTML = order.item_count}
      if(subNav) {subNav.innerHTML = order.item_count}
    } else {
      if(nav){nav.innerHTML = ''}
      if(subNav) {subNav.innerHTML = ''}
    }
    commit('updateLoading', false )
    return order
  }
}

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions
}
