export default {
  en() {
    return {
      orderDetails: 'Order Details',
      phone: 'Phone',
      eventName: 'Event',
      item: 'Item',
      quantity: 'Quantity',
      reservationName: 'Reservation Name',
      subtotal: 'Subtotal',
      processingFee: 'Processing Fee',
      currency: 'Currency',
      total: 'Total',
      totalAfterCredits: 'Total After Credits',
      creditsUsed: 'Credits Used',
      roomType: 'Room Type',
    }
  },
  ar() {
    return {
      orderDetails: 'تفاصيل الطلب',
      phone: 'الهاتف',
      eventName: 'الحدث/الفعاليّة',
      item: 'عنصر',
      quantity: 'الكميّة',
      reservationName: 'الاسم الّذي تمّت به عمليّة الحجز',
      subtotal: 'المجموع الفرعيّ',
      processingFee: 'كلفة معالجة المسألة',
      currency: 'العملة',
      total: 'المجموع',
      totalAfterCredits: 'الاعتمادات المستخدمة',
      creditsUsed: 'الأرصدة الدائنة',
      roomType: 'نوع الغرفة',
    }
  },
  de() {
    return {
      orderDetails: 'Bestelldaten',
      phone: 'Telefon',
      eventName: 'Veranstaltung',
      item: 'Artikel',
      quantity: 'Anzahl',
      reservationName: 'Reservierungsname',
      subtotal: 'Zwischentotal',
      processingFee: 'Bearbeitungsgebühr',
      currency: 'Währung',
      total: 'Gesamtbetrag',
      totalAfterCredits: 'Gesamtbetrag nach Guthaben',
      creditsUsed: 'Verwendetes Guthaben',
      roomType: 'Zimmertyp',
    }
  },
  enGB() {
    return {
      orderDetails: 'Order Details',
      phone: 'Phone',
      eventName: 'Event',
      item: 'Item',
      quantity: 'Quantity',
      reservationName: 'Reservation Name',
      subtotal: 'Subtotal',
      processingFee: 'Processing Fee',
      currency: 'Currency',
      total: 'Total',
      totalAfterCredits: 'Total After Credits',
      creditsUsed: 'Credits Used',
      roomType: 'Room Type',
    }
  },
  esMX() {
    return {
      orderDetails: 'Detalles de la Orden',
      phone: 'Teléfono',
      eventName: 'Evento',
      item: 'Artículo',
      quantity: 'Cantidad',
      reservationName: 'Nombre en la reservación',
      subtotal: 'Subtotal',
      processingFee: 'Tarifa de Procesamiento',
      currency: 'Moneda',
      total: 'Total',
      totalAfterCredits: 'Créditos Totales Finales',
      creditsUsed: 'Créditos Utilizados',
      roomType: 'Tipo de Habitación',
    }
  },
  es() {
    return {
      orderDetails: 'Detalles del Pedido',
      phone: 'Teléfono',
      eventName: 'Evento',
      item: 'Artículo',
      quantity: 'Cantidad',
      reservationName: 'Nombre de la Reserva',
      subtotal: 'Subtotal',
      processingFee: 'Tasa de Tramitación',
      currency: 'Moneda',
      total: 'Total',
      totalAfterCredits: 'Total Después de Créditos',
      creditsUsed: 'Créditos Utilizados',
      roomType: 'Tipo de Habitación',
    }
  },
  fr() {
    return {
      orderDetails: 'Détails de la commande',
      phone: 'Téléphone',
      eventName: 'Événement',
      item: 'Article',
      quantity: 'Quantité',
      reservationName: 'Nom de la réservation',
      subtotal: 'Sous-total',
      processingFee: 'Frais de traitement',
      currency: 'Devise',
      total: 'Total',
      totalAfterCredits: 'Total après avoirs',
      creditsUsed: 'Avoirs utilisés',
      roomType: 'Type de chambre',
    }
  },
  it() {
    return {
      orderDetails: 'Dettagli dell\'ordine',
      phone: 'Telefono',
      eventName: 'Evento',
      item: 'Articolo',
      quantity: 'Quantità',
      reservationName: 'Nome della prenotazione',
      subtotal: 'Totale parziale',
      processingFee: 'Spese di commissione',
      currency: 'Valuta',
      total: 'Totale',
      totalAfterCredits: 'Totale dopo l\'uso dei crediti:',
      creditsUsed: 'Crediti usati',
      roomType: 'Tipo di stanza',
    }
  },
  ja() {
    return {
      orderDetails: 'ご注文詳細',
      phone: '電話番号',
      eventName: 'イベント',
      item: '項目',
      quantity: '数量',
      reservationName: '予約名',
      subtotal: '小計',
      processingFee: '手数料',
      currency: '通貨',
      total: 'ご請求額',
      totalAfterCredits: 'クレジット後の合計',
      creditsUsed: '使用されるクレジット',
      roomType: 'ルームタイプ',
    }
  },
  pl() {
    return {
      orderDetails: 'szczegóły zamówienia                                                                                                     ',
      phone: 'telefon',
      eventName: 'impreza',
      item: 'artykuł',
      quantity: 'ilość',
      reservationName: 'Reservation Name',
      subtotal: 'suma',
      processingFee: 'opłata manipulacyjna',
      currency: 'waluta',
      total: 'ogółem',
      totalAfterCredits: 'Total After Credits',
      creditsUsed: 'Credits Used',
      roomType: 'rodzaj pokoju',
    }
  },
  po() {
    return {
      orderDetails: 'Order Details',
      phone: 'Phone',
      eventName: 'Event',
      item: 'Item',
      quantity: 'Quantity',
      reservationName: 'Reservation Name',
      subtotal: 'Subtotal',
      processingFee: 'Processing Fee',
      currency: 'Currency',
      total: 'Total',
      totalAfterCredits: 'Total After Credits',
      creditsUsed: 'Credits Used',
      roomType: 'Room Type',
    }
  },
  zhCN() {
    return {
      orderDetails: '订单详情',
      phone: '手机',
      eventName: '赛事',
      item: '一项',
      quantity: '数量',
      reservationName: '预订姓名',
      subtotal: '小计',
      processingFee: '处理费',
      currency: '货币',
      total: '总计',
      totalAfterCredits: '积分抵扣后总费用',
      creditsUsed: '已用积分',
      roomType: '房间类型',
    }
  },
}
