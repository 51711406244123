export default {

  en() {
    return {
      item: 'Item:',
      order_details: 'Order Details',
      phone_number: 'Phone Number:',
      quantity: 'Quantity:',
      total: 'Total:'
    }
  },

  ar() {
    return {
      item: ':المنتج',
      order_details: 'تفاصيل الطلب',
      phone_number: ':رقم الهاتف',
      quantity: ':عدد الغرف',
      total: ':المجموع',
    }
  },
  de() {
    return {
      item: 'Artikel:',
      order_details: 'Bestelldaten',
      phone_number: 'Telefonnummer:',
      quantity: 'Quantity:',
      total: 'Total:',
    }
  },
  enGB() {
    return {
      item: 'Item:',
      order_details: 'Order Details',
      phone_number: 'Phone Number:',
      quantity: 'Quantity:',
      total: 'Total:',
    }
  },
  esMX() {
    return {
      item: 'Artículo:',
      order_details: 'Detalles de la Orden',
      phone_number: 'Número de teléfono:',
      quantity: 'Cantidad:',
      total: 'Total:',
    }
  },
  es() {
    return {
      item: 'Artículo:',
      order_details: 'Detalles del Pedido',
      phone_number: 'Número de Teléfono:',
      quantity: 'Cantidad:',
      total: 'Total:',
    }
  },
  fr() {
    return {
      item: 'Article :',
      order_details: 'Détails de la commande',
      phone_number: 'Numéro de téléphone :',
      quantity: 'Quantité :',
      total: 'Total :',
    }
  },
  it() {
    return {
      item: 'Articolo:',
      order_details: 'Dettagli dell\'ordine',
      phone_number: 'NUMERO DI TELEFONO',
      quantity: 'Quantità:',
      total: 'Totale:',
    }
  },
  ja() {
    return {
      item: 'アイテム：',
      order_details: 'ご注文詳細',
      phone_number: '電話番号:',
      quantity: '数量:',
      total: '合計：',
    }
  },
  pl() {
    return {
      item: 'pozycja:',
      order_details: 'szczegóły zamówienia                                                                                                     ',
      phone_number: 'Phone Number:',
      quantity: 'ilość:',
      total: 'ogółem:',
    }
  },
  po() {
    return {
      item: 'Item:',
      order_details: 'Order Details',
      phone_number: 'Phone Number:',
      quantity: 'Quantity:',
      total: 'Total:',
    }
  },
  zhCN() {
    return {
      item: '项目：',
      order_details: '订单详情',
      phone_number: '电话号码：',
      quantity: '数量：',
      total: '总计：',
    }
  },
}
