export default {
  en() {
    return {
      confirmation_deadline:'<strong>Important!</strong> In order to finalize your hotel stay, please fill out the information below by {date}. If This information is not filled out by {date}, your room will be forfeited.',
      confirmation_deadlineLocation: 'This information can be found on your order details page as well.',
      confirmation_hotelDetails: 'Hotel Details',
      deadline: '<strong>Important:</strong> Information must be received no later than {date} or your room will be forfeited.',
      details: 'Hotel Reservation Details',
      finalize: 'To finalize your hotel stay, please provide the information below.',
      numAdults: 'Number of Adults:',
      requiredDetails: 'Required Hotel Reservation Details',
      resName: 'Name for Reservation:',
      roomNum: 'Room {number}:',
      save: 'Save',
      updateFailed: 'We could not update you hotel information. Please contact us if the problem continues.'
    }
  },
  ar() {
    return {
      confirmation_deadline: '<strong>هامّ</strong> من أجل إنهاء إقامتك في الفندق، يُرْجى إدخال البيانات أدناه بحلول {date}. إذا لم يتمّ ملء هذه المعلومات بحلول {date}، فسوف تفقد حجز غرفتك',
      confirmation_deadlineLocation: 'يمكن العثور على هذه المعلومات في صفحة تفاصيل الطلب أيضًا',
      confirmation_hotelDetails: 'يمكن العثور على هذه المعلومات في صفحة تفاصيل الطلب أيضًا',
      deadline: '<strong>:هامّ</strong> .يجب أن يتمّ استلام المعلومات/البيانات في موعد لا يتجاوز {date} وإلاّ ستفقد حجزك للغرفة',
      details: 'تفاصيل حجز الفندق',
      finalize: '.لوضع اللمسات الأخيرة على حجز إقامتك في الفندق، يُرْجى تقديم المعلومات أدناه',
      numAdults: ':عدد الكبار',
      requiredDetails: 'البيانات المطلوبة لحجوزات الفنادق',
      resName: ':الاسم للحجز',
      roomNum: 'الغرفة: {number} ',
      save: 'حفظ',
      updateFailed: 'لم نتمكّن من تحديث معلومات الفندق الخاصّة بك. يُرْجى الاتصال بنا إذا استمرّ الإشكال',
    }
  },
  de() {
    return {
      confirmation_deadline: '<strong>Wichtig!</strong> Um die Buchung für Ihren Hotelaufenthalt zu vervollständigen, füllen Sie bitte die nachfolgenden Informationen bis zum {date}. Falls diese Informationen nicht bis zum {date} übermittelt werden, verfällt Ihre Zimmerreservierung.',
      confirmation_deadlineLocation: 'Diese Informationen finden Sie auch auf der Seite mit den Einzelheiten Ihrer Bestellung.',
      confirmation_hotelDetails: 'Hotelinformationen',
      deadline: '<strong>Wichtig:</strong> Die Informationen müssen spätestens am {date} eingehen, sonst verfällt Ihre Zimmerreservierung.',
      details: 'Details zur Hotelreservierung',
      finalize: 'Um Ihren Hotelaufenthalt abzuschließen, geben Sie bitte die untenstehenden Informationen an',
      numAdults: 'Anzahl Erwachsene:',
      requiredDetails: 'Erforderliche Hotelreservierungsdaten',
      resName: 'Name der Reservation:',
      roomNum: 'Zimmer {number}:',
      save: ' Save',
      updateFailed: 'Wir konnten Ihre Hotelinformationen nicht aktualisieren. Bitte kontaktieren Sie uns, falls das Problem andauert.',
    }
  },
  enGB() {
    return {
      confirmation_deadline: '<strong>Important!</strong> In order to finalize your hotel stay, please fill out the information below by {date}. If This information is not filled out by {date}, your room will be forfeited.',
      confirmation_deadlineLocation: 'This information can be found on your order details page as well.',
      confirmation_hotelDetails: 'Hotel Details',
      deadline: '<strong>Important:</strong> Information must be received no later than {date} or your room will be forfeited.',
      details: 'Hotel Reservation Details',
      finalize: 'To finalize your hotel stay, please provide the information below.',
      numAdults: 'Number of Adults:',
      requiredDetails: 'Required Hotel Reservation Details',
      resName: 'Name for Reservation:',
      roomNum: 'Room {number}:',
      save: 'Save',
      updateFailed: 'We could not update you hotel information. Please contact us if the problem continues.',
    }
  },
  esMX() {
    return {
      confirmation_deadline: '<strong>¡Importante!</strong> Para poder completar su reservación de hotel, por favor llene la información que se encuentra a continuación antes del {date}. Si no ha enviado la información para el {date}, la reserva no procederá.',
      confirmation_deadlineLocation: 'Esta información también se puede encontrar en su página de detalles del pedido. ',
      confirmation_hotelDetails: 'Detalles del Hotel',
      deadline: '<strong>Importante:</strong> La información debe ser recibida a más tardar el {date} o su reserva de habitación será cancelada.',
      details: 'Detalles de la Reserva del Hotel',
      finalize: 'Para finalizar su reserva de hospedaje, favor de proporcionar la información a continuación',
      numAdults: 'Número de adultos:',
      requiredDetails: 'Detalles Requeridos para la Reserva del Hotel',
      resName: 'Nombre para la Reservación:',
      roomNum: 'Habitación {number}:',
      save: ' Save',
      updateFailed: 'No podemos actualizar la información de su hotel. Favor de contactarnos si el problema persiste.',
    }
  },
  es() {
    return {
      confirmation_deadline: '<strong>¡Importante!</strong> Para ultimar tu estancia en el hotel, por favor, rellena la información que aparece a continuación {date}. Si Esta información no se rellena en {date}, tu habitación se perderá.',
      confirmation_deadlineLocation: 'Esta información también se puede encontrar en la página de detalles de tu pedido.',
      confirmation_hotelDetails: 'Detalles del Hotel',
      deadline: '<strong>Importante:</strong> La información debe recibirse no más tarde del {date} o tu habitación se perderá.',
      details: 'Detalles de la Reserva del Hotel',
      finalize: 'Para finalizar tu estancia en el hotel, por favor, proporciona la siguiente información.',
      numAdults: 'Número de Adultos:',
      requiredDetails: 'Detalles de la Reserva de Hotel Requeridos',
      resName: 'Nombre para la Reserva:',
      roomNum: 'Habitación {number}:',
      save: 'Guardar',
      updateFailed: 'No hemos podido actualizar la información de tu hotel. Por favor, contáctanos si el problema continúa.',
    }
  },
  fr() {
    return {
      confirmation_deadline: '<strong>Important !</strong> Afin de finaliser votre réservation d\'hôtel, veuillez renseigner les informations ci-dessous avant le {date}. Si ces informations ne nous sont pas parvenues avant le {date}, votre réservation sera annulée.',
      confirmation_deadlineLocation: 'Ces informations seront également affichées sur votre page de réservation.',
      confirmation_hotelDetails: 'Informations sur l\'hôtel',
      deadline: '<strong>Important :</strong> Ces informations doivent nous être fournies avant le {date}, ou votre réservation sera annulée.',
      details: 'Les détails de votre réservation d\'hôtel',
      finalize: 'Pour finaliser la réservation de votre hébergement en hôtel, veuillez renseigner les informations ci-dessous.',
      numAdults: 'Nombre d\'adultes :',
      requiredDetails: 'Informations requises pour la réservation d\'un hôtel',
      resName: 'Réservation au nom de :',
      roomNum: 'Chambre {number} :',
      save: ' Enregistrer',
      updateFailed: 'Nous n\'avons pas pu mettre à jour les informations concernant votre réservation d\'hôtel. Veuillez nous contactez si le problème persiste.',
    }
  },
  it() {
    return {
      confirmation_deadline: '<strong>IMPORTANTE!</strong> Per completare la prenotazione del tuo soggiorno in hotel, ti preghiamo di fornirci le informazioni riportate di seguito entro il {date}. Se le informazioni non ci verranno fornite entro il {date}, la prenotazione della camera verrà annullata.',
      confirmation_deadlineLocation: 'Queste informazioni sono disponibili anche nella pagina contenente i dettagli dell\'ordine.',
      confirmation_hotelDetails: 'Dettagli alberghieri',
      deadline: '<strong>Importante:</strong> le informazioni devono essere inviate entro e non oltre il {date} oppure la prenotazione della camera verrà annullata.',
      details: 'Dettagli per la prenotazione alberghiera',
      finalize: 'Per completare la prenotazione del tuo soggiorno in hotel, ti preghiamo di fornirci le informazioni riportate di seguito',
      numAdults: 'Numero di adulti:',
      requiredDetails: 'Dettagli necessari per la prenotazione alberghiera',
      resName: 'Nominativo della prenotazione:',
      roomNum: 'Camera {number}:',
      save: ' Salva',
      updateFailed: 'Impossibile aggiornare i tuoi dettagli alberghieri. Ti preghiamo di contattarci in caso il problema dovesse persistere.',
    }
  },
  ja() {
    return {
      confirmation_deadline: '<strong>Important!</strong> ホテルでの滞在を予約するには、{date}までに以下の情報を入力してください。この情報が{date}までに記入されない場合、部屋はキャンセルされます。',
      confirmation_deadlineLocation: 'この情報は、注文詳細ページでも同様に確認できます。',
      confirmation_hotelDetails: 'ホテル詳細',
      deadline: '<strong>重要：</ strong> {date} までに情報を受信する必要があります。そうしないと部屋はキャンセルされます。',
      details: 'ホテル予約の詳細',
      finalize: 'ホテルの予約を完了するには、以下の情報を提供してください',
      numAdults: '大人の数:',
      requiredDetails: '必要なホテル予約の詳細',
      resName: '予約名：',
      roomNum: '部屋 {number}:',
      save: ' セーブ',
      updateFailed: 'ホテル情報を更新できませんでした。問題が解決しない場合はご連絡ください',
    }
  },
  pl() {
    return {
      confirmation_deadline: '<strong>Uwaga!</strong> Aby dokończyć rezerwację hotelu, proszę podać poniższe dane najpóźniej do {date}. Jeśli nie otrzymamy danych do {date}, rezerwacja zostanie anulowana.',
      confirmation_deadlineLocation: 'Te informacje znajdują się również na stronie ze szczegółami zamówienia.',
      confirmation_hotelDetails: 'szczegóły dotyczące hotelu',
      deadline: '<strong>Uwaga:</strong> dane należy przekazać najpóźniej {date}; w przeciwnym razie rezerwacja zostanie anulowana.',
      details: 'szczegóły rezerwacji hotelowej',
      finalize: 'aby dokończyć rezerwację hotelu, proszę podać poniższe dane',
      numAdults: 'Number of Adults:',
      requiredDetails: 'dane, wymagane przy rezerwacji hotelu',
      resName: 'rezerwacja na nazwisko:',
      roomNum: 'pokój {number}:',
      save: 'zapisz',
      updateFailed: 'Nie udało nam się uaktualnić informacji o hotelu. Prosimy o kontakt, jeśli sytuacja się powtórzy.',
    }
  },
  po() {
    return {
      confirmation_deadline: '<strong>Important!</strong> In order to finalize your hotel stay, please fill out the information below by {date}. If This information is not filled out by {date}, your room will be forfeited.',
      confirmation_deadlineLocation: 'This information can be found on your order details page as well.',
      confirmation_hotelDetails: 'Hotel Details',
      deadline: '<strong>Important:</strong> Information must be received no later than {date} or your room will be forfeited.',
      details: 'Hotel Reservation Details',
      finalize: 'To finalize your hotel stay, please provide the information below.',
      numAdults: 'Number of Adults:',
      requiredDetails: 'Required Hotel Reservation Details',
      resName: 'Name for Reservation:',
      roomNum: 'Room {number}:',
      save: 'Save',
      updateFailed: 'We could not update you hotel information. Please contact us if the problem continues.',
    }
  },
  zhCN() {
    return {
      confirmation_deadline: '<strong>重要！</strong>请在{date}之前填写以下信息，以完成酒店住宿。如果未在{date}之前填写此信息，则视为自愿放弃您的房间。',
      confirmation_deadlineLocation: '您也可以在您的订单详细页面中找到此消息。',
      confirmation_hotelDetails: '酒店详情',
      deadline: '<strong>重要提示：</strong>请务必在{date}之前发送信息，否则视为自愿放弃您的房间。',
      details: '酒店预订详情',
      finalize: '请提供以下信息确定酒店住宿',
      numAdults: '成人人数：',
      requiredDetails: '酒店预订所需详细信息',
      resName: '预订者姓名：',
      roomNum: '房间 {number}：',
      save: '保存',
      updateFailed: '我们无法更新您的酒店信息。如果仍有问题，请与我们联系。',
    }
  },
}
