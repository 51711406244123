export default {

  en() {
    return {
      title: 'My Event Interests',
      description: 'Let us know which events you might be interested in that we offer. Don\'t forget to save your event selection when complete!',
      saveBtn: 'Save My Event Interest',
      confirmation: 'Thank you for letting us know what else you\'re interested in! We look forward to bringing you closer to the events you love.',
      subConfirm: 'Please be on the lookout for a confirmation email containing a discount code for you to use on your next order.',
    }
  },

  ar() {
    return {
      title: 'اهتماماتي أثناء الحدث',
      description: '!أخبرنا بالأحداث الّتي نقدّمها والّتي تنال اهتمامك. لا تنس حفظ اختيارك للحدث عند الانتهاء',
      saveBtn: 'احفظ اهتماماتي خلال الحدث',
      confirmation: 'أدخل رمز الهديّة عند تسجيل الخروج ضمن قسم " الدفع & التخفيضات "، يجب أن يكون تابعا لشركة جلوبال',
      subConfirm: 'يُرْجى البحث عن رسالة بريد إلكترونيّ للتأكيد تحتوي على رمز التخفيض لتستخدمه في طلبك التالي',
    }
  },
  de() {
    return {
      title: 'Meine Event-Interessen',
      description: 'Lassen Sie uns wissen, für welche unserer Veranstaltungen Sie sich interessieren. Vergessen Sie bitte nicht, Ihre Auswahl zu speichern!',
      saveBtn: 'Meine Event-Interessen speichern',
      confirmation: 'Vielen Dank für die Mitteilung, woran Sie sonst noch interessiert sind! Wir freuen uns darauf, Sie näher an Ihre Lieblingsveranstaltungen zu bringen.',
      subConfirm: 'Bitte achten Sie sich auf eine Bestätigungs-E-Mail mit einem Rabattcode, den Sie bei Ihrer nächsten Bestellung einlösen können.',
    }
  },
  enGB() {
    return {
      title: 'My Event Interests',
      description: 'Let us know which events you might be interested in that we offer. Don\'t forget to save your event selection when complete!',
      saveBtn: 'Save My Event Interest',
      confirmation: 'Thank you for letting us know what else you\'re interested in! We look forward to bringing you closer to the events you love.',
      subConfirm: 'Please be on the lookout for a confirmation email containing a discount code for you to use on your next order.',
    }
  },
  esMX() {
    return {
      title: 'Mis Eventos de Interés',
      description: 'Háganos saber qué eventos le podrían interesar. ¡No olvide guardar su selección de eventos cuando termine!',
      saveBtn: 'Guardar Mis Eventos de Interés',
      confirmation: '¡Gracias por hacernos saber qué más le interesa! Esperamos poder acercarlo a los eventos que más le gustan. ',
      subConfirm: 'Por favor esté atento a un correo electrónico de confirmación que contenga un código de descuento para usar en su próximo pedido.',
    }
  },
  es() {
    return {
      title: 'Mis Intereses del Evento',
      description: 'Haznos saber qué eventos que ofrecemos te pueden interesar. ¡No olvides guardar tu selección de eventos cuando la hayas completado!',
      saveBtn: 'Guardar Mi Interés del Evento',
      confirmation: '¡Gracias por hacernos saber qué más te interesa! Estamos deseando acercarte a los eventos que te gustan.',
      subConfirm: 'Por favor, estate atento a un correo electrónico de confirmación que contiene un código de descuento para que lo utilices en tu próximo pedido.',
    }
  },
  fr() {
    return {
      title: 'Intérêts pour Mon Événement',
      description: 'Dites-nous les événements qui vous intéressent parmi nos offres. N\'oubliez pas de sauvegarder votre sélection d\'événement une fois terminé !',
      saveBtn: 'Sauvegarder cet intérêt pour Mon Événement',
      confirmation: 'Merci de nous avoir indiqué le reste de vos intérêts ! Nous sommes impatients de pouvoir vous emmener au plus près des événements que vous appréciez.',
      subConfirm: 'Veuillez surveiller l\'arrivée d\'un courriel de confirmation contenant un code de réduction à utiliser sur votre prochaine commande.',
    }
  },
  it() {
    return {
      title: 'Eventi che mi interessano',
      description: 'Facci sapere a quali eventi potresti essere interessato tra quelli che offriamo. Non dimenticarti di salvare l\'evento che hai scelto alla fine!',
      saveBtn: 'Salva gli eventi che mi interessano',
      confirmation: 'Grazie per averci fatto sapere cosa t\'interessa! Non vediamo l\'ora di portarti a osservare ancora più da vicino gli eventi che ami.',
      subConfirm: 'Ti invitiamo a controllare la posta per trovare l\'email di conferma contenente un codice sconto da utilizzare durante il tuo prossimo ordine.',
    }
  },
  ja() {
    return {
      title: '興味のあるイベント',
      description: '提供するどのイベントに興味があるかをお知らせください。完了したら、イベントの選択を保存することを忘れないでください!',
      saveBtn: '興味のあるイベントの保存',
      confirmation: '関心があることについてお知らせいただきありがとうございました！お客様に愛されるイベントに近づけるようがんばります。',
      subConfirm: '次の注文で使用可能な割引コードが記載された確認メールにご注意ください。',
    }
  },
  pl() {
    return {
      title: 'My Event Interests',
      description: 'Let us know which events you might be interested in that we offer. Don\'t forget to save your event selection when complete!',
      saveBtn: 'Save My Event Interest',
      confirmation: 'Thank you for letting us know what else you\'re interested in! We look forward to bringing you closer to the events you love.',
      subConfirm: 'Please be on the lookout for a confirmation email containing a discount code for you to use on your next order.',
    }
  },
  po() {
    return {
      title: 'My Event Interests',
      description: 'Let us know which events you might be interested in that we offer. Don\'t forget to save your event selection when complete!',
      saveBtn: 'Save My Event Interest',
      confirmation: 'Thank you for letting us know what else you\'re interested in! We look forward to bringing you closer to the events you love.',
      subConfirm: 'Please be on the lookout for a confirmation email containing a discount code for you to use on your next order.',
    }
  },
  zhCN() {
    return {
      title: '我感兴趣的活动',
      description: '对我们提供的活动中，请告知您感兴趣的活动。完成后，请务必保存您的活动选择！',
      saveBtn: '保存我感兴趣的活动',
      confirmation: '感谢您告知您感兴趣的其他活动！我们期待着让您参与您喜爱的活动。',
      subConfirm: '请留意包含折扣代码的确认电子邮件，以便您在下次订单中使用。',
    }
  },
}
