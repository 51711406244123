export default {
  en() {
    return {
      preexistingAccount: 'Looks like you have an account. Login to continue.',
      choosePaymentTerms: 'Choose Your Payment Terms',
      couldNotProcess: 'Sorry, we could not process your order. Please contact us if the problem continues.',
      creditsAndDiscounts: 'Credits & Discounts',
      headsUp:'Heads up!',
      hotelNotAvailable: 'Sorry, we were unable to reserve this hotel room. Please select a new one.',
      paymentMethod: 'Payment Method',
      placeOrder: 'Place Order {amount}',
      processing: 'Processing {amount} with card ending in {numbers}',
      rateChanged: 'The hotel you previously added to your cart is now {total} per night. Would you still like to proceed?',
      reviewOrder: 'Review Your Order',
      selectRefundable: 'Please select your Refundable Booking preference to continue',
      updateInfo: 'Update Billing & Shipping Information',
      yourPayments: 'Your Payments',
      yourPaymentTerms: 'Your Payment Terms',
    }
  },
  ar() {
    return {
      preexistingAccount: '.يبدو أنّك مُسجّل من قَبْلُ. سجّل دخولك للمواصلة',
      choosePaymentTerms: 'اختر شروط وطريقة الدفع الخاصّة بك',
      couldNotProcess: ' .تُطَبّق ساعات العمل المعمول بها في الولايات المتّحدة الأمريكيّة ',
      creditsAndDiscounts: 'المبالغ المُقترضة والتخفيضات',
      headsUp: '! يُرجى الانتباه',
      hotelNotAvailable: 'المعذرة، لم نتمكّن من حجز غرفة الفندق، هذه. يُرجى اختيار واحدة جديدة',
      paymentMethod: 'طريقة الدفع',
      placeOrder: 'تقديم الطلب {amount}',
      processing: 'Processing {amount} with card ending in {numbers}',
      rateChanged: 'في الليلة. هل ما زلت ترغب في المتابعة؟ {total} الفندق الّذي أضفته سابقًا إلى سلّة تسوّقك أصبح الآن',
      reviewOrder: 'راجع طلبك',
      selectRefundable: 'يُرْجى تحديد خِيار الحجز القابل للاسترجاع للمتابعة',
      updateInfo: 'حيّن معلومات الفوترة والشحن',
      yourPayments: 'دفوعاتك',
      yourPaymentTerms: 'شروط الدفع الخاصة بك',
    }
  },
  de() {
    return {
      preexistingAccount: 'Es scheint, als hätten Sie bereits ein Konto. Bitte melden Sie sich an, um fortzufahren.',
      choosePaymentTerms: 'Wählen Sie Ihre Zahlungsbedingungen',
      couldNotProcess: 'Es tut uns leid, aber wir können mit Ihrer Bestellung nicht weiterfahren. Bitte kontaktieren Sie uns, falls das Problem weiter besteht.',
      creditsAndDiscounts: 'Guthaben & Ermäßigungen',
      headsUp: 'Aufgepasst!',
      hotelNotAvailable: 'Leider ist es uns nicht möglich, dieses Hotelzimmer zu reservieren. Bitte wählen Sie ein neues aus.',
      paymentMethod: 'Zahlungsart',
      placeOrder: 'Bestellung aufgeben {amount}',
      processing: 'Verarbeitung von {amount} mit einer Karte, die mit {numbers} endet.',
      rateChanged: 'Das Hotel, das Sie zuvor zu Ihrem Warenkorb hinzugefügt haben, kostet jetzt {total} pro Nacht. Möchten Sie trotzdem weiterfahren?',
      reviewOrder: 'Ihre Bestellung überprüfen',
      selectRefundable: 'Bitte wählen Sie Ihre Präferenz für erstattungsfähige Buchungen, um fortzufahren',
      updateInfo: 'Rechnungs- und Lieferinformationen aktualisieren',
      yourPayments: 'Ihre Zahlungen',
      yourPaymentTerms: 'Ihre Zahlungsfristen',
    }
  },
  enGB() {
    return {
      preexistingAccount: 'Looks like you have an account. Login to continue.',
      choosePaymentTerms: 'Choose Your Payment Terms',
      couldNotProcess: 'Sorry, we could not process your order. Please contact us if the problem continues.',
      creditsAndDiscounts: 'Credits & Discounts',
      headsUp: 'Heads up!',
      hotelNotAvailable: 'Sorry, we were unable to reserve this hotel room. Please select a new one.',
      paymentMethod: 'Payment Method',
      placeOrder: 'Place Order {amount}',
      processing: 'Processing {amount} with card ending in {numbers}',
      rateChanged: 'The hotel you previously added to your cart is now {total} per night. Would you still like to proceed?',
      reviewOrder: 'Review Your Order',
      selectRefundable: 'Please select your Refundable Booking preference to continue',
      updateInfo: 'Update Billing & Shipping Information',
      yourPayments: 'Your Payments',
      yourPaymentTerms: 'Your Payment Terms',
    }
  },
  esMX() {
    return {
      preexistingAccount: 'Parece que tiene una cuenta. Inicie sesión para continuar.',
      choosePaymentTerms: 'Elija Sus Plazos de Pago',
      couldNotProcess: 'Lo sentimos, no podemos procesar su orden. Favor de contactarnos si el problema continúa.',
      creditsAndDiscounts: 'Créditos & Descuentos',
      headsUp: '¡Atención!',
      hotelNotAvailable: 'Lo sentimos, no es posible reservar esta habitación de hotel. Favor de seleccionar una nueva.',
      paymentMethod: 'Método de pago',
      placeOrder: 'Colocar Orden {amount}',
      processing: 'Procesando {amount} con la tarjeta con terminación {numbers}',
      rateChanged: 'El hotel que agregó anteriormente a su carrito ahora cuesta {total} por noche. ¿Todavía desea continuar?',
      reviewOrder: 'revise su orden',
      selectRefundable: 'Por favor seleccione su preferencia de Reserva Reembolsable para continuar',
      updateInfo: 'Actualizar Información de Envío y Facturación',
      yourPayments: 'Sus Pagos',
      yourPaymentTerms: 'Sus Plazos de Pago',
    }
  },
  es() {
    return {
      preexistingAccount: 'Parece que tienes una cuenta. Inicia sesión para continuar.',
      choosePaymentTerms: 'Elige Tus Condiciones de Pago',
      couldNotProcess: 'Lo sentimos, no hemos podido procesar tu pedido. Por favor, contáctanos si el problema continúa.',
      creditsAndDiscounts: 'Créditos y Descuentos',
      headsUp: '¡Atención!',
      hotelNotAvailable: 'Lo sentimos, no hemos podido reservar esta habitación de hotel. Por favor, selecciona una nueva.',
      paymentMethod: 'Método de Pago',
      placeOrder: 'Hacer Pedido {amount}',
      processing: 'Procesando {amount} con tarjeta terminada en {numbers}',
      rateChanged: 'El hotel que has añadido previamente a tu carrito es ahora {total} por noche. ¿Todavía quieres proceder?',
      reviewOrder: 'revisa tu pedido',
      selectRefundable: 'Por favor, selecciona tu preferencia de Reserva Reembolsable para continuar',
      updateInfo: 'Actualizar la Información de Facturación y Envío',
      yourPayments: 'Tus Pagos',
      yourPaymentTerms: 'Tus Condiciones de Pago',
    }
  },
  fr() {
    return {
      preexistingAccount: 'Il semblerait que vous ayez un compte. Connectez-vous pour continuer.',
      choosePaymentTerms: 'Sélectionnez vos conditions de paiement',
      couldNotProcess: 'Désolé, nous n\'avons pas pu traiter votre commande. Si le problème persiste, veuillez nous conctacter.',
      creditsAndDiscounts: 'Bons d\'achat & remises',
      headsUp: 'Attention !',
      hotelNotAvailable: 'Désolé, nous n\'avons pas pu réserver cette chambre d\'hôtel. Veuillez en choisir une autre.',
      paymentMethod: 'Méthode de paiement',
      placeOrder: 'Passer la commande {amount}',
      processing: 'Traitement en cours du paiement de {amount} avec la carte finissant par {numbers}',
      rateChanged: 'L\'hôtel que vous avez ajouté à votre panier plus tôt coûte désormais {total} par nuitée. Désirez-vous quand même continuer ?',
      reviewOrder: 'vérifier votre commande',
      selectRefundable: 'Veuillez sélectionner vos préférences en matière de réservation remboursable pour continuer',
      updateInfo: 'Modifier les informations de facturation et de livraison',
      yourPayments: 'Vos paiements',
      yourPaymentTerms: 'Vos conditions de paiement',
    }
  },
  it() {
    return {
      preexistingAccount: 'Sembra che tu abbia già un account. Accedi per continuare.',
      choosePaymentTerms: 'Scegli la modalità di pagamento',
      couldNotProcess: 'Ci dispiace, ma non è stato possibile elaborare il tuo ordine. Ti preghiamo di contattarci nel caso in cui il problema dovesse persistere.',
      creditsAndDiscounts: 'Crediti e sconti',
      headsUp: 'Attenzione!',
      hotelNotAvailable: 'Purtroppo non ci è stato possibile prenotare una stanza in questo albergo. Ti invitiamo a selezionarne uno differente.',
      paymentMethod: 'Metodo di pagamento',
      placeOrder: 'Effettua Ordine {amount}',
      processing: 'Stiamo elaborando un pagamento da {amount} tramite carta con cifre finali {numbers}',
      rateChanged: 'L\'albergo che hai aggiunto al carrello in precedenza adesso costa {total} per notte. Desideri continuare lo stesso?',
      reviewOrder: 'controlla il tuo ordine',
      selectRefundable: 'Ti preghiamo di scegliere il tipo di prenotazione rimborsabile che desideri per proseguire',
      updateInfo: 'Aggiorna i dati di fatturazione e di spedizione',
      yourPayments: 'I tuoi pagamenti',
      yourPaymentTerms: 'Le tue modalità di pagamento',
    }
  },
  ja() {
    return {
      preexistingAccount: 'アカウントがあります。続けるにはログインしてください。',
      choosePaymentTerms: 'お支払い条件をお選びください',
      couldNotProcess: '大変申し訳ございません、ご注文を完了できませんでした。引き続き問題が発生する場合は弊社までご連絡ください。',
      creditsAndDiscounts: 'クレジットと割引',
      headsUp: '注意!',
      hotelNotAvailable: 'このホテルの部屋を予約できませんでした。新しい部屋をお選びください。',
      paymentMethod: '支払方法',
      placeOrder: '注文する {amount}',
      processing: ' {numbers}で終わるカードで{amount}を処理をします',
      rateChanged: '以前にカートに追加したホテルは、現在 1 泊 {total} です。まだ続行しますか？',
      reviewOrder: '注文内容を確認する',
      selectRefundable: '続行するには、払い戻し可能な予約設定を選択してください',
      updateInfo: '請求先と発送先の情報を更新',
      yourPayments: 'お支払い',
      yourPaymentTerms: 'お客様のお支払い期間',
    }
  },
  pl() {
    return {
      preexistingAccount: 'Looks like you have an account. Login to continue.',
      choosePaymentTerms: 'Choose Your Payment Terms',
      couldNotProcess: 'Przepraszamy, nie mogliśmy zrealizować Twojego zamówienia. Prosimy o kontakt, jeśli problem się powtórzy.',
      creditsAndDiscounts: 'Credits & Discounts',
      headsUp: 'Heads up!',
      hotelNotAvailable: 'Sorry, we were unable to reserve this hotel room. Please select a new one.',
      paymentMethod: 'Payment Method',
      placeOrder: ' złóż zamówienie na {amount}',
      processing: 'pobieramy {amount} z karty o końcówce {numbers}',
      rateChanged: 'The hotel you previously added to your cart is now {total} per night. Would you still like to proceed?',
      reviewOrder: 'sprawdź swoje zamówienie',
      selectRefundable: 'Please select your Refundable Booking preference to continue',
      updateInfo: 'Uaktualnij dane do rozliczeń i wysyłki ',
      yourPayments: 'Twoje płatności',
      yourPaymentTerms: 'terminy płatności',
    }
  },
  po() {
    return {
      preexistingAccount: 'Looks like you have an account. Login to continue.',
      choosePaymentTerms: 'Choose Your Payment Terms',
      couldNotProcess: 'Sorry, we could not process your order. Please contact us if the problem continues.',
      creditsAndDiscounts: 'Credits & Discounts',
      headsUp: 'Heads up!',
      hotelNotAvailable: 'Sorry, we were unable to reserve this hotel room. Please select a new one.',
      paymentMethod: 'Payment Method',
      placeOrder: 'Place Order {amount}',
      processing: 'Processing {amount} with card ending in {numbers}',
      rateChanged: 'The hotel you previously added to your cart is now {total} per night. Would you still like to proceed?',
      reviewOrder: 'review your order',
      selectRefundable: 'Please select your Refundable Booking preference to continue',
      updateInfo: 'Update Billing & Shipping Information',
      yourPayments: 'Your Payments',
      yourPaymentTerms: 'Your Payment Terms',
    }
  },
  zhCN() {
    return {
      preexistingAccount: '看来您已经有帐户了。请登录以继续。',
      choosePaymentTerms: '选择您的付款方式',
      couldNotProcess: '抱歉，我们无法处理您的订单。如果问题持续出现请联系我们。',
      creditsAndDiscounts: '积分和折扣',
      headsUp: '注意！',
      hotelNotAvailable: '抱歉，无法预订此酒店房间。请重新选择其他房间。',
      paymentMethod: '支付方式',
      placeOrder: '下单 {amount}',
      processing: '处理尾号为 {numbers}的银行卡支付款项{amount}',
      rateChanged: '您之前添加到购物车的酒店现在每晚价格为{total}。还要继续吗？',
      reviewOrder: '查看您的订单',
      selectRefundable: '请选择您的可退款预订首选项以继续',
      updateInfo: '更新帐单和发货信息',
      yourPayments: '您的支付款项',
      yourPaymentTerms: '您的付款方式',
    }
  },
}
