export default {

  en() {
    return {
      close: 'Close',
      continue: 'Continue To Checkout',
      continueGpe: 'Continue To Payment',
      keepShopping: 'Continue Shopping',
      yourItems: 'Your Items',
    }
  },

  ar() {
    return {
      close: 'قريب',
      continue: 'استمرّ في تسجيل الخروج',
      continueGpe: 'واصل إلى مرحلة الدفع',
      keepShopping: 'واصل التسوّق',
      yourItems: 'واصل إلى مرحلة الدفع',
    }
  },
  de() {
    return {
      close: 'Schließen',
      continue: 'Weiter zur Kasse',
      continueGpe: 'Weiter zur Kasse',
      keepShopping: 'Weiter einkaufen',
      yourItems: 'Ihre Artikel',
    }
  },
  enGB() {
    return {
      close: 'Close',
      continue: 'Continue To Checkout',
      continueGpe: 'Continue To Payment',
      keepShopping: 'Continue Shopping',
      yourItems: 'Your Items',
    }
  },
  esMX() {
    return {
      close: 'Cerrar',
      continue: 'Continuar al Pago',
      continueGpe: 'Continuar al Pago',
      keepShopping: 'Continuar Comprando',
      yourItems: 'Sus Artículos',
    }
  },
  es() {
    return {
      close: 'Cerrar',
      continue: 'Continuar Con La Facturación',
      continueGpe: 'Continuar Con El Pago',
      keepShopping: 'Continuar Comprando',
      yourItems: 'Tus Artículos',
    }
  },
  fr() {
    return {
      close: 'Fermer',
      continue: 'Continuer vers le paiement',
      continueGpe: 'Continuer vers le paiement',
      keepShopping: 'Continuer vos achats',
      yourItems: 'Vos articles',
    }
  },
  it() {
    return {
      close: 'Chiudi',
      continue: 'Vai alla cassa',
      continueGpe: 'Vai al pagamento',
      keepShopping: 'Continua a fare acquisti',
      yourItems: 'I tuoi articoli',
    }
  },
  ja() {
    return {
      close: '閉じる',
      continue: 'チェックアウトを続ける',
      continueGpe: '支払いを続ける',
      keepShopping: 'ショッピングを続ける',
      yourItems: 'お客様のアイテム',
    }
  },
  pl() {
    return {
      close: 'zamknij',
      continue: 'przejdź do kasy',
      continueGpe: 'przejdź do kasy',
      keepShopping: 'kontynuuj zakupy',
      yourItems: 'zamówione pozycje',
    }
  },
  po() {
    return {
      close: 'Close',
      continue: 'Continue To Checkout',
      continueGpe: 'Continue To Payment',
      keepShopping: 'Continue Shopping',
      yourItems: 'Your Items',
    }
  },
  zhCN() {
    return {
      close: '关闭',
      continue: '继续付款',
      continueGpe: '继续支付',
      keepShopping: '继续购物',
      yourItems: '您的物品',
    }
  },
}
