import FetchCall from '../local_fetch_call'

// const cartChangeEventName = 'order-updated'

const csrfToken = () => {
  let tok = document.getElementsByTagName('meta')['csrf-token']
  if (tok) { // csrf-token is null in testing
    return tok.getAttribute('content')
  } else {
    return null
  }
}

export default {

  async addContractedToCart(itemId, body) {
    let res = await FetchCall.fetchWithLocale(
      `/cart/items/${itemId}/order_item_hotels`,
      'POST',
      body
    )

    return res
  },

  addDiscount(discountCode) {
    return FetchCall.fetchWithoutLocale('/api/discounts.json', 'POST', discountCode).
      then(res => {
        return res
      })
  },

  saveAddress(body) {

    return FetchCall.fetchWithLocale(
      '/checkout/addresses.json',
      'POST',
      body
    )
  },

  updateProtectGroup(body) {

    return FetchCall.fetchWithLocale(
      '/checkout/refundable_bookings.json',
      'POST',
      body
    )
  },


  createPaymentIntent(body) {

    return FetchCall.fetchWithLocale(
      '/checkout/payment_intents.json',
      'POST',
      body
    )
  },

  checkoutLogin(body) {

    return FetchCall.fetchWithLocale(
      '/customers/sign_in.json',
      'POST',
      body
    )
  },

  placeOrder(body){
    return FetchCall.fetchWithLocale('/checkout/charges.json', 'POST', JSON.stringify(body))
  },

  addH4HHotelToCart(itemId, body){
    return FetchCall.fetchWithLocale(
      `/cart/items/${itemId}/order_item_h4h_hotels`,
      'POST',
      body
    )
      .then(item => {
        return item
      })
  },

  changeOrder(body) {
    return FetchCall.fetchWithLocale('/cart.json', 'PUT', body).
      then(order => { return order })
  },

  changeOrderItem(orderItemId, method, body) {
    return FetchCall.fetchWithLocale(`/cart/items/${orderItemId}.json`, method, body).
      then(item => { return item })
  },

  updateHotelTransportation(orderItemId, method, body){
    return FetchCall.fetchWithLocale(`/cart/items/${orderItemId}/update_transportation`, method, body).
      then(item => { return item })
  },

  confirmHotelRateChange(orderItemId, method, body) {
    return FetchCall.fetchWithLocale(`/cart/items/${orderItemId}/confirm_hotel_rate_change.json`, method, body).
      then(item => { return item })
  },

  checkout(checkoutParams) {
    return FetchCall.fetchWithLocale('/cart/charges.json', 'POST', checkoutParams).
      then(order => { return order })
  },

  elementsCreditCheckout(checkoutParams) {
    return FetchCall.fetchWithLocale('/checkout/charges.json', 'POST', checkoutParams).
      then(order => { return order })
  },

  checkoutConfirm(checkoutConfirmParams) {
    return FetchCall.fetchWithLocale('/cart/charges/confirms.json', 'POST', checkoutConfirmParams).
      then(order => { return order })
  },

  createOrderItem(body) {
    return FetchCall.fetchWithLocale('/cart/items.json', 'POST', body).
      then(item => {
        return item
      })
  },

  deleteContractedHotelItem(orderItemId, hotelId, method, body){
    return FetchCall.fetchWithLocale(`/cart/items/${orderItemId}/order_item_hotels/${hotelId}.json`, method, body).
      then(item => {
        return item
      })
  },

  deleteH4hHotelItem(orderItemId, hotelId, method, body){
    return FetchCall.fetchWithLocale(`/cart/items/${orderItemId}/order_item_h4h_hotels/${hotelId}.json`, method, body).
      then(item => {
        return item
      })
  },

  addCustomerPoints(body) {
    return FetchCall.fetchWithLocale('/cart/customer_reward_points.json', 'POST', body).
      then(order => { return order })
  },

  fetchCustomer(email) {
    return FetchCall.fetchWithLocale(`/lookup_customers.json?email=${email}`, 'GET', null).
      then(order => { return order })
  },

  fetchCustomerPoints() {
    return FetchCall.fetchWithLocale('/cart/customer_reward_points.json', 'GET', null).
      then(order => { return order })
  },

  getAddOnItems() {
    return FetchCall.fetchWithLocale('/cart/add_ons.json', 'GET', null).
      then(addOns => { return addOns })
  },

  getFinishedOrder() {
    return FetchCall.fetchWithLocale('/confirmation_orders.json', 'GET', null).
      then(order => { return order })
  },

  getDiscounts(){
    return FetchCall.fetchWithLocale('/cart/discounts.json', 'GET', null).
      then(discounts => { return discounts })
  },

  getOrder() {
    return FetchCall.fetchWithLocale('/cart.json', 'GET', null).
      then(order => { return order })
  },

  getLocationSuggestions(input){
    return FetchCall.fetchWithoutLocale(`/api/location_suggestions.json?text=${input}`, 'GET', null)
  },
  getLocationById(id){
    return FetchCall.fetchWithoutLocale(`/api/locations/${id}.json`, 'GET', null)
  },

  async updateGuestUser(email) {
    return fetch('/customers/guests.json', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken()
      },
      method: 'PUT',
      body: JSON.stringify({
        email: email
      })
    })
  },

  clearCart() {
    return FetchCall.fetchWithLocale('/cart/clear_quote_cart.json', 'PUT', null)
  },

  getProtectGroupInfo() {
    return FetchCall.fetchWithLocale('/cart/protect_group_info.json', 'GET', null)
  },
  hotelPriceChange() {
    return FetchCall.fetchWithLocale('/cart/hotel_price_change.json', 'GET', null)
  }
}
