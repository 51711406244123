// import FetchCall from '../../../shared/local_fetch_call'
import gsap from 'gsap'
const namespaced = true

const state = {
  topSectionShowing: true,
  middleSectionShowing: false,
  bottomSectionShowing: false,
}

const mutations = {

  displayTopSection(state) {
    gsap.to('.infoContainer', {duration: 1, height: 'auto', ease: 'power2.inOut'})
    gsap.to('.protectGroupContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    gsap.to('.paymentSectionContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    // if(window.innerWidth < 768) {
    window.scrollTo({top: 140, behavior: 'smooth'})
    // }
    state.topSectionShowing = true
    state.middleSectionShowing = false
    state.bottomSectionShowing = false
  },

  displayMiddleSection(state) {
    gsap.to('.infoContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    gsap.to('.protectGroupContainer', {duration: 1, height: 'auto', ease: 'power2.inOut'})
    gsap.to('.paymentSectionContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    // if(window.innerWidth < 768) {
    window.scrollTo({top: 190, behavior: 'smooth'})
    // }
    state.topSectionShowing = false
    state.middleSectionShowing = true
    state.bottomSectionShowing = false
  },

  displayBottomSection(state) {
    gsap.to('.infoContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    gsap.to('.protectGroupContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
    gsap.to('.paymentSectionContainer', {duration: 1, height: 'auto', ease: 'power2.inOut'})
    // if(window.innerWidth < 768) {
    window.scrollTo({top: 250, behavior: 'smooth'})
    // }
    state.topSectionShowing = false
    state.middleSectionShowing = false
    state.bottomSectionShowing = true
  }
}

export default {
  namespaced,
  state,
  mutations,
}
