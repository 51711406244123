export default {
  en() {
    return {
      link: 'Click here',
      description: 'to read and agree to your terms and conditions.',
      finePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      sectionTitle: 'Terms and Conditions',
      button: 'View Terms & Conditions'
    }
  },
  ar() {
    return {
      link: 'اضغط هنا',
      description: 'لقراءة الأحكام والشروط الخاصّة بك والموافقة عليها',
      finePrint: '.عند الانتهاء من الاطّلاع على الشروط والأحكام الموقّعة الخاصّة بك، ستتمّ إعادتك إلى هذه الصفحة لإكمال عمليّة الشراء .docusign.com سينقلك هذا الرابط إلى',
      sectionTitle: 'الشروط والأحكام',
      button: 'اطّلع على الشروط والأحكام'
    }
  },
  de() {
    return {
      link: 'Hier klicken',
      description: 'um die allgemeinen Geschäftsbedingungen zu lesen und zu akzeptieren.',
      finePrint: 'Dieser Link führt Sie zu docusign.com. Nach der Unterzeichnung der Allgemeinen Geschäftsbedingungen werden Sie auf diese Seite zurückgeleitet, um Ihren Kauf abzuschließen.',
      sectionTitle: 'Allgemeine Geschäftsbedingungen',
      button: 'Allgemeine Geschäftsbedingungen ansehen',
    }
  },
  enGB() {
    return {
      link: 'Click here',
      description: 'to read and agree to your terms and conditions.',
      finePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      sectionTitle: 'Terms and Conditions',
      button: 'View Terms & Conditions',
    }
  },
  esMX() {
    return {
      link: 'Haga clic aquí',
      description: 'para leer y aceptar sus términos y condiciones.',
      finePrint: 'Este enlace lo llevará a docusign.com. Una vez que haya firmado sus términos y condiciones, volverá a esta página para completar su compra.',
      sectionTitle: 'Términos y Condiciones ',
      button: 'Ver Términos y Condiciones',
    }
  },
  es() {
    return {
      link: 'Pulsa aquí',
      description: 'para leer y aceptar tus términos y condiciones.',
      finePrint: 'Este enlace te llevará a docusign.com. Una vez que hayas firmado los términos y condiciones, volverás a esta página para completar tu compra.',
      sectionTitle: 'Términos y Condiciones',
      button: 'Ver los Términos y Condiciones',
    }
  },
  fr() {
    return {
      link: 'Cliquez ici',
      description: 'pour lire et accepter nos conditions d\'utilisation.',
      finePrint: 'Ce lien vous dirigera vers le site docusign.com. Une fois que vous aurez signé les conditions générales d\'utilisation, vous serez redirigé vers cette page pour finaliser votre achat.',
      sectionTitle: 'Conditions d\'utilisation',
      button: 'Voir les conditions d\'utilisation',
    }
  },
  it() {
    return {
      link: 'Clicca qui',
      description: 'per leggere e accettare Termini e condizioni.',
      finePrint: 'Questo collegamento ti indirizzerà a docusign.com. Una volta presa visione dei termini e delle condizioni, verrai reindirizzato a questa pagina per il completamento dell\'acquisto.',
      sectionTitle: 'Termini e condizioni',
      button: 'Mostra termini e condizioni»',
    }
  },
  ja() {
    return {
      link: 'ここをクリック',
      description: '利用規約を読んで同意します。',
      finePrint: 'このリンクをクリックすると、docusign.comに移動します。契約条件への署名が完了すると、このページに戻って購入を完了します。',
      sectionTitle: 'ご利用規約',
      button: '利用規約を見る',
    }
  },
  pl() {
    return {
      link: 'Click here',
      description: 'to read and agree to your terms and conditions.',
      finePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      sectionTitle: 'regulamin',
      button: 'View Terms & Conditions',
    }
  },
  po() {
    return {
      link: 'Click here',
      description: 'to read and agree to your terms and conditions.',
      finePrint: 'This link will take you to docusign.com. Upon completion of your signed terms and conditions, you will be returned to this page to complete your purchase.',
      sectionTitle: 'Terms and Conditions',
      button: 'View Terms & Conditions',
    }
  },
  zhCN() {
    return {
      link: '点击此处',
      description: '阅读并同意条款和条件。',
      finePrint: '点击该链接可访问docusign.com网站。同意条款和条件后，您将返回此页面以完成支付。',
      sectionTitle: '条款和条件',
      button: '查看条款和条件',
    }
  },
}
