export default {
  en() {
    return {
      instructions: 'Your package has been shipped via {carrier}. Click on the tracking number(s) below to track your package.',
      orderShipments: 'Your Package Has Shipped',
    }
  },
  ar() {
    return {
      instructions: 'تمّ شحن طردك عبر {شركة النقل}. اُنْقر فوق رقم /أرقام التتبّع أدناه لتتبّع باقتك',
      orderShipments: 'تمّ شحن طردك',
    }
  },
  de() {
    return {
      instructions: 'Ihr Paket wurde per {carrier} versendet. Klicken Sie auf die nachfolgende Trackingnummer, um Ihr Paket zu verfolgen.',
      orderShipments: 'Ihr Paket wurde versendet',
    }
  },
  enGB() {
    return {
      instructions: 'Your package has been shipped via {carrier}. Click on the tracking number(s) below to track your package.',
      orderShipments: 'Your Package Has Shipped',
    }
  },
  esMX() {
    return {
      instructions: 'Su paquete ha sido enviado vía {carrier}. Haga clic en el número(s) de rastreo que se encuentra abajo para rastrear su paquete.',
      orderShipments: 'Su Paquete Ha Sido Enviado',
    }
  },
  es() {
    return {
      instructions: 'Tu paquete ha sido enviado a través de {carrier}. Haz clic en el( los) número(s) de seguimiento que aparece(n) a continuación para rastrear tu paquete.',
      orderShipments: 'Tu Paquete Ha Sido Enviado',
    }
  },
  fr() {
    return {
      instructions: 'Votre pack a forfait a été expédié via {carrier}. Cliquez sur le(s) numéro(s) de suivi ci-dessous pour suivre l\'expédition de votre forfait.',
      orderShipments: 'Votre forfait a été expédié',
    }
  },
  it() {
    return {
      instructions: 'Il tuo pacchetto è stato spedito tramite {carrier}. Clicca sul numero di tracciamento sottostante se desideri monitorare il tuo pacchetto.',
      orderShipments: 'Il tuo pacchetto è stato spedito',
    }
  },
  ja() {
    return {
      instructions: 'パッケージは{carrier}経由で発送されます。パッケージを追跡するには下記の追跡番号をクリックしてください。',
      orderShipments: 'お客様のパッケージは発送されました',
    }
  },
  pl() {
    return {
      instructions: 'Twój pakiet został wysłany za pośrednictwem {carrier}. Kliknij w numer(y) przesyłki, żeby ją śledzić.',
      orderShipments: 'wysłaliśmy Twój pakiet ',
    }
  },
  po() {
    return {
      instructions: 'Your package has been shipped via {carrier}. Click on the tracking number(s) below to track your package.',
      orderShipments: 'Your Package Has Shipped',
    }
  },
  zhCN() {
    return {
      instructions: '您的套餐已通过{carrier}发货。请点击以下物流跟踪编号跟踪您的套餐。',
      orderShipments: '您的套餐已发货',
    }
  },
}
