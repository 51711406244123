export default {
  en() {
    return {
      acceptedTerms: 'I understand and agree to the <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Terms & Conditions</a>',
      agreedTerms: 'I agree to the Terms & Conditions',
      f1Consent: 'I want to receive the latest information from F1®, including news, surveys, offers and promotions',
      pleaseAccept: 'Please accept the Terms & Conditions',
      pleaseRead: 'Please read through the Terms & Conditions above to continue'
    }
  },
  ar() {
    return {
      acceptedTerms: 'أفهم وأوافق على <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">الأحكام والشروط</a>',
      agreedTerms: 'أوافق على الشروط والأحكام',
      f1Consent: 'بما في ذلك الأخبار واستطلاعات الرأي والعروض وكذلك العروض الترويجية F1®أرغب في تلقّي أحدث المعلومات من ',
      pleaseAccept: 'يُرجى قَبُول الشروط والأحكام',
      pleaseRead: 'يُرْجى الاطّلاع على الشروط والأحكام أعلاه للمواصلة',
    }
  },
  de() {
    return {
      acceptedTerms: 'Ich verstehe die <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Allgemeinen Geschäftsbedingungen</a> und stimme ihnen zu.',
      agreedTerms: 'Ich akzeptiere die Allgemeinen Geschäftsbedingungen',
      f1Consent: 'Ich möchte die aktuellsten Informationen von F1®, inklusive News, Umfragen, Angebote und Promotionen, erhalten',
      pleaseAccept: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
      pleaseRead: 'Bitte lesen Sie die oben genannten Allgemeinen Geschäftsbedingungen durch, um fortzufahren.',
    }
  },
  enGB() {
    return {
      acceptedTerms: 'I understand and agree to the <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Terms & Conditions</a>',
      agreedTerms: 'I agree to the Terms & Conditions',
      f1Consent: 'I want to receive the latest information from F1®, including news, surveys, offers and promotions',
      pleaseAccept: 'Please accept the Terms & Conditions',
      pleaseRead: 'Please read through the Terms & Conditions above to continue',
    }
  },
  esMX() {
    return {
      acceptedTerms: 'Entiendo y acepto los <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Términos & Condiciones</a>',
      agreedTerms: 'Acepto los Términos y Condiciones',
      f1Consent: 'Quiero recibir información actualizada de F1®, incluyendo noticias, encuestas, ofertas y promociones',
      pleaseAccept: 'Favor de aceptar los Términos & Condiciones',
      pleaseRead: 'Por favor lea los Términos y Condiciones para continuar',
    }
  },
  es() {
    return {
      acceptedTerms: 'Entiendo y acepto los <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Términos y Condiciones</a>',
      agreedTerms: 'Estoy de acuerdo con los Términos y Condiciones',
      f1Consent: 'Quiero recibir la información más reciente de F1®, incluyendo noticias, encuestas, ofertas y promociones',
      pleaseAccept: 'Por favor, acepta los Términos y Condiciones',
      pleaseRead: 'Por favor, lee los Términos y Condiciones de arriba para continuar',
    }
  },
  fr() {
    return {
      acceptedTerms: 'Je comprends et j\'accepte les <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Conditions Générales d\'Utilisation</a>.',
      agreedTerms: 'J\'accepte les Conditions Générales d\'Utilisation',
      f1Consent: 'Je souhaite recevoir les dernières informations provenant de la F1®, incluant les actualités, les sondages, les offres et les promotions.',
      pleaseAccept: 'Veuillez accepter les Conditions Générales d\'Utilisation.',
      pleaseRead: 'Veuillez lire les Conditions Générales d\'Utilisation ci-dessus pour continuer',
    }
  },
  it() {
    return {
      acceptedTerms: 'Ho letto e accetto <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">i termini e le condizioni</a>',
      agreedTerms: 'Accetto i termini e le condizioni',
      f1Consent: 'Desidero ricevere le ultime informazioni provenienti da F1®, tra cui notizie, sondaggi, offerte e promozioni',
      pleaseAccept: 'Ti preghiamo di accettare i termini e le condizioni',
      pleaseRead: 'Ti preghiamo di leggere i termini e le condizioni soprastanti per continuare',
    }
  },
  ja() {
    return {
      acceptedTerms: '私は<a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">利用規約</a>を理解し同意します。',
      agreedTerms: '私は利用規約に同意します',
      f1Consent: 'F1®のニュース、レポート、割引＆キャンペーン情報などの最新情報を受け取る',
      pleaseAccept: '利用規約に同意してください。',
      pleaseRead: '続けるには、上記の利用規約をお読みください',
    }
  },
  pl() {
    return {
      acceptedTerms: 'I understand and agree to the <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Terms & Conditions</a>',
      agreedTerms: 'I agree to the Terms & Conditions',
      f1Consent: 'chcę otrzymywać najświeższe informacje od F1®, w tym wiadomości, ekspertyzy, oferty i promocje',
      pleaseAccept: 'Please accept the Terms & Conditions',
      pleaseRead: 'Please read through the Terms & Conditions above to continue',
    }
  },
  po() {
    return {
      acceptedTerms: 'I understand and agree to the <a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">Terms & Conditions</a>',
      agreedTerms: 'I agree to the Terms & Conditions',
      f1Consent: 'I want to receive the latest information from F1®, including news, surveys, offers and promotions',
      pleaseAccept: 'Please accept the Terms & Conditions',
      pleaseRead: 'Please read through the Terms & Conditions above to continue',
    }
  },
  zhCN() {
    return {
      acceptedTerms: '本人理解并同意<a data-toggle="modal" data-target="#termsModal" class="terms-and-conditions" href="#">《条款与条件》</a>',
      agreedTerms: '本人同意条款和条件',
      f1Consent: '我希望收到 F1® 的最新消息，包括新闻、信息反馈、优惠和促销',
      pleaseAccept: '请接受《条款与条件》',
      pleaseRead: '请仔细阅读上述条款和条件以继续',
    }
  },
}
