export default {
  en() {
    return {
      added: '{name} added to basket',
      addOns: 'Add-Ons',
      item: 'Item {startCount} of {total}',
      items: 'Items ({startCount} - {endCount}) of {total}',
      raceDayExtra: 'Race Day Extras'
    }
  },
  ar() {
    return {
      added: ' {name} المُضاف إلى سلّة التسوّق',
      addOns: 'الإضافات',
      item: 'في الليلة. هل ما زلت ترغب في المتابعة؟ {total} الفندق الّذي أضفته سابقًا إلى سلّة تسوّقك أصبح الآن',
      items: 'العناصر ({startCount} - {endCount}) من {total}',
      raceDayExtra: 'المبالغ الإضافيّة يوم السباق',
    }
  },
  de() {
    return {
      added: '{name} zum Warenkorb hinzufügen',
      addOns: 'Add-Ons',
      item: 'Artikel {startCount} von {total}',
      items: 'Artikel ({startCount} - {endCount}) von {total}',
      raceDayExtra: 'Extras am Renntag',
    }
  },
  enGB() {
    return {
      added: '{name} added to basket',
      addOns: 'Add-Ons',
      item: 'Item {startCount} of {total}',
      items: 'Items ({startCount} - {endCount}) of {total}',
      raceDayExtra: 'Race Day Extras',
    }
  },
  esMX() {
    return {
      added: '{name} añadido al carrito',
      addOns: 'Adicionales',
      item: 'Artículo {startCount} de {total}',
      items: 'Artículos ({startCount} - {endCount}) de {total}',
      raceDayExtra: 'Extras del día de la carrera',
    }
  },
  es() {
    return {
      added: '{name} añadido al carrito',
      addOns: 'Complementos',
      item: 'Artículo {startCount} de {total}',
      items: 'Artículos ({startCount} - {endCount}) de {total}',
      raceDayExtra: 'Extras del Día de la Carrera',
    }
  },
  fr() {
    return {
      added: '{name} ajouté au panier',
      addOns: 'Services supplémentaires',
      item: 'Article {startCount} sur {total}',
      items: 'Articles ({startCount} à {endCount}) sur {total}',
      raceDayExtra: 'Bonus journée de Grand Prix',
    }
  },
  it() {
    return {
      added: '{name} aggiunto al carrello',
      addOns: 'Extra',
      item: 'Articolo {startCount} di {total}',
      items: 'Articoli ({startCount} - {endCount}) di {total}',
      raceDayExtra: 'Servizi aggiuntivi nel giorno della gara',
    }
  },
  ja() {
    return {
      added: '{name} がバスケットに追加されました',
      addOns: 'アドオン',
      item: 'アイテム {startCount} の{total}',
      items: '{total}の内、 ({startCount} ～ {endCount}) 項目 ',
      raceDayExtra: 'レース日その他',
    }
  },
  pl() {
    return {
      added: '{name} added to basket',
      addOns: 'rozszerzenia',
      item: 'Item {startCount} of {total}',
      items: ' pozycje ({startCount} - {endCount}) z {total}',
      raceDayExtra: 'Race Day Extras',
    }
  },
  po() {
    return {
      added: '{name} added to basket',
      addOns: 'Add-Ons',
      item: 'Item {startCount} of {total}',
      items: 'Items ({startCount} - {endCount}) of {total}',
      raceDayExtra: 'Race Day Extras',
    }
  },
  zhCN() {
    return {
      added: '{name}已添加至购物篮',
      addOns: '附加',
      item: '{total}的{startCount}项 ',
      items: ' {total}项目的 ({startCount} - {endCount}) ',
      raceDayExtra: '比赛日专享福利',
    }
  },
}
