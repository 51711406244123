export default {
  en() {
    return {
      description: 'To finalize your hotel stay, please provide the information below.',
      room: 'Room {number}:',
      sectionTitle: 'Hotel Details',
    }
  },
  ar() {
    return {
      description: '.لوضع اللمسات الأخيرة على حجز إقامتك في الفندق، يُرْجى تقديم المعلومات أدناه',
      room: 'الغرفة: {number} ',
      sectionTitle: 'يمكن العثور على هذه المعلومات في صفحة تفاصيل الطلب أيضًا',
    }
  },
  de() {
    return {
      description: 'Um Ihren Hotelaufenthalt abzuschließen, geben Sie bitte die untenstehenden Informationen an',
      room: 'Zimmer {number}:',
      sectionTitle: 'Hotelinformationen',
    }
  },
  enGB() {
    return {
      description: 'To finalize your hotel stay, please provide the information below.',
      room: 'Room {number}:',
      sectionTitle: 'Hotel Details',
    }
  },
  esMX() {
    return {
      description: 'Para finalizar su reserva de hospedaje, favor de proporcionar la información a continuación',
      room: 'Habitación {number}:',
      sectionTitle: 'Detalles del Hotel',
    }
  },
  es() {
    return {
      description: 'Para finalizar tu estancia en el hotel, por favor, proporciona la siguiente información.',
      room: 'Habitación {number}:',
      sectionTitle: 'Detalles del Hotel',
    }
  },
  fr() {
    return {
      description: 'Pour finaliser la réservation de votre hébergement en hôtel, veuillez renseigner les informations ci-dessous.',
      room: 'Chambre {number} :',
      sectionTitle: 'Informations sur l\'hôtel',
    }
  },
  it() {
    return {
      description: 'Per completare la prenotazione del tuo soggiorno in hotel, ti preghiamo di fornirci le informazioni riportate di seguito',
      room: 'Camera {number}:',
      sectionTitle: 'Dettagli alberghieri',
    }
  },
  ja() {
    return {
      description: 'ホテルの予約を完了するには、以下の情報を提供してください',
      room: '部屋 {number}:',
      sectionTitle: 'ホテル詳細',
    }
  },
  pl() {
    return {
      description: 'aby dokończyć rezerwację hotelu, proszę podać poniższe dane',
      room: 'pokój {number}:',
      sectionTitle: 'szczegóły dotyczące hotelu',
    }
  },
  po() {
    return {
      description: 'To finalize your hotel stay, please provide the information below.',
      room: 'Room {number}:',
      sectionTitle: 'Hotel Details',
    }
  },
  zhCN() {
    return {
      description: '请提供以下信息确定酒店住宿',
      room: '房间 {number}：',
      sectionTitle: '酒店详情',
    }
  },
}
