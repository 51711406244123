/* global */
import { createStore } from 'vuex'
import VuexTurbo from '../vuex-turbolinks'
// Store imports
import hotel from './modules/hotel'
import packages from './modules/packages'
import order from './modules/order'
import order_item from './modules/order-item'
import protect_group from './modules/protect_group'
import event from './modules/event'
import cart from './modules/cart'
import checkout from './modules/checkout'
import elements from './modules/elements'
import reservation from './modules/reservation'
import login from './modules/login'

const plugins = [
  VuexTurbo
]

export const store = createStore({
  modules: {
    login,
    hotel,
    packages,
    order,
    order_item,
    protect_group,
    event,
    checkout,
    elements,
    cart,
    reservation
  },
  plugins
})



